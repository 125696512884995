import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const SinkVendorMaster = createSlice({
  name: "SinkVendorMaster",
  initialState: {
    isLoading: false,
    isSuccess: false,
    data: [],
  },
  reducers: {
    sink_vendor_master: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_sink_vendor_master: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_sink_vendor_master: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    sink_vendor_isSuccess: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default SinkVendorMaster.reducer;
export const {
  sink_vendor_master,
  success_api_call_sink_vendor_master,
  sink_vendor_isSuccess,
  failure_api_call_sink_vendor_master,
} = SinkVendorMaster.actions;
