import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const onboardingBasicInfo: any = createSlice({
  name: "onboardingBasicInfo",
  initialState: {
    data: {},
    isCompleted: false,
    isLoading: false,
    isFetchedData: false,
  },
  reducers: {
    submit_basic_info_state: (state, action?: any) => {
      console.log(action.payload);
      state.data = action.payload;
      state.isCompleted = true;
    },
    list_success_api_basic_info: (state, action) => {
      state.isLoading = false;

      if (action.payload.result != 0) {
        const value = action.payload.result[0];
        console.log("value90", value);
        const fetched_data: any = {
          id: value.id,
          branch: value.branch,
          company_name: value.company_name,
          alternate_name: value.foreign_name,
          business_type: value.business_type,
          pan_no: value.pan_no,
          gst_filling_status: value.gst_filling_status,
          gst_type: value.gst_type,
          msme_type: value.msme_type,
          msme: value.msme,
          msme_no: value.msme_no,
          msme_certificate: {
            name: value.msme_certificate_file
              ? "msme certificate uploaded"
              : "",
          },
          pancard_certificate: {
            name: "pancard certificate uploaded",
          },
        };
        state.data = fetched_data;
        state.isCompleted = true;
        state.isFetchedData = true;
      } else {
        state.data = {};
        state.isCompleted = false;
      }
    },
    list_failure_api_basic_nfo: (state, action) => {
      state.data = action.payload;
      state.isCompleted = false;
    },
    raise_api_for_list_onboarding: (state, action) => {
      state.isLoading = true;
    },
    raise_api_completed: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default onboardingBasicInfo.reducer;
export const {
  submit_basic_info_state,
  list_success_api_basic_info,
  list_failure_api_basic_nfo,
  raise_api_for_list_onboarding,
  raise_api_completed,
} = onboardingBasicInfo.actions;
