import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ListBranchMaster = createSlice({
  name: "ListBranchMaster",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_branch_master: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_list_branch_master: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_list_branch_master: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    branch_master_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default ListBranchMaster.reducer;
export const {
  list_branch_master,
  success_api_call_list_branch_master,
  failure_api_call_list_branch_master,
  branch_master_is_first,
} = ListBranchMaster.actions;
