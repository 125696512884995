import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/main-table.css";
const MainTable = (props: any) => {
  // get heders
  const heder1: any = [];
  const keys: any = [];

  for (let j = 0; j < 1; j++) {
    let words: any = Object.keys(props.mainTableData[j]);

    const val1 = words.map((word: any) => {
      keys.push(word);
      let data: any = word.split("_");
      const heder: any = data
        .map((e: any) => {
          if (typeof e[0] != "undefined") {
            return e[0].toUpperCase() + e.substring(1);
          }
        })
        .join(" ");
      heder1.push(heder);
    });
  }

  return (
    <>
      <div>
        <table className="table table-borderless">
          <thead>
            <tr className="color-grey">
              <th scope="col">S.No</th>

              {heder1.map((e: String, index: number) => {
                return (
                  <th key={index} scope="col">
                    {e}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {props.mainTableData.map((e: any, index: number) => {
              return (
                <>
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    {keys.map((a: any, key: number) => {
                      return (
                        <>
                          <td key={key + 1}>
                            {props.nonObject ? e[a] : e[a].value}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MainTable;
