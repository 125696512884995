import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const vendorPayActiveData = createSlice({
  name: "vendorPayActiveData",
  initialState: {
    data: {},
  },
  reducers: {
    addvendorDataData: (state, action?: any) => {
      state.data = action.payload;
    },
  },
});
export default vendorPayActiveData.reducer;
export const { addvendorDataData } = vendorPayActiveData.actions;
