import React, { createRef, useEffect, useState } from "react";
import "../../assets/css/sap-master/sap-master.css";
import MainTable from "../components/main-tables";
import CancelIcon from "@mui/icons-material/Cancel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import {
  branch_master_is_first,
  list_branch_master,
} from "../../redux/prefered-master/list-branch-master";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import {
  add_branch_master,
  add_branch_isSuccess,
  add_branch_master_api_complete,
} from "../../redux/prefered-master/add-branch-master";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { addGroupDataData } from "../../redux/sap-master/group-master-active-data-reducer";
import {
  edit_branch_isSuccess,
  edit_branch_master,
  edit_branch_master_api_complete,
} from "../../redux/prefered-master/edit-branch-master";
import CompletePopup from "../components/response-pop-up";
import { configs } from "../../common";
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const BranchMaster = () => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState<string>("");

  const dispatch = useDispatch();
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const editBranchMaster = useSelector((state: any) => state.EditBranchMaster);
  const activeDatas = useSelector((state: any) => state.GroupMasterActiveData);
  const activeData = activeDatas.data;
  const branch_master_data = useSelector(
    (state: any) => state.ListBranchMaster
  );
  const add_branch_master_data = useSelector(
    (state: any) => state.AddBranchMaster
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  //   validation
  const validationSchema = Yup.object().shape({
    branch_code: Yup.string().required("Branch Code Is Required"),
    branch_name: Yup.string().required("Branch name Is Required"),
    sap_branch_code: Yup.string().required("Sap Branch Code Is Required"),
    branch_logo: Yup.mixed()
      .test(
        "fileFormat",
        "Unsupported Format - We only allow images.",
        (value) => {
          if (value) {
            SUPPORTED_FORMATS.includes(value.type);
          }
          return true;
        }
      )
      .required("branch logo is required")
      .nullable(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      branch_code: isEdit ? editData.branch_code : "",
      branch_name: isEdit ? editData.branch_name : "",
      sap_branch_code: isEdit ? editData.sap_branch_code : "",
      active: isEdit ? editData.active : true,
      branch_logo: isEdit
        ? editData.branch_logo
        : {
            name: "",
          },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;

  const submit = (a: any) => {
    if (!isEdit) {
      const formData = new FormData();
      formData.append("branch_code", a.branch_code);
      formData.append("branch_name", a.branch_name);
      formData.append("active", a.active);
      formData.append("sap_branch_code", a.sap_branch_code);
      formData.append("branch_logo", a.branch_logo);
      dispatch(add_branch_master(formData));
    } else {
      const formData = new FormData();
      formData.append("id", activeData.id);
      formData.append("branch_code", a.branch_code);
      formData.append("active", a.active);
      formData.append("branch_name", a.branch_name);
      formData.append("sap_branch_code", a.sap_branch_code);
      formData.append("branch_logo", a.branch_logo);
      dispatch(edit_branch_master(formData));
      // for (var pair of formData.enttrueries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
    }
  };
  // useEffect
  useEffect(() => {
    dispatch(list_branch_master(""));
    if (add_branch_master_data.isSuccess) {
      dispatch(add_branch_isSuccess(""));
      setOpen(false);
      resetForm();
    }
    if (editBranchMaster.isSuccess) {
      dispatch(edit_branch_isSuccess(""));
      setOpen(false);
      setEdit(false);
      resetForm();
    }
    if (add_branch_master_data.api_completed) {
      setResponsePopup(true);
      dispatch(add_branch_master_api_complete(false));
    }
    if (editBranchMaster.api_completed) {
      setResponseEdit(true);
      dispatch(edit_branch_master_api_complete(false));
      setResponsePopup(true);
    }
  }, [add_branch_master_data, editBranchMaster]);
  const fileRep = createRef<any>();
  // mainTable data
  const mainTable = () => {
    const filterarr = [];
    for (let i = 0; i < branch_master_data.data.result.length; i++) {
      let data = { ...branch_master_data.data.result[i] };
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // create on
      const created_on = new Date(data.created_on);
      // delete data.EffecDate;
      const created_date =
        created_on.getDate() +
        "-" +
        monthNames[created_on.getMonth()] +
        "-" +
        created_on.getFullYear();
      data.created_on
        ? (data.created_on = created_date)
        : console.log("sdfdsf");

      // update on
      const updated_on = new Date(data.updated_on);
      data.branch_logo = data.branch_logo ? (
        <img
          style={{
            maxHeight: "50px",
            maxWidth: "200px",
          }}
          src={configs.api + "/" + data.branch_logo}
          alt="branch logo"
        />
      ) : (
        "-"
      );
      // delete data.EffecDate;
      const update_date =
        updated_on.getDate() +
        "-" +
        monthNames[updated_on.getMonth()] +
        "-" +
        updated_on.getFullYear();
      data.updated_on ? (data.updated_on = update_date) : console.log("sdfdsf");

      filterarr.push(data);
    }
    // filterarr.filter((e) => delete e.id);
    // filterarr.filter((e) => delete e.active);
    // filterarr.filter((e) => delete e.created_by);
    // filterarr.filter((e) => delete e.updated_by);
    console.log(branch_master_data);
    return (
      <>
        {branch_master_data.data.length != 0 ? (
          <>
            <div className="list col-5 ">
              <div className=" px-3 my-4  d-flex align-items-center">
                <div className="title d-flex ">
                  <h5>Branch Master</h5>
                </div>

                <div className="filter ms-auto me-3 d-flex align-items-center">
                  <TextField
                    placeholder="Search"
                    variant="outlined"
                    className="w-100 me-2"
                    name="search"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "0px",
                        height: "45px",
                      },
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      dispatch(list_branch_master(""));
                    }}
                    className="mx-2"
                    style={{
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                    icon={["fas", "arrows-rotate"]}
                  />
                </div>
              </div>
              <div className="list ">
                {filterarr
                  .filter((item: any) => {
                    return Object.values(item)
                      .join("")
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  .map((e: any, key: number) => {
                    if (branch_master_data.isFirst && key === 0) {
                      dispatch(branch_master_is_first(""));
                      dispatch(addGroupDataData(e));
                    }
                    return (
                      <>
                        <div
                          key={key + 1}
                          id={activePartner === key ? "active-lists" : ""}
                          onClick={(a) => {
                            setActivePartner(key);
                            dispatch(addGroupDataData(e));
                          }}
                          className={"d-flex lists flex-column mt-2  p-3"}
                          style={{
                            height: "100px",
                            background: "#FFFFFF",
                          }}
                        >
                          <div className="mx-2 partner-name">
                            {e.branch_name}
                          </div>
                          <div className="d-flex mt-auto partner-locations align-items-center">
                            <p className="ms-2 me-auto mb-0">{e.branch_code}</p>
                            <p
                              className={
                                e.status === "Request Sent for Approval"
                                  ? "ms-auto me-2 mb-0 text-yellow"
                                  : "ms-auto text-light-green me-2 mb-0"
                              }
                            >
                              {/* {e.status} */}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div
              className="col-7 tracking pt-4 px-4"
              style={{
                height: "100vh",
              }}
            >
              <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
                <div
                  className="mx-4 add-branch"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  Add branch
                </div>
                <div>
                  <FontAwesomeIcon
                    onClick={() => {
                      setEdit(true);
                      setEditData(activeData);
                      handleClickOpen();
                    }}
                    className="mx-2"
                    style={{
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                    icon={["fas", "pen-to-square"]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 partner-detail-data mt-4">
                  <label>Branch Name</label>
                  <p>{activeData.branch_name}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Branch Code</label>
                  <p>{activeData.branch_code}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>SAP Branch Code</label>
                  <p>{activeData.sap_branch_code}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Branch Logo</label>
                  <p>{activeData.branch_logo}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Active</label>
                  <p>{activeData.active ? "Active" : "InActive"}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Create On</label>
                  <p>{activeData.created_on}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Update On</label>
                  <p>{activeData.updated_on}</p>
                </div>
              </div>
              {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
            </div>
          </>
        ) : (
          <>
            <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
              <div
                className="mx-4 add-branch"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                Add branch
              </div>
            </div>
            <div>No Data Found</div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="general-setting sap-master">
        {responsePopup ? (
          <CompletePopup
            data={responseEdit ? editBranchMaster : add_branch_master_data}
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
              setResponseEdit(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={branch_master_data.isLoading ? true : false}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        {/* <div className="title d-flex">
          <h5>Branch Master</h5>
          
        </div> */}
        <div className="forms d-flex">
          {branch_master_data.isSuccess ? (
            mainTable()
          ) : (
            <>
              <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
                <div
                  className="mx-4 add-branch"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  Add branch
                </div>
              </div>
              <div>No Data Found</div>
            </>
          )}
        </div>
      </div>
      {/* add from */}
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700">
            {"Add Branch"}{" "}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="d-flex flex-column">
            <div className="row">
              <div className="input-field px-3 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="branch_code"
                  label="Branch Code"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="branch_name"
                  label="Branch Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 col-6 d-flex mt-4 flex-column">
                <TextFiealdInput
                  name="sap_branch_code"
                  label="Sap Branch Code"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3  col-6 d-flex mt-4 flex-column">
                <label className="mx-3">Branch Logo</label>
                <div
                  className={
                    errors.branch_logo && touched.branch_logo
                      ? "msme-certificate-require mx-3"
                      : "msme-certificate mx-3"
                  }
                  onClick={() => {
                    fileRep.current.click();
                    console.log("DSF");
                  }}
                >
                  <FontAwesomeIcon
                    className="ms-50"
                    icon={["fas", "paperclip"]}
                    style={{
                      fontSize: "1vw",
                      marginLeft: "10px",
                      marginRight: "10px",
                      color: "#908f8f",
                    }}
                  />
                  <label>
                    {values.branch_logo.name === ""
                      ? "Upload branch logo"
                      : values.branch_logo.name}
                  </label>
                </div>
                <span
                  className={
                    errors.branch_logo && touched.branch_logo
                      ? "text-danger mx-3"
                      : "mx-3"
                  }
                >
                  Supported file(.jpeg,.jpg,.png)
                </span>
                <TextField
                  inputRef={fileRep}
                  type="file"
                  // value={values.msme_certificate}
                  name="branch_logo"
                  onChange={(e: any) => {
                    setFieldValue("branch_logo", e.target.files[0]);
                    setFieldTouched("branch_logo", true, false);
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    display: "none",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <div className="col-6 d-flex ms-3 align-items-center">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox checked={values.active} />}
                    label="Is Active"
                    onChange={handleChange}
                    name="active"
                    labelPlacement="end"
                    sx={{
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    onClick={() => {
                      handleClose();
                      setEdit(false);
                    }}
                    className="cancel"
                  >
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {add_branch_master_data.isLoading
                      ? "Please Wait..."
                      : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BranchMaster;
