import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import {
  success_api_call_basic_info,
  failure_api_call_basic_info,
} from "./basic-info-add-reducer";
import {
  success_api_call_contact,
  failure_api_call_contact,
} from "./contact-details-add-reducer";
import {
  success_api_call_address,
  failure_api_call_address,
} from "./address-add-reducer";
import {
  success_api_call_bank_detail,
  failure_api_call_bank_detail,
} from "./bank-detail-add-reducer";
import { isVariableStatement } from "typescript";
import {
  failure_api_call_edit_bank_detail,
  success_api_call_edit_bank_detail,
} from "./bank-detail-edit-reducer";
import {
  failure_api_call_edit_address,
  success_api_call_edit_address,
} from "./address-edit-reducer";
import {
  failure_api_edit_call_contact,
  success_api_edit_call_contact,
} from "./contact-edit-reducer";
import {
  failure_api_call_edit_basic_info,
  success_api_call_edit_basic_info,
} from "./basic-info-edit-reducer";
import { configs } from "../../../common";

// const api = "http://185.209.228.65:3035/api4/list";
//192.168.1.107:3035

// local ipp
// const basicinfo_api = "http://192.168.1.114:3035/api6/update";
// const contact_api = "http://192.168.1.114:3035/api/person/update";
// const address_api = "http://192.168.1.114:3035/api/address/update";
// const banck__api = "http://192.168.1.114:3035/api/bankdetail/update";
// server ip

const basicinfo_api = configs.api + "/api6/update";
const contact_api = configs.api + "/api/person/update";
const address_api = configs.api + "/api/address/update";
const banck__api = configs.api + "/api/bankdetail/update";

const getCallBasicInfo = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(basicinfo_api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallContact = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(contact_api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallAddress = async (a: any) => {
  for (var pair of a.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(address_api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallBankDetail = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(banck__api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* EditOnboardingApi(e: any) {
  try {
    const response_basic_info: AxiosResponse = yield call(
      getCallBasicInfo,
      e.payload.basicInfo
    );
    if (response_basic_info.data.status) {
      yield put(success_api_call_edit_basic_info(response_basic_info.data));
    } else {
      yield put(failure_api_call_edit_basic_info(response_basic_info.data));
    }

    const response_contact: AxiosResponse = yield call(
      getCallContact,
      e.payload.contact_info
    );
    if (response_contact.data.status) {
      yield put(success_api_edit_call_contact(response_contact.data));
    } else {
      yield put(failure_api_edit_call_contact(response_contact.data));
    }

    const response_address: AxiosResponse = yield call(
      getCallAddress,
      e.payload.address_info
    );
    if (response_address.data.status) {
      yield put(success_api_call_edit_address(response_address.data));
    } else {
      yield put(failure_api_call_edit_address(response_address.data));
    }

    const response_bank_detail: AxiosResponse = yield call(
      getCallBankDetail,
      e.payload.bank_detail
    );
    if (response_bank_detail.data.status) {
      yield put(success_api_call_edit_bank_detail(response_bank_detail.data));
    } else {
      yield put(failure_api_call_edit_bank_detail(response_bank_detail.data));
    }
  } catch (err) {
    yield put(failure_api_call_edit_bank_detail(err));
  }
}
function edit_basic_complete(arg0: string): any {
  throw new Error("Function not implemented.");
}
