import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const SampleApiCall=createSlice({
    name:"Sample",
    initialState:{
        isLoading:false,
        data:[]
    },
    reducers:{
        raise_api_call:(state,action?)=>{
            state.isLoading=true
        },
        success_api_call:(state,action)=>{
            state.data=action.payload
            state.isLoading=false
        },
        failure_api_call:(state,action)=>{
            state.isLoading=false
        }

    }
})
export default SampleApiCall.reducer
export const {raise_api_call,success_api_call,failure_api_call}=SampleApiCall.actions
