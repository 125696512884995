import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const AgreementMaster = createSlice({
  name: "AgreementMaster",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_agreement_master: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_agreement_master: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_agreement_master: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    agreement_master_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default AgreementMaster.reducer;
export const {
  list_agreement_master,
  success_api_call_agreement_master,
  failure_api_call_agreement_master,
  agreement_master_is_first,
} = AgreementMaster.actions;
