import { createSlice } from "@reduxjs/toolkit";

export const onboardingAddressInfo = createSlice({
  name: "onboardingAddressInfo",
  initialState: {
    data: [],
    isCompleted: false,
    isLoading: false,
  },
  reducers: {
    submit_Address_info_state: (state, action?: any) => {
      state.data = action.payload;
      state.isCompleted = true;
    },

    list_success_api_address_info: (state, action) => {
      const responce_data: any = [];
      action.payload.result.forEach((a: any) => {
        const data: any = {
          id: a.id,
          address_type: a.address_type,
          address_title: a.address_title,
          address_id: a.address_id,
          address_name3: a.address_name3,
          block: a.block,
          address_name2: a.address_name2,
          street_pobox: a.street_pobox,
          gst_type: a.gst_type,
          city: a.city,
          zip_code: a.zip_code,
          country: a.country,
          state: a.state,
          gstin: a.gstin,
          default: a.default,
          gst_certificate: a.gst_certificate,
        };
        responce_data.push(data);
      });
      state.data = responce_data;
      state.isCompleted = true;
    },
    list_failure_api_address_info: (state, action) => {
      state.data = action.payload;
      state.isCompleted = false;
    },
  },
});
export default onboardingAddressInfo.reducer;
export const {
  submit_Address_info_state,
  list_success_api_address_info,
  list_failure_api_address_info,
} = onboardingAddressInfo.actions;
