import React from "react";
import { Route, Routes } from "react-router-dom";
import RoutePage from "./rute-pages";
import Login from "./pages/login-and-logout/login";
import CreateNewPassword from "./pages/login-and-logout/create-new-password";
import PrivateRoute from "./private-route";

const Router = (props: any): React.ReactElement | null => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/create-password" element={<CreateNewPassword />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <RoutePage />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </>
  );
};
export default Router;
