import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

export const CompletePopup = (props: any) => {
  return (
    <>
      <Dialog
        PaperProps={{
          style: { borderRadius: 0, width: "700px", height: "270px" },
        }}
        open={props.responsePopup}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex  justify-content-center">
          <div
            style={{
              marginTop: "40px",
              fontSize: "23px",
              textAlign: "center",
              padding: "0 20px",
            }}
            id="alert-dialog-title"
            className="font-weight-700 pb-0 d-flex justify-content-center"
          >
            {props.data.data.length != 0 ? props.data.data.message : ""}
          </div>
        </div>
        <DialogContent className="pt-0">
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="d-flex flex-column">
            <div></div>
            <div className="col-12 d-flex justify-content-center mt-4">
              <div
                className="add-more px-3 col-3 d-flex flex-column "
                style={{
                  width: "200px",
                }}
              >
                <div
                  onClick={() => {
                    props.setCompletePopup(false);
                  }}
                  className="cancel d-flex justify-content-center"
                >
                  OK
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CompletePopup;
