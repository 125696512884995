import React, { lazy } from "react";
import { configureStore } from "@reduxjs/toolkit";
import SampleApiCall from "./get-api-call-reducers";
import createSagaMiddleware from "redux-saga";
import saga from "./saga-api-call";
import Login from "./login-and-create-password/login-reducer";
import CreatePassword from "./login-and-create-password/create-password-reducer";
import AddGeneralSetting from "./general-setting/add-general-setting-reducer";
import GeneralSetting from "./general-setting/list-general-setting-reducer";
import onboardingBasicInfo from "./business-partner/onboarding/basic-info-reducer";
import onboardingContactInfo from "./business-partner/onboarding/contact-details-reducer";
import onboardingAddressInfo from "./business-partner/onboarding/address-reducer";
import onboardingBankDetail from "./business-partner/onboarding/bank-detail-reducer";
import getOnboardingAggrementOtp from "./business-partner/onboarding/get-aggrement-otp-reducer";
import onboardingAddBasicInfo from "./business-partner/onboarding/basic-info-add-reducer";
import onboardingAddContact from "./business-partner/onboarding/contact-details-add-reducer";
import onboardingAddress from "./business-partner/onboarding/address-add-reducer";
import onboardingAddBankDetails from "./business-partner/onboarding/bank-detail-add-reducer";
import EditGeneralSetting from "./general-setting/edit-general-setting-reducer";
import listPartnerMaster from "./business-partner/partner-master/list-partner-master";
import AddPartnerData from "./business-partner/partner-master/partner-master-first-data";
import CreatePartnerDetails from "./business-partner/partner-master/create-partner-master-reducer";
import ListNotification from "./notification/list-notification-reducer";
import ReadOneNotification from "./notification/single-notification-read";
import ApprovePartner from "./business-partner/partner-master/approve-partner";
import WTAXMasterListApi from "./sap-master/list-wtax-master";
import BankMasterListApi from "./sap-master/bank-master";
import DownPayAccount from "./sap-master/downpayaccount";
import CustomerReceivable from "./sap-master/customer-recivable";
import ListAgreementMaster from "./prefered-master/list-agreement-master";
import ListBranchMaster from "./prefered-master/list-branch-master";
import GroupMasterList from "./sap-master/group-master";
import VendorPayListApi from "./sap-master/vendor-pay";
import AddBranchMaster from "./prefered-master/add-branch-master";
import AddAgreementMaster from "./prefered-master/add-agreement";
import BankIFSCCodeListApi from "./sap-master/get-bank-ifsc-code";
import UpdateAcountsOnboarding from "./business-partner/onboarding/update-acounts-onboarding";
import GroupMasterActiveData from "./sap-master/group-master-active-data-reducer";
import vendorPayActiveData from "./sap-master/vendor-pay-active-reducer";
import getOnboardingAggrement from "./business-partner/onboarding/get-agreements";
import VerifyOtpOnboarding from "./business-partner/onboarding/verify-otp-reducer";
import CustomerReceivableActiveData from "./sap-master/customer-recivable-active-data";
import DownPayAccountActiveData from "./sap-master/down-pay-account-active-data";
import BusinessChannelList from "./prefered-master/business-channel-list";
import AddBusinessBhannel from "./prefered-master/add-business-channel";
import GenerateCostCentre from "./business-partner/onboarding/get-ar-andhd-locations";
import ListConsalidationBusinessPartner from "./business-partner/onboarding/list-consalidation-business-partner";
import SinkwtaxMaster from "./sap-master/wtax-sink-master";
import SinkbankMaster from "./sap-master/brank-master-sink-api";
import SinkCustomerReceivableMaster from "./sap-master/sink-customer-receivable";
import SinkDownPayAccountMaster from "./sap-master/sink-down-pay-account";
import SinkGroupMaster from "./sap-master/sink-group-master";
import SinkVendorMaster from "./sap-master/sink-vendor-pay";
import listOnePartnerMaster from "./business-partner/partner-master/list-one-partner-master";
import RequestClarification from "./business-partner/partner-master/request-clarification";
import CheckUserExist from "./login-and-create-password/email-exist-reducer";
import Menus from "./login-and-create-password/menu-reducer";
import ListRole from "./add-user/list-role";
import AddUser from "./add-user/add-user";
import ListUser from "./add-user/list-user";
import editUser from "./add-user/edit-user";
import EditBusinessBhannel from "./prefered-master/edit-business-channel";
import EditBranchMaster from "./prefered-master/edit-branch-master";
import EditAgreementMaster from "./prefered-master/edit-agreement-master";
import onboardingEditBasicInfo from "./business-partner/onboarding/basic-info-edit-reducer";
import onboardingEditAddress from "./business-partner/onboarding/address-edit-reducer";
import onboardingEditContact from "./business-partner/onboarding/contact-edit-reducer";
import SubmitClarification from "./business-partner/onboarding/clarification-submited";
import AddStateMaster from "./prefered-master/add-state-master";
import StateMasterList from "./prefered-master/list-state-master";
import EditStateMaster from "./prefered-master/edit-state-master";
import CheckAgreementAvailable from "./business-partner/onboarding/check-agreement-valid-or-not";
import PartnerMasterToSap from "./business-partner/partner-master/move-xml-data";
import listOnePartnerDetail from "./business-partner/partner-master/list-one-partner-details";
import onboardingEditBankDetails from "./business-partner/onboarding/bank-detail-edit-reducer";
import ListOneAgreementMaster from "./prefered-master/list-one-agreement";
import UpdatePartnerDetails from "./business-partner/partner-master/update-partner-master-reducer";

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    SampleApiCall: SampleApiCall,
    Login: Login,
    onboardingBasicInfo: onboardingBasicInfo,
    onboardingContactInfo: onboardingContactInfo,
    onboardingAddressInfo: onboardingAddressInfo,
    onboardingBankDetail: onboardingBankDetail,
    onboardingAddBasicInfo: onboardingAddBasicInfo,
    getOnboardingAggrementOtp: getOnboardingAggrementOtp,
    CreatePassword: CreatePassword,
    AddGeneralSetting: AddGeneralSetting,
    GeneralSetting: GeneralSetting,
    EditGeneralSetting: EditGeneralSetting,
    onboardingAddContact: onboardingAddContact,
    onboardingAddress: onboardingAddress,
    listPartnerMaster: listPartnerMaster,
    onboardingAddBankDetails: onboardingAddBankDetails,
    AddPartnerData: AddPartnerData,
    CreatePartnerDetails: CreatePartnerDetails,
    UpdatePartnerDetails: UpdatePartnerDetails,
    ListNotification: ListNotification,
    ReadOneNotification: ReadOneNotification,
    ApprovePartner: ApprovePartner,
    WTAXMasterListApi: WTAXMasterListApi,
    BankMasterListApi: BankMasterListApi,
    DownPayAccount: DownPayAccount,
    CustomerReceivable: CustomerReceivable,
    ListBranchMaster: ListBranchMaster,
    ListAgreementMaster: ListAgreementMaster,
    GroupMasterList: GroupMasterList,
    VendorPayListApi: VendorPayListApi,
    AddBranchMaster: AddBranchMaster,
    AddAgreementMaster: AddAgreementMaster,
    ListOneAgreementMaster: ListOneAgreementMaster,
    BankIFSCCodeListApi: BankIFSCCodeListApi,
    UpdateAcountsOnboarding: UpdateAcountsOnboarding,
    GroupMasterActiveData: GroupMasterActiveData,
    vendorPayActiveData: vendorPayActiveData,
    getOnboardingAggrement: getOnboardingAggrement,
    VerifyOtpOnboarding: VerifyOtpOnboarding,
    CustomerReceivableActiveData: CustomerReceivableActiveData,
    DownPayAccountActiveData: DownPayAccountActiveData,
    BusinessChannelList: BusinessChannelList,
    EditBusinessBhannel: EditBusinessBhannel,
    AddBusinessBhannel: AddBusinessBhannel,
    GenerateCostCentre: GenerateCostCentre,
    ListConsalidationBusinessPartner: ListConsalidationBusinessPartner,
    SinkbankMaster: SinkbankMaster,
    SinkwtaxMaster: SinkwtaxMaster,
    SinkCustomerReceivableMaster: SinkCustomerReceivableMaster,
    SinkDownPayAccountMaster: SinkDownPayAccountMaster,
    SinkGroupMaster: SinkGroupMaster,
    SinkVendorMaster: SinkVendorMaster,
    listOnePartnerMaster: listOnePartnerMaster,
    RequestClarification: RequestClarification,
    CheckUserExist: CheckUserExist,
    ListRole: ListRole,
    Menus: Menus,
    AddUser: AddUser,
    ListUser: ListUser,
    editUser: editUser,
    EditBranchMaster: EditBranchMaster,
    onboardingEditBasicInfo: onboardingEditBasicInfo,
    EditAgreementMaster: EditAgreementMaster,
    onboardingEditAddress: onboardingEditAddress,
    onboardingEditContact: onboardingEditContact,
    SubmitClarification: SubmitClarification,
    AddStateMaster: AddStateMaster,
    StateMasterList: StateMasterList,
    EditStateMaster: EditStateMaster,
    CheckAgreementAvailable: CheckAgreementAvailable,
    PartnerMasterToSap: PartnerMasterToSap,
    listOnePartnerDetail: listOnePartnerDetail,
    onboardingEditBankDetails: onboardingEditBankDetails,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(saga);
export type RootState = ReturnType<typeof store.getState>;
export default store;
