import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/general-setting/general-setting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AutoComplete from "../components/autocomplete-input";
import TextFiealdInput from "../components/textFiald";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import CompletePopup from "../components/response-pop-up";
import {
  create_general_setting_complete_api,
  raise_api_call_add_general_setting,
} from "../../redux/general-setting/add-general-setting-reducer";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import {
  edit_general_setting_complete_api,
  raise_api_call_edit_general_setting,
} from "../../redux/general-setting/edit-general-setting-reducer";
const GeneralSetting = () => {
  const PageLists = useSelector((state: any) => state.GeneralSetting);
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEdit] = useState<any>();
  const [search, setSearch] = useState<string>("");
  const validationSchema = Yup.object().shape({
    page: Yup.string().required("page Is Required"),
    key: Yup.string().required("Key Is Required"),
    name: Yup.string().required("Name  Is Required"),
    description: Yup.string().required("Description Is Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      page: isEdit ? editData.page : "",
      key: isEdit ? editData.key : "",
      name: isEdit ? editData.name : "",
      description: isEdit ? editData.description : "",
      active: isEdit ? editData.active : true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  console.log(editData);

  // page lists
  // submit data types

  type submitData = {
    id?: string;
    page: string;
    key: string;
    name: string;
    description: string;
    active: boolean | string;
  };

  const dispatch = useDispatch();
  const addGeneralSetting = useSelector(
    (state: any) => state.AddGeneralSetting
  );
  const editGeneralSetting = useSelector(
    (state: any) => state.EditGeneralSetting
  );
  // useEffect
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    if (addGeneralSetting.data.status) {
      resetForm();
    }
    if (addGeneralSetting.api_completed) {
      setResponsePopup(true);
      dispatch(create_general_setting_complete_api(false));
    }
    if (editGeneralSetting.api_completed) {
      setResponseEdit(true);
      dispatch(edit_general_setting_complete_api(false));
      setResponsePopup(true);
    }
  }, [addGeneralSetting, editGeneralSetting]);

  const submit = (e: submitData) => {
    let data: submitData = {
      page: e.page,
      key: e.key,
      name: e.name,
      description: e.description,
      active: e.active,
    };
    if (isEdit) {
      data.id = editData.id;

      dispatch(raise_api_call_edit_general_setting(data));
    } else {
      dispatch(raise_api_call_add_general_setting(data));
    }
  };

  const pageOption = PageLists.data.status
    ? PageLists.data.result
        .filter((e: any) => {
          if (e.page == "general_setting") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.description };
          return data;
        })
    : [{ label: "No Page", value: "no Page" }];

  return (
    <>
      <div className=" general-setting">
        {responsePopup ? (
          <CompletePopup
            data={responseEdit ? editGeneralSetting : addGeneralSetting}
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
              setResponseEdit(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={PageLists.isLoading ? true : false}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className="forms d-flex">
          <div className="list col-5">
            <div className="title d-flex m-4">
              <h5>Add General Settings</h5>
              <div
                style={{
                  width: "250px",
                }}
                className="ms-auto me-2"
              >
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="w-100 me-2"
                  name="search"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "45px",
                    },
                  }}
                />
              </div>
              <div
                className="ms-2 d-flex align-items-center mb-2"
                onClick={() => {
                  setIsEdit(false);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  title="add general setting"
                  style={{
                    fontWeight: "700",
                    fontSize: "21px",
                    color: "white",
                    padding: "10px",
                    background: "#46a4e3",
                    marginBottom: "2px",
                  }}
                  icon={["fas", "plus"]}
                />
              </div>
            </div>
            <div className="general-setting-list">
              <div className="list">
                {PageLists.data.status
                  ? PageLists.data.result
                      .filter((item: any) => {
                        return Object.values(item)
                          .join("")
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      })
                      .map((e: any, key: number) => {
                        // console.log(e);
                        return (
                          <>
                            <div
                              key={key + 1}
                              id={activePartner === key ? "active-lists" : ""}
                              onClick={() => {
                                setActivePartner(key);
                                setIsEdit(true);
                                setEdit(e);
                              }}
                              className={"d-flex lists flex-column mt-2  p-3"}
                              style={{
                                height: "100px",
                                background: "#FFFFFF",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                className="mx-2 partner-name"
                                style={{
                                  fontWeight: "700",
                                }}
                              >
                                {e.name}
                              </div>
                              <div className="d-flex mt-auto partner-locations align-items-center">
                                <p className="ms-2 me-auto mb-0">
                                  {e.description}
                                </p>
                                <p
                                  className={
                                    e.status === "Request Sent for Approval"
                                      ? "ms-auto me-2 mb-0 text-yellow"
                                      : "ms-auto text-light-green me-2 mb-0"
                                  }
                                >
                                  {e.status}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                  : ""}
              </div>
            </div>
          </div>
          <form
            className="col-7 tracking pt-4 "
            style={{
              height: "100vh",
            }}
          >
            <div className="row">
              <div className="col-6">
                <AutoComplete
                  className="w-100"
                  pageOption={pageOption}
                  name="page"
                  handleBlur={handleBlur}
                  values={values}
                  vari={(e: any) => {
                    setFieldValue("page", e ? e.value : "");
                  }}
                  label="Key 1"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col-6">
                <TextFiealdInput
                  handleChange={handleChange}
                  name="key"
                  handleBlur={handleBlur}
                  values={values}
                  label="Key 2"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col-6 mt-4">
                <TextFiealdInput
                  handleChange={handleChange}
                  name="name"
                  handleBlur={handleBlur}
                  values={values}
                  label="Value"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col-6 mt-4">
                <TextFiealdInput
                  handleChange={handleChange}
                  name="description"
                  handleBlur={handleBlur}
                  values={values}
                  label="Description"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col-12 mt-4">
                <div className="col-6 d-flex ms-3 align-items-center">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox defaultChecked />}
                    label="Is Active"
                    onChange={handleChange}
                    name="active"
                    labelPlacement="end"
                    sx={{
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-auto general-setting-button">
              <div className="botom-navigation d-flex justify-content-end align-items-center ">
                <div className="next-and-back">
                  {/* <div className="back">Back</div> */}
                  <div
                    onClick={() => {
                      if (!addGeneralSetting.isLoading) {
                        handleSubmit();
                      }
                    }}
                    className="next"
                    style={{
                      width: "200px",
                    }}
                  >
                    {addGeneralSetting.isLoading || editGeneralSetting.isLoading
                      ? "Please wait..."
                      : "Submit"}{" "}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default GeneralSetting;
