import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const listOnePartnerDetail = createSlice({
  name: "listOnePartnerDetail",
  initialState: {
    data: [],
    isLoading: false,
    isFirst: true,
  },
  reducers: {
    raise_api_call_list_partner_one_detail: (state, action?) => {
      state.isLoading = true;
    },
    list_success_partner_one_detail: (state, action) => {
      console.log(action.payload);
      state.data = action.payload;
      state.isFirst = true;
      state.isLoading = false;
    },
    list_failure_partner_one_detail: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    partner_detail_one_isFirst: (state, action) => {
      state.isFirst = false;
    },
    clear_single_partner_details: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isFirst = true;
    },
  },
});
export default listOnePartnerDetail.reducer;
export const {
  raise_api_call_list_partner_one_detail,
  list_success_partner_one_detail,
  list_failure_partner_one_detail,
  partner_detail_one_isFirst,
  clear_single_partner_details,
} = listOnePartnerDetail.actions;
