import React, { useEffect, useState } from "react";
import "../../assets/css/sap-master/sap-master.css";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { InputAdornment } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import { addGroupDataData } from "../../redux/sap-master/group-master-active-data-reducer";
import {
  business_channel_is_first,
  list_business_channel,
} from "../../redux/prefered-master/business-channel-list";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AutoComplete from "../components/autocomplete-input";

import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import {
  add_business_channel,
  add_business_channel_isSuccess,
} from "../../redux/prefered-master/add-business-channel";
import {
  edit_business_channel,
  edit_business_channel_isSuccess,
} from "../../redux/prefered-master/edit-business-channel";
import { list_branch_master } from "../../redux/prefered-master/list-branch-master";
import {
  add_state_master,
  add_state_master_api_complete,
  add_state_master_isSuccess,
} from "../../redux/prefered-master/add-state-master";
import {
  list_state_master,
  list_state_master_is_first,
} from "../../redux/prefered-master/list-state-master";
import {
  edit_state_master,
  edit_state_master_api_complete,
  edit_state_master_isSuccess,
} from "../../redux/prefered-master/edit-state-master";
import CompletePopup from "../components/response-pop-up";

const StateMaster = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  const editStateMasterApi = useSelector((state: any) => state.EditStateMaster);
  const addStateMaster = useSelector((state: any) => state.AddStateMaster);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<{
    state_code: string;
    state_name: string;
    dimension: string;
    active: boolean;
    country: string;
  }>({
    state_code: "",
    state_name: "",
    dimension: "",
    country: "",
    active: true,
  });
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const [search, setSearch] = useState<string>("");
  const activeDatas = useSelector((state: any) => state.GroupMasterActiveData);
  const activeData = activeDatas.data;

  const state_master_list_data = useSelector(
    (state: any) => state.StateMasterList
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  // useEffect
  useEffect(() => {
    dispatch(list_state_master(""));

    dispatch(raise_api_call_list_general_setting(""));
    if (addStateMaster.isSuccess) {
      dispatch(add_state_master_isSuccess(""));
      setOpen(false);
    }
    if (editStateMasterApi.isSuccess) {
      dispatch(edit_state_master_isSuccess(""));
      setOpen(false);
      setEdit(false);
    }
    if (addStateMaster.api_completed) {
      setResponsePopup(true);
      dispatch(add_state_master_api_complete(false));
    }
    if (editStateMasterApi.api_completed) {
      setResponseEdit(true);
      dispatch(edit_state_master_api_complete(false));
      setResponsePopup(true);
    }
  }, [addStateMaster, editStateMasterApi]);

  const validationSchema = Yup.object().shape({
    state_code: Yup.string().required(" Required"),
    state_name: Yup.string().required("Required"),
    dimension: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      state_code: isEdit ? editData.state_code : "",
      state_name: isEdit ? editData.state_name : "",
      active: isEdit ? editData.active : true,
      dimension: isEdit ? editData.dimension : "",
      country: isEdit ? editData.country : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;

  const submit = (a: any) => {
    if (!isEdit) {
      const data = {
        state_code: a.state_code,
        state_name: a.state_name,
        active: a.active,
        dimension: a.dimension,
        country: a.country,
      };

      dispatch(add_state_master(data));
    } else {
      const data = {
        id: activeData.id,
        state_code: a.state_code,
        state_name: a.state_name,
        active: a.active,
        dimension: a.dimension,
        country: a.country,
      };
      dispatch(edit_state_master(data));
    }
  };
  // mainTable data
  const mainTable = () => {
    let filterarr: any = [];
    if (state_master_list_data.data.length != 0) {
      state_master_list_data.data.result.forEach((a: any) => {
        const data = { ...a };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const created_on = new Date(a.created_on);
        // delete data.EffecDate;
        const created_date =
          created_on.getDate() +
          "-" +
          monthNames[created_on.getMonth()] +
          "-" +
          created_on.getFullYear();
        data.created_on
          ? (data.created_on = created_date)
          : console.log("sdfdsf");
        // update on
        const updated_on = new Date(data.updated_On);
        // delete data.EffecDate;
        const update_date =
          updated_on.getDate() +
          "-" +
          monthNames[updated_on.getMonth()] +
          "-" +
          updated_on.getFullYear();
        data.updated_On
          ? (data.updated_On = update_date)
          : console.log("sdfdsf");
        filterarr.push(data);
      });
    }
    console.log(values);
    // filterarr.filter((e) => delete e.id);
    // filterarr.filter((e) => delete e.active);
    // filterarr.filter((e) => delete e.created_by);
    // filterarr.filter((e) => delete e.updated_by);

    return (
      <>
        {/* {state_master_list_data.data.length != 0 ? ( */}
        <>
          <div className="list col-5">
            <div className=" px-3 my-4  d-flex align-items-center">
              <div className="title d-flex ">
                <h5>State Master</h5>
              </div>

              <div className="filter ms-auto me-3 d-flex align-items-center">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="w-100 me-2"
                  name="search"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "45px",
                    },
                  }}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    dispatch(list_business_channel(""));
                  }}
                  className="mx-2"
                  style={{
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                  icon={["fas", "arrows-rotate"]}
                />
              </div>
            </div>
            <div className="list">
              {filterarr
                .filter((item: any) => {
                  return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                .map((e: any, key: number) => {
                  if (state_master_list_data.isFirst && key === 0) {
                    dispatch(list_state_master_is_first(""));
                    dispatch(addGroupDataData(e));
                  }
                  return (
                    <>
                      <div
                        key={key + 1}
                        id={activePartner === key ? "active-lists" : ""}
                        onClick={(a) => {
                          setActivePartner(key);
                          dispatch(addGroupDataData(e));
                        }}
                        className={"d-flex lists flex-column mt-2  p-3"}
                        style={{
                          height: "100px",
                          background: "#FFFFFF",
                        }}
                      >
                        <div className="mx-2 partner-name">{e.state_name}</div>
                        <div className="d-flex mt-auto partner-locations align-items-center">
                          <p className="ms-2 me-auto mb-0">{e.state_code}</p>
                          <p
                            className={
                              e.status === "Request Sent for Approval"
                                ? "ms-auto me-2 mb-0 text-yellow"
                                : "ms-auto text-light-green me-2 mb-0"
                            }
                          >
                            {/* {e.status} */}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div
            className="col-7 tracking pt-4 px-4"
            style={{
              height: "100vh",
            }}
          >
            <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
              <div
                className="mx-4 add-branch"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                Add State
              </div>
              {Object.keys(activeData).length != 0 && activeData.state_name ? (
                <div>
                  <FontAwesomeIcon
                    onClick={() => {
                      setEdit(true);
                      setEditData(activeData);
                      handleClickOpen();
                    }}
                    title="edit"
                    className="mx-2"
                    style={{
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                    icon={["fas", "pen-to-square"]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {Object.keys(activeData).length != 0 && activeData.name ? (
              <>
                <div className="row">
                  <div className="col-8 partner-detail-data mt-4">
                    <label>State Name</label>
                    <p>{activeData.state_name}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>State Code</label>
                    <p>{activeData.state_code}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Dimension</label>
                    <p>{activeData.dimension}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Country</label>
                    <p>{activeData.country}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Active</label>
                    <p>{activeData.active ? "Active" : "InActive"}</p>
                  </div>

                  <div className="col-4 partner-detail-data mt-4">
                    <label>Create On</label>
                    <p>{activeData.created_on}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Update On</label>
                    <p>{activeData.updated_On}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>Please Select Anyone</div>
              </>
            )}
            {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
          </div>
        </>
        {/* ) : (
          <div>No Data Found</div>
        )} */}
      </>
    );
  };
  const country_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "country") {
                return e;
              }
            })
            .sort((a: any, b: any) => {
              return a.description > b.description ? 1 : -1;
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  return (
    <>
      <div className="general-setting sap-master">
        {responsePopup ? (
          <CompletePopup
            data={responseEdit ? editStateMasterApi : addStateMaster}
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
              setResponseEdit(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            generalSettingList.isLoading || state_master_list_data.isLoading
              ? true
              : false
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className="forms d-flex">
          {
            //   state_master_list_data.isSuccess ? (
            mainTable()
            //   ) : (
            //     <div>No Data Found</div>
            //   )
          }
        </div>
      </div>
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700">
            {"Add State"}{" "}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="d-flex flex-column">
            <div className="row">
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="state_name"
                  label="State Name"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="state_code"
                  label="State Code"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="dimension"
                  label="Dimension"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>

              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <AutoComplete
                  pageOption={country_option}
                  name="country"
                  label="Country"
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  vari={(e: any) => {
                    setFieldValue("country", e ? e.value : "");
                  }}
                />
              </div>

              <div className="col-12 mt-4">
                <div className="col-6 d-flex ms-3 align-items-center">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox checked={values.active} />}
                    label="Is Active"
                    onChange={handleChange}
                    name="active"
                    labelPlacement="end"
                    sx={{
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    onClick={() => {
                      handleClose();
                      setEdit(false);
                    }}
                    className="cancel"
                  >
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {addStateMaster.isLoading || editStateMasterApi.isLoading
                      ? "Please Wait"
                      : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StateMaster;
