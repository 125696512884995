import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { add_basic_info_loading_complete } from "./basic-info-add-reducer";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const onboardingAddress: any = createSlice({
  name: "onboardingAddress",
  initialState: {
    data: [],
    isCompleted: false,
    isLoading: false,
  },
  reducers: {
    raise_api_call_address: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_address: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_address: (state, action) => {
      state.isLoading = false;
      FAILED(action.payload);
    },
  },
});
export default onboardingAddress.reducer;
export const {
  raise_api_call_address,
  success_api_call_address,
  failure_api_call_address,
} = onboardingAddress.actions;
