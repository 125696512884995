import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const onboardingBankDetail = createSlice({
  name: "onboardingBankDetail",
  initialState: {
    data: {},
    isCompleted: false,
  },
  reducers: {
    submit_bank_detail_state: (state, action?: any) => {
      state.data = action.payload;
      state.isCompleted = true;
    },
    list_success_api_bank_detail: (state, action) => {
      const value = action.payload.result[0];
      const data: any = {
        id: value.id,
        branch: value.branch,
        bank_name: value.bank_name,
        bank_code: value.bank_code,
        IFSC_code: value.bic_ifsc_swift_code,
        account_number: value.account_no,
        gst_filling_status: value.gst_filling_status,
        bank_account_name: value.bank_account_name,
        cancelled_cheque: {
          name: "cancelled check uploaded",
        },
      };
      console.log(data);
      state.data = data;
      state.isCompleted = true;
    },
    list_failure_api_bank_detail: (state, action) => {
      state.data = action.payload;
      state.isCompleted = false;
    },
  },
});
export default onboardingBankDetail.reducer;
export const {
  submit_bank_detail_state,
  list_success_api_bank_detail,
  list_failure_api_bank_detail,
} = onboardingBankDetail.actions;
