import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { configs } from "../../../common";

export const getOnboardingAggrement = createSlice({
  name: "getOnboardingAggrement",
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {
    get_agreement_from_api: (state, action?) => {
      state.isLoading = true;
    },
    success_api_agreement_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_agreement_call: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default getOnboardingAggrement.reducer;
export const {
  get_agreement_from_api,
  success_api_agreement_call,
  failure_api_agreement_call,
} = getOnboardingAggrement.actions;

//

const api = configs.api + "/api/partner/aggrement";
// const api = "http://192.168.1.114:3035/api5/aggrement";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* getAgremeent(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);

    yield put(success_api_agreement_call(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_agreement_call(e));
  }
}
