import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const onboardingEditBankDetails: any = createSlice({
  name: "onboardingEditBankDetails",
  initialState: {
    data: [],
    isCompleted: false,
    isLoading: false,
    isSuccess: false,
  },
  reducers: {
    raise_api_call_edit_bank_detail: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_edit_bank_detail: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isCompleted = true;
      SUCCESE(action.payload.message);
    },
    failure_api_call_edit_bank_detail: (state, action) => {
      state.isLoading = false;
      FAILED(action.payload);
    },
    edit_bank_is_success: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default onboardingEditBankDetails.reducer;
export const {
  raise_api_call_edit_bank_detail,
  success_api_call_edit_bank_detail,
  failure_api_call_edit_bank_detail,
  edit_bank_is_success,
} = onboardingEditBankDetails.actions;
