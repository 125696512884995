import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const AddGeneralSetting = createSlice({
  name: "AddGeneralSetting",
  initialState: {
    isLoading: false,
    api_completed: false,
    data: [],
  },
  reducers: {
    raise_api_call_add_general_setting: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.api_completed = true;
    },
    failure_api_call: (state, action) => {
      state.isLoading = false;
      state.api_completed = true;
    },
    create_general_setting_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddGeneralSetting.reducer;
export const {
  raise_api_call_add_general_setting,
  create_general_setting_complete_api,
  success_api_call,
  failure_api_call,
} = AddGeneralSetting.actions;
