import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { configs } from "../../../common";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

// const SUCCESE = async (data: any) => {
//   toast.success(data, {
//     position: toast.POSITION.TOP_LEFT,
//   });
// };
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const PartnerMasterToSap = createSlice({
  name: "PartnerMasterToSap",
  initialState: {
    data: [],
    isLoading: false,
    isFirst: false,
  },
  reducers: {
    convert_sap_from_api: (state, action?) => {
      state.isLoading = true;
    },
    success_api_convert_sap_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isFirst = true;
    },
    failure_api_convert_sap_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      FAILED(action.payload);
    },
    convert_sap_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default PartnerMasterToSap.reducer;
export const {
  convert_sap_from_api,
  convert_sap_is_first,
  success_api_convert_sap_call,
  failure_api_convert_sap_call,
} = PartnerMasterToSap.actions;

//

const api = configs.api + "/api/partner/generateccxml";
// const api = "http://192.168.1.114:3035/api5/aggrement";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api, a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ConvertSapApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);
    if (response.data.status) {
      yield put(success_api_convert_sap_call(response.data));
    } else {
      yield put(failure_api_convert_sap_call(response.data));
    }
  } catch (e: any) {
    console.log(e);
    if (e.code === "ERR_NETWORK") {
      e.message = "SAP Convert Failed";
    }
    yield put(failure_api_convert_sap_call(e));
  }
}
