import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/business-partner/onboarding.css";
import AutoComplete from "../components/autocomplete-input";
import { list_branch_master } from "../../redux/prefered-master/list-branch-master";
import TextFiealdInput from "../components/textFiald";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";
import { raise_api_call_add_general_setting } from "../../redux/general-setting/add-general-setting-reducer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  raise_api_call_Create_partner,
  Create_partner_is_success,
  create_partner_complete_api,
} from "../../redux/business-partner/partner-master/create-partner-master-reducer";
import {
  raise_api_call_Update_partner,
  Update_partner_is_success,
  Update_partner_complete_api,
} from "../../redux/business-partner/partner-master/update-partner-master-reducer";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import { list_group_master } from "../../redux/sap-master/group-master";
import "react-toastify/dist/ReactToastify.css";
import { raise_api_call_list_partner_one_detail } from "../../redux/business-partner/partner-master/list-one-partner-details";
import { toast } from "react-toastify";
import { configs, COUNTRIES } from "../../common";
import { test_agreement_available_from_api } from "../../redux/business-partner/onboarding/check-agreement-valid-or-not";
import CompletePopup from "../components/response-pop-up";

const EditOnBoarding = () => {
  const [country_code, setCountry_code] = React.useState(91);
  const [ok, SetOk] = React.useState(false);

  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  const onboardingData = useSelector((state: any) => state.AddPartnerData);
  const group_master_data = useSelector((state: any) => state.GroupMasterList);
  const createApi = useSelector((state: any) => state.UpdatePartnerDetails);
  const navigate = useNavigate();
  const [stateKey, setStateKey] = useState<string>("");
  const [payment_term_code, setpayment_term_code] = useState<any>("");
  const branch_master_data = useSelector(
    (state: any) => state.ListBranchMaster
  );

  console.log("createApi", createApi);

  // list single partner
  const activeDatas = useSelector((state: any) => state.listOnePartnerDetail);
  console.log("activeDatas", activeDatas);

  const activeData =
    activeDatas.data.length != 0 ? activeDatas.data.result[0] : {};
  console.log("activeData", activeData);
  const onboardingValues: any =
    Object.keys(onboardingData.data).length != 0 ? onboardingData.data : false;
  const regExp = /\b\d{5}\b/;
  // validations
  const validationSchema = Yup.object().shape({
    company_name: Yup.string()
      .max(100, "maximum 100 character")
      .matches(/^[a-zA-Z0-9\s]+$/, "special characters are not acceptable")
      .required("Required"),
    alter_name: Yup.string()
      .max(100, "maximum 100 character")
      .required(" Required"),
    type_of_business: Yup.string().required(" Required"),
    contact_phone: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(50, "maximum 50 character")
      .required(" Required"),
    about: Yup.string()
      .max(1000, "maximum 1000 character")
      .required("Required"),
    city: Yup.string()
      .max(100, "maximum 100 character")
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    annul_turn_over: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(10),
    contact_person: Yup.string()
      .max(50, "maximum 50 character")
      .required("Required"),
    contact_email_id: Yup.string()
      .email("contact_email_id is invalid")
      .required("Required"),
    branch: Yup.array(
      Yup.object({
        name: Yup.string().required("Required"),
        value: Yup.boolean().required("Required"),
      })
    ).min(1, "Required"),
    payment_terms: Yup.string()
      .required("payment terms is required")
      .nullable(),
    employee_id: Yup.number().notRequired(),
    group_name: Yup.string().required("Required"),
    partner_type: Yup.string().required("Required"),
  });
  console.log("onboardingValues", onboardingValues);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: activeData.company_name,
      alter_name: activeData.foreign_name,
      type_of_business: activeData.bussiness_type,
      contact_phone: activeData.contact_phone,
      about: activeData.about,
      country_code: "+91",
      city: activeData.city,
      state: activeData.state,
      country: activeData.country,
      employee_id: "2",
      currency: activeData.currency,
      annul_turn_over: activeData.annual_turnover,
      contact_person: activeData.contact_person,
      contact_email_id: activeData.contact_email,
      payment_terms: activeData.payments_terms,
      // branch: activeData.branch.branch[0].branch_name,
      branch: activeData.branch.map((b: any) => ({
        name: b.branch_name,
        value: true,
        id: b.id,
      })), // Transforming branch data
      group_name: activeData.group_name,
      partner_type: activeData.partner_type,
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;

  const dispatch = useDispatch();
  const scrollRef = useRef<any>(null);

  const [open, setOpen] = React.useState(false);
  const [filter_partner_type, setFilterPartnerType] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect
  useEffect(() => {
    dispatch(list_branch_master(""));
    dispatch(list_group_master(""));
    dispatch(raise_api_call_list_general_setting(""));
    // if (createApi.isSuccess) {
    //   resetForm();
    //   dispatch(Create_partner_is_success(""));
    // }

    if (createApi.isSuccess) {
      resetForm();
      dispatch(Update_partner_is_success(""));
    }
    // }
    if (createApi.api_completed) {
      dispatch(Update_partner_complete_api(false));
      // dispatch(raise_api_call_list_partner_one_detail("?id=" + activeData.id));
      setResponsePopup(true);
    }
  }, [createApi]);

  useEffect(() => {
    if (ok) {
      navigate("/business-partner/partner-details");
      SetOk(false);
      dispatch(raise_api_call_list_partner_one_detail("?id=" + activeData.id));
    }
  }, [ok]);
  // submit
  const submit = (e: any) => {
    const branches: any = [];
    e.branch.forEach((a: any) => {
      if (a.value) {
        branches.push(a.id);
      }
    });
    const data = {
      id: activeData && activeData.id,
      company_name: e.company_name,
      foreign_name: e.alter_name,
      bussiness_type: e.type_of_business,
      partner_type: e.partner_type,
      employee_id:
        e.partner_type == "V" && e.group_name == "162"
          ? e.employee_id.length != 0
            ? e.employee_id
            : null
          : null,
      group_name: e.group_name,
      branch: branches,
      contact_person: e.contact_person,
      contact_email: e.contact_email_id,
      contact_phone: e.contact_phone,
      country_code: e.country_code,
      annual_turnover: e.annul_turn_over,
      currency: e.currency,
      country: e.country,
      payments_terms: e.payment_terms,
      state: e.state,
      city: e.city,
      about: e.about,
    };
    console.log("createapi", data);

    dispatch(raise_api_call_Update_partner(data));
  };
  console.log(values.branch);
  // option

  const TypeOfBusinessOption =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "type_of_business") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const partner_type_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "partner_type") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const group_name_option =
    group_master_data.data.length != 0
      ? group_master_data.data.status
        ? group_master_data.data.result
            .filter((e: any) => {
              if (values.partner_type === "V" && "S" === e.group_type) {
                return e;
              }
              if (values.partner_type === e.group_type) {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.group_name, value: e.group_code };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const currency_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "Currency") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const country_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "country") {
                return e;
              }
            })
            .sort((a: any, b: any) => {
              return a.description > b.description ? 1 : -1;
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const state_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "state") {
                if (e.key === stateKey) {
                  return e;
                }
              }
            })
            .sort((a: any, b: any) => {
              return a.description > b.description ? 1 : -1;
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const country_codes = COUNTRIES.map((e: any) => {
    const data = { label: e.mobileCode, value: e.mobileCode };
    return data;
  });
  // Payment Terms Options
  const payment_terms =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "Payment Terms") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  console.log("payment_terms", payment_terms);
  return (
    <>
      <div className="list-onboarding  my-4">
        {responsePopup ? (
          <CompletePopup
            data={createApi}
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
              SetOk(true);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            group_master_data.isLoading ||
            generalSettingList.isLoading ||
            branch_master_data.isLoading ||
            createApi.isLoading
              ? true
              : false
            // updateApi.isLoading
            //   ? true
            //   : false
          }
        >
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>

        <div className="  d-flex onboarding-list mx-5">
          <div className="title">
            <h5> Edit Partner Onboarding</h5>
          </div>
        </div>
        <div
          className="forms  mx-5"
          style={{
            paddingBottom: "75px",
          }}
        >
          <div className="row">
            <div className="col-12 p-0">
              <div className="input-field col-12 px-3">
                <label>
                  Branch <span>*</span>
                </label>

                <div className="d-flex align-items-center set-default-address">
                  <div className=" d-flex align-items-center ">
                    <FormControl
                      error={touched.branch && errors.branch ? true : false}
                    >
                      <FormGroup
                        onChange={(e: any) => {
                          const index = values.branch.findIndex(
                            (object: any) => {
                              return object.name === e.target.name;
                            }
                          );

                          if (index >= 0) {
                            let items: any = [...values.branch];
                            const data: any = {
                              name: e.target.name,
                              value: e.target.checked,
                              id: Number(e.target.id),
                            };
                            items[index] = data;

                            setFieldValue("branch", items);
                          } else {
                            const result: any = [...values.branch];
                            result.push({
                              name: e.target.name,
                              value: e.target.checked,
                              id: e.target.id,
                            });
                            setFieldValue("branch", result);
                          }
                          setFieldTouched("branch", true, false);
                        }}
                        row
                      >
                        {branch_master_data.data.length != 0
                          ? branch_master_data.data.result.map(
                              (a: any, index: number) => {
                                return (
                                  <div key={index}>
                                    <FormControlLabel
                                      label={
                                        <img
                                          src={
                                            configs.api + "/" + a.branch_logo
                                          }
                                          style={{
                                            maxHeight: "50px",
                                            maxWidth: "200px",
                                          }}
                                          alt={a.branch_name}
                                        ></img>
                                      }
                                      sx={{
                                        fontSize: "0.8vw",
                                      }}
                                      id={"12"}
                                      onChange={(e: any) => {}}
                                      name={a.branch_name}
                                      className="ms-3"
                                      control={
                                        <Checkbox
                                          id={JSON.stringify(a.id)}
                                          checked={
                                            values.branch.filter((e: any) => {
                                              return (
                                                e.name === a.branch_name &&
                                                e.value
                                              );
                                            }).length != 0
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                    />
                                  </div>
                                );
                              }
                            )
                          : ""}
                      </FormGroup>
                      {touched.branch && errors.branch ? (
                        <FormHelperText>branch is required</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 mt-4">
              <TextFiealdInput
                values={values}
                handleChange={handleChange}
                name="company_name"
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}
                label="Company Name"
              />
            </div>
            <div className="col-6 mt-4">
              <TextFiealdInput
                name="alter_name"
                label="Alternate Name"
                values={values}
                handleChange={handleChange}
                errors={errors}
                handleBlur={handleBlur}
                touched={touched}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={TypeOfBusinessOption}
                errors={errors}
                touched={touched}
                name="type_of_business"
                handleBlur={handleBlur}
                values={values}
                label="Type Of Business"
                vari={(e: any) => {
                  setFieldValue("type_of_business", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={partner_type_option}
                name="partner_type"
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                label="Partner Type"
                vari={(e: any) => {
                  setFieldValue("partner_type", e ? e.value : "");
                  setFieldValue("group_name", "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={group_name_option}
                name="group_name"
                label="Group Name"
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                vari={(e: any) => {
                  setFieldValue("group_name", e ? e.value : "");
                  if (values.partner_type != "C") {
                    dispatch(
                      test_agreement_available_from_api({
                        partner_type: values.partner_type,
                        group_name: e.value,
                      })
                    );
                  }
                }}
              />
            </div>
            {values.partner_type == "V" && values.group_name == "162" ? (
              <div className="col-4 mt-4">
                <TextFiealdInput
                  notRequire={true}
                  name="employee_id"
                  label="Employee ID"
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            ) : (
              ""
            )}

            <div className="col-4 mt-4">
              <TextFiealdInput
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
                name="contact_person"
                label="Contact Person"
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="contact_email_id"
                label="Contact Email id"
                handleChange={(e: any) => {
                  setFieldValue(
                    "contact_email_id",
                    e.target.value.toLowerCase()
                  );
                }}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-4 mt-4">
              <div className="input-field px-3   d-flex flex-column">
                <label>
                  Contact Phone No <span>*</span>
                </label>

                <TextField
                  placeholder=""
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_phone}
                  error={
                    touched.contact_phone && errors.contact_phone ? true : false
                  }
                  helperText={
                    touched.contact_phone && errors.contact_phone
                      ? String(errors.contact_phone)
                      : ""
                  }
                  name="contact_phone"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          value={values.country_code}
                          onChange={(e) => {
                            setFieldValue("country_code", `${e.target.value}`);
                          }}
                          name="country_code"
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            borderRadius: "0px",
                            height: "50px",
                            width: "100%",
                          }}
                        >
                          {COUNTRIES.map((e: any) => {
                            return (
                              <MenuItem value={e.mobileCode}>
                                {e.mobileCode}
                              </MenuItem>
                            );
                          })}
                          country_codes
                        </Select>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "50px",
                      padding: "0px",
                    },
                  }}
                />
              </div>
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="annul_turn_over"
                label="Annual Turn Over"
                alignend={true}
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={currency_option}
                errors={errors}
                touched={touched}
                name="currency"
                handleBlur={handleBlur}
                values={values}
                label="Currency"
                vari={(e: any) => {
                  setFieldValue("currency", e ? e.value : "");
                }}
              />
            </div>
            {/* <div className="col-4 mt-4"></div> */}

            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={country_option}
                errors={errors}
                touched={touched}
                name="country"
                handleBlur={handleBlur}
                values={values}
                label="Country"
                vari={(e: any) => {
                  generalSettingList.data.result.map((a: any) => {
                    if (a.name === (e ? e.value : "")) {
                      setStateKey(a.key);
                    }
                  });
                  setFieldValue("country", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={state_option}
                errors={errors}
                touched={touched}
                name="state"
                handleBlur={handleBlur}
                values={values}
                label="State"
                vari={(e: any) => {
                  setFieldValue("state", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-4 mt-4">
              <TextFiealdInput
                name="city"
                label="City"
                touched={touched}
                errors={errors}
                values={values}
                handleChange={(e: any) => {
                  if (e.target.value) {
                    setFieldValue(
                      "city",
                      e.target.value[0].toUpperCase() +
                        e.target.value.slice(1).toLowerCase()
                    );
                  } else {
                    setFieldValue("city", e.target.value);
                  }
                }}
                handleBlur={handleBlur}
              />
            </div>
            <div className="col-4 mt-4">
              <AutoComplete
                pageOption={payment_terms}
                name="payment_terms"
                touched={touched}
                setpayment_term_code={setpayment_term_code}
                errors={errors}
                values={values}
                handleBlur={handleBlur}
                label="Payment Terms"
                vari={(e: any) => {
                  console.log(e);
                  // props.setpayment_term_code(e ? e.key : "");
                  setpayment_term_code(e ? e.key : "");
                  setFieldValue("payment_terms", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-12 mt-4">
              <div
                className=" px-3 input-field  d-flex flex-column"
                id="abouts"
              >
                <label className="">
                  About <span>*</span>
                </label>
                <TextField
                  className="w-100"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      borderRadius: "0px",
                      height: "100px !important",
                      textAlign: "left",
                    },
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  value={values.about}
                  onChange={handleChange}
                  name="about"
                  error={touched.about && errors.about ? true : false}
                  helperText={
                    touched.about && errors.about ? String(errors.about) : ""
                  }
                  onBlur={handleBlur}
                  multiline
                  id="about"
                  inputProps={{
                    style: {
                      height: "80px !important",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-auto general-setting-button"
          style={{
            position: "relative",
          }}
        >
          <div className="botom-navigation bg-white d-flex justify-content-end align-items-center mb-5">
            <div
              className="next-and-back"
              style={{
                padding: "0",
                margin: "0",
              }}
            >
              <div
                style={{
                  width: "180px",
                  margin: "0px",
                }}
                className="back"
                onClick={() => {
                  // navigate("/business-partner/partner-details");
                  navigate("/business-partner/Management-approve");
                }}
              >
                Cancel
              </div>
            </div>
            <div className="next-and-back">
              {/* <div className="back">Back</div> */}
              <div
                onClick={handleClickOpen}
                className="next"
                style={{
                  width: "250px",
                }}
              >
                {createApi.isLoading ? "Please Wait ..." : "Update"}
                {/* {updateApi.isLoading ? "Please Wait ..." : "Send For Approval"} */}
              </div>
            </div>
          </div>
        </div>

        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle id="alert-dialog-title" className="font-weight-700">
              {"Confirmation"}{" "}
            </DialogTitle>

            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to send the onboarding partner approval?
            </DialogContentText>
            {/* <DialogContentText id="alert-dialog-description"> */}
            <div className="d-flex flex-column">
              <div></div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div onClick={handleClose} className="cancel">
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                      handleClose();
                    }}
                  >
                    Send
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default EditOnBoarding;
