import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const listGeneralSetting = createSlice({
  name: "listGeneralSetting",
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    raise_api_call_list_general_setting: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default listGeneralSetting.reducer;
export const {
  raise_api_call_list_general_setting,
  success_api_call,
  failure_api_call,
} = listGeneralSetting.actions;
