import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  success_api_call_wtax_master,
  failure_api_call_wtax_master,
} from "../sap-master/list-wtax-master";
import {
  failure_api_call_bank_master,
  success_api_call_bank_master,
} from "./bank-master";
import {
  failure_api_call_customer_receivable,
  success_api_call_customer_receivable,
} from "./customer-recivable";
import {
  failure_api_call_list_group_master,
  success_api_call_list_group_master,
} from "./group-master";
import {
  failure_api_call_down_pay_account,
  success_api_call_down_pay_account,
} from "./downpayaccount";
import {
  failure_api_call_vendor_pay,
  success_api_call_vendor_pay,
} from "./vendor-pay";
import {
  failure_api_call_bank_Ifsc_code,
  success_api_call_bank_Ifsc_code,
} from "./get-bank-ifsc-code";
import {
  failure_api_call_sink_bank_master,
  success_api_call_sink_bank_master,
} from "./brank-master-sink-api";
import {
  failure_api_call_sink_wtax_master,
  success_api_call_sink_wtax_master,
} from "./wtax-sink-master";
import {
  failure_api_call_sink_customer_receivable_master,
  success_api_call_sink_customer_receivable_master,
} from "./sink-customer-receivable";
import {
  failure_api_call_sink_down_pay_account_master,
  success_api_call_sink_down_pay_account_master,
} from "./sink-down-pay-account";
import {
  success_api_call_sink_group_account_master,
  failure_api_call_sink_group_account_master,
} from "./sink-group-master";
import {
  failure_api_call_sink_vendor_master,
  success_api_call_sink_vendor_master,
} from "./sink-vendor-pay";
import { configs } from "../../common";

// server ip
const api = configs.api;

// local ip

// const api = "http://192.168.1.102:3035";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/wttax_master/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListWTAXMaster(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(getCall, e);

    yield put(success_api_call_wtax_master(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_wtax_master(e));
  }
}

// bank master
const bankCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/bank_master/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListBankMaster(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(bankCall, e);

    yield put(success_api_call_bank_master(response.data));
  } catch (e) {
    yield put(failure_api_call_bank_master(e));
  }
}
// down pay accunt

const downPayCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/downpay/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListDownPayAccount(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(downPayCall, e);

    yield put(success_api_call_down_pay_account(response.data));
  } catch (e) {
    yield put(failure_api_call_down_pay_account(e));
  }
}
//

const customerReceivableCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/customerrec/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* CustomerReceivableAccountApi(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(customerReceivableCall, e);

    yield put(success_api_call_customer_receivable(response.data));
  } catch (e) {
    yield put(failure_api_call_customer_receivable(e));
  }
}

// group master list

const groupMasterCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/group_master/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* GroupMasterListApiCall(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(groupMasterCall, e);

    yield put(success_api_call_list_group_master(response.data));
  } catch (e) {
    yield put(failure_api_call_list_group_master(e));
  }
}
// venderpay master list

const venderPayCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/vendorpay/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* VendorPayListApiCall(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(venderPayCall, e);

    yield put(success_api_call_vendor_pay(response.data));
  } catch (e) {
    yield put(failure_api_call_vendor_pay(e));
  }
}

// get ifsc code

const IFSCCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api + "/api/bank_master/ifc", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* IFSCListApiCall(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(IFSCCall, e);

    yield put(success_api_call_bank_Ifsc_code(response.data));
  } catch (e) {
    yield put(failure_api_call_bank_Ifsc_code(e));
  }
}

// sik branch master

const SinkCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/bank_master/sync", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* SinkBankMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(SinkCall, e);

    yield put(success_api_call_sink_bank_master(response.data));
  } catch (e) {
    yield put(failure_api_call_sink_bank_master(e));
  }
}

// sink wtax code
const SinkwtaxCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/wttax_master/sync", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* SinkWtaxMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(SinkwtaxCall, e);

    yield put(success_api_call_sink_wtax_master(response.data));
  } catch (e) {
    yield put(failure_api_call_sink_wtax_master(e));
  }
}

// sink customer Receivable code
const SinkCutomerCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/customerrec/sync", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* SinkCustomerMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(SinkCutomerCall, e);

    yield put(success_api_call_sink_customer_receivable_master(response.data));
  } catch (e) {
    yield put(failure_api_call_sink_customer_receivable_master(e));
  }
}
// sink down Pay code
const SinkDownPayCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/downpay/sync", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* SinkDownPayMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(SinkDownPayCall, e);

    yield put(success_api_call_sink_down_pay_account_master(response.data));
  } catch (e) {
    yield put(failure_api_call_sink_down_pay_account_master(e));
  }
}

// sink group master
const SinkgroupCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/group_master/sync", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* SinkGroupMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(SinkgroupCall, e);

    yield put(success_api_call_sink_group_account_master(response.data));
  } catch (e) {
    yield put(failure_api_call_sink_group_account_master(e));
  }
}

// sink CVendor master
const SinkgvendorCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/vendorpay/sync", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* SinkVendorMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(SinkgvendorCall, e);

    yield put(success_api_call_sink_vendor_master(response.data));
  } catch (e) {
    yield put(failure_api_call_sink_vendor_master(e));
  }
}
