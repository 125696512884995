import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import {
  success_api_call_list,
  failure_api_call_list,
} from "./list-notification-reducer";
import { isVariableStatement } from "typescript";
import {
  failure_api_call_read_one,
  success_api_call_read_one,
} from "./single-notification-read";
import { configs } from "../../common";

// const api = "http://192.168.1.110:3035/notification/";
const api = configs.api + "/notification/";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListNotificationApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);

    yield put(success_api_call_list(response.data));

    if (response.data.message === "Token is invalid") {
      window.location.pathname = "/login";
      localStorage.removeItem("SpApperalsToken");
    }
  } catch (e) {
    yield put(failure_api_call_list(e));
  }
}

// read one notification
const ReadOne = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(api + "/markread" + a.payload.url, a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ReadOneNotificationApi(e: any) {
  try {
    const response: AxiosResponse = yield call(ReadOne, e);

    if (response.data.status) {
      yield put(success_api_call_read_one(response.data));
    } else {
      if (response.data.message === "Token is invalid") {
        window.location.pathname = "/login";
        localStorage.removeItem("SpApperalsToken");
      }
      yield put(failure_api_call_read_one(response.data));
    }
  } catch (e) {
    yield put(failure_api_call_read_one(e));
  }
}
