import React from "react";
import SideNav from "./header-and-sidenavbar/sidenav";
import Headers from "./header-and-sidenavbar/header";
import AcountOnBoarding from "./pages/bussiness-partner/acounts-onboarding";
import AddOnBoarding from "./pages/bussiness-partner/add-onboarding";
import OnBoarding from "./pages/bussiness-partner/onboarding";
import { Route, Routes } from "react-router-dom";
import GeneralSetting from "./pages/general-setting/genaral-setting";
import PartnerDetails from "./pages/bussiness-partner/partner-details";
import WTAXMaster from "./pages/sap-master/wtax-master";
import BankMaster from "./pages/sap-master/bank-master";
import CustomerReceivables from "./pages/sap-master/customer-recivible";
import DownPayAccount from "./pages/sap-master/down-pay-acount";
import BranchMaster from "./pages/prefered-master/branch-master";
import AgreementMaster from "./pages/prefered-master/aggrement-master";
import GroupMaster from "./pages/sap-master/group-master";
import VendorPay from "./pages/sap-master/vendorpay";
import BusinessChannel from "./pages/prefered-master/business-channel";
import AddUser from "./pages/add-users.tsx/add-user";
import StateMaster from "./pages/prefered-master/state-master";
import AccountTeam from "./pages/bussiness-partner/super-admin-account-team";
import Management from "./pages/bussiness-partner/super-admin-management";
import AccountHead from "./pages/bussiness-partner/super-admin-account-heaad-approve";
import EditOnBoarding from "./pages/bussiness-partner/edit-onboarding";

const RoutePage = () => {
  return (
    <>
      <div className="border-box h-100 w-100 d-flex flex-column full-page">
        <div className="hedears">
          <Headers />
        </div>
        <div className="h-100 d-flex ">
          <div className="h-100 sidenavbars shadow">
            <SideNav />
          </div>
          <div className="w-100 component">
            <Routes>
              <Route
                path="/business-partner/onboarding/add"
                element={<AddOnBoarding />}
              />
              <Route
                path="/business-partner/onboarding"
                element={<OnBoarding />}
              />
              <Route
                path="/business-partner/onboarding/edit"
                element={<EditOnBoarding />}
              />
              <Route
                path="/business-partner/account-head-approve"
                element={<AccountHead />}
              />
              <Route
                path="/business-partner/account-team-approve"
                element={<AccountTeam />}
              />
              <Route
                path="/business-partner/Management-approve"
                element={<Management />}
              />
              <Route
                path="/business-partner/acounts-onboarding"
                element={<AcountOnBoarding />}
              />
              <Route
                path="/business-partner/partner-details"
                element={<PartnerDetails />}
              />
              <Route path="/general-setting" element={<GeneralSetting />} />
              <Route path="/sap-master/wtax-master" element={<WTAXMaster />} />
              <Route path="/sap-master/bank-master" element={<BankMaster />} />
              <Route path="/sap-master/vendor-pay" element={<VendorPay />} />
              <Route
                path="/sap-master/group-master"
                element={<GroupMaster />}
              />

              <Route
                path="/sap-master/Downpayaccount"
                element={<DownPayAccount />}
              />
              <Route
                path="/sap-master/customer-receivables"
                element={<CustomerReceivables />}
              />
              <Route
                path="/prefer-master/branch-master"
                element={<BranchMaster />}
              />
              <Route
                path="/prefer-master/agreement-master"
                element={<AgreementMaster />}
              />
              <Route
                path="/prefer-master/business-channel"
                element={<BusinessChannel />}
              />
              <Route
                path="/prefer-master/state-master"
                element={<StateMaster />}
              />
              <Route path="/add-user" element={<AddUser />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};
export default RoutePage;
