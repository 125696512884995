import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const listPartnerMaster = createSlice({
  name: "listPartnerMaster",
  initialState: {
    data: [],
    isLoading: false,
    isFirst: true,
  },
  reducers: {
    raise_api_call_list_partner_master: (state, action?: any) => {
      state.data = action.payload;
      state.isLoading = true;
    },
    list_success_partner_master: (state, action) => {
      console.log(action.payload);
      state.data = action.payload;
      state.isFirst = true;
      state.isLoading = false;
    },
    list_failure_partner_master: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    partner_master_isFirst: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default listPartnerMaster.reducer;
export const {
  raise_api_call_list_partner_master,
  list_success_partner_master,
  list_failure_partner_master,
  partner_master_isFirst,
} = listPartnerMaster.actions;
