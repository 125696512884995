import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ListUser = createSlice({
  name: "ListUser",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_user: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_user: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_user: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    user_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default ListUser.reducer;
export const {
  list_user,
  success_api_call_user,
  failure_api_call_user,
  user_is_first,
} = ListUser.actions;
