import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ReadOneNotification = createSlice({
  name: "ReadOneNotification",
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    read_one_notification: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call_read_one: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_read_one: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default ReadOneNotification.reducer;
export const {
  read_one_notification,
  success_api_call_read_one,
  failure_api_call_read_one,
} = ReadOneNotification.actions;
