import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const getOnboardingAggrementOtp = createSlice({
  name: "getOnboardingAggrementOtp",
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {
    get_otp_from_api: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default getOnboardingAggrementOtp.reducer;
export const { get_otp_from_api, success_api_call, failure_api_call } =
  getOnboardingAggrementOtp.actions;
