import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  list_success_partner_master,
  list_failure_partner_master,
} from "./list-partner-master";
import {
  success_api_call_Create_partner,
  failure_api_call_Create_partner,
} from "./create-partner-master-reducer";
import {
  list_failure_management_approve_partner,
  list_success_management_approve_partner,
} from "./approve-partner";
import {
  list_success_partner_one_master,
  list_failure_partner_one_master,
} from "./list-one-partner-master";
import {
  failure_request_clarification,
  success_request_clarification,
} from "./request-clarification";
import { list_success_api_basic_info } from "../onboarding/basic-info-reducer";
import { list_success_api_contact } from "../onboarding/contact-details-reducer";
import { configs } from "../../../common";
import {
  list_failure_partner_one_detail,
  list_success_partner_one_detail,
} from "./list-one-partner-details";

import {
  success_api_call_Update_partner,
  failure_api_call_Update_partner,
} from "./update-partner-master-reducer";

// local ip

// const partner_partner_master = "http://192.168.1.112:3035";
// const partner_master_update_api = "http://192.168.1.112:3035";
// // // server ip
const partner_partner_master = configs.api;
const partner_master_update_api = configs.api;

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(partner_partner_master + "/api/partner/list_partner", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getOnePartnerCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(
    partner_partner_master + "/api/partner/list_single_partner" + a,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
const getCallOne = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(partner_partner_master + "/api6/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const createApiCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(partner_master_update_api + "/api/partner/create", a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const updateApiCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(partner_master_update_api + "/api/partner/update", a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const approveManagement = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(partner_master_update_api + "/api/approval/" + a.url, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const requestClarificationapi = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(
    partner_master_update_api + "/api/accounts_onboarding/clarification",
    a,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export function* listPartnerMasterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e.payload);
    if (response.data.status) {
      yield put(list_success_partner_master(response.data));
    } else {
      yield put(list_failure_partner_master(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* listOnePartnerDetailsApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getOnePartnerCall, e.payload);
    // console.log(response);
    if (response.data.status) {
      yield put(list_success_partner_one_detail(response.data));
    } else {
      yield put(list_failure_partner_one_detail(response.data));
    }
  } catch (err) {
    yield put(list_failure_partner_one_detail(err));
  }
}

// one
export function* listOnePartnerMasterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCallOne, e.payload);
    // console.log(response);
    if (response.data.status) {
      yield put(list_success_partner_one_master(response.data));
    } else {
      yield put(list_failure_partner_one_master(response.data));
    }
  } catch (err) {
    yield put(list_failure_partner_one_master(err));
  }
}

export function* createPartnerMasterApi(e: any) {
  try {
    console.log(e.payload);
    const response: AxiosResponse = yield call(createApiCall, e.payload);
    if (response.data.status) {
      yield put(success_api_call_Create_partner(response.data));
    } else {
      yield put(failure_api_call_Create_partner(response.data));
    }
  } catch (err) {
    console.log(err);
    yield put(failure_api_call_Create_partner(err));
  }
}

export function* updatePartnerMasterApi(e: any) {
  try {
    console.log(e.payload);
    const response: AxiosResponse = yield call(updateApiCall, e.payload);
    if (response.data.status) {
      yield put(success_api_call_Update_partner(response.data));
    } else {
      yield put(failure_api_call_Update_partner(response.data));
    }
  } catch (err) {
    console.log(err);
    yield put(failure_api_call_Update_partner(err));
  }
}

// approve partners
export function* ApproveManagementPartnerMasterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(approveManagement, e.payload);
    if (response.data.status) {
      yield put(list_success_management_approve_partner(response.data));
    } else {
      yield put(list_failure_management_approve_partner(response.data));
    }
  } catch (err) {
    console.log(err);
    yield put(list_failure_management_approve_partner(err));
  }
}

// request clarification
export function* RequestClarificationApi(e: any) {
  try {
    const response: AxiosResponse = yield call(
      requestClarificationapi,
      e.payload
    );
    if (response.data.status) {
      yield put(success_request_clarification(response.data));
    } else {
      yield put(failure_request_clarification(response.data));
    }
  } catch (err) {
    console.log(err);
    yield put(failure_request_clarification(err));
  }
}
