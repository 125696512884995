import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { configs } from "../../../common";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

// const SUCCESE = async (data: any) => {
//   toast.success(data, {
//     position: toast.POSITION.TOP_LEFT,
//   });
// };
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const CheckAgreementAvailable = createSlice({
  name: "CheckAgreementAvailable",
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {
    test_agreement_available_from_api: (state, action?) => {
      state.isLoading = true;
    },
    success_api_test_agreement_available_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      // SUCCESE(action.payload);
    },
    failure_api_test_agreement_available_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      // FAILED(action.payload); ///failed added
    },
  },
});
export default CheckAgreementAvailable.reducer;
export const {
  test_agreement_available_from_api,
  success_api_test_agreement_available_call,
  failure_api_test_agreement_available_call,
} = CheckAgreementAvailable.actions;

//

const api = configs.api + "/api/partner/aggrement_validate";
// const api = "http://192.168.1.114:3035/api5/aggrement";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api, a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* getAgreementAvailable(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);
    if (response.data.status) {
      yield put(success_api_test_agreement_available_call(response.data));
    } else {
      yield put(failure_api_test_agreement_available_call(response.data));
    }
  } catch (e) {
    console.log(e);
    yield put(failure_api_test_agreement_available_call(e));
  }
}
