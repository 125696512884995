import React, { useEffect, useState } from "react";
import { navigation } from "../navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { raise_api_call_menu } from "../redux/login-and-create-password/menu-reducer";
import { configs } from "../common";
import { clear_single_partner_details } from "../redux/business-partner/partner-master/list-one-partner-details";
const SideNav = () => {
  const dispatch = useDispatch();
  const menus = useSelector((state: any) => state.Menus);
  useEffect(() => {
    dispatch(raise_api_call_menu(""));
  }, []);
  return (
    <>
      <div className="border-box  sidenav-overflow  m-0  ">
        <div className="pt-4">
          {menus.data.length != 0
            ? menus.data.result.map((e: object, key: number) => {
                return <Submenus e={e} key={key} />;
              })
            : ""}
        </div>
      </div>
    </>
  );
};

const Submenus = (props: any) => {
  const dispatch = useDispatch();
  const { e, key } = props;
  const navigate = useNavigate();
  const locationPath = window.location.pathname.split("/");
  const [subnav, setSubnav] = useState<Boolean>(
    locationPath[1] === e.path.split("/")[1] ? true : false
  );

  // console.log(locationPath[1]);
  return (
    <>
      <div className="my-2">
        <div
          className="d-flex  px-4 py-3  pointer"
          id={
            locationPath[1] === e.path.split("/")[1]
              ? "activesidenav"
              : "notActivesidenav"
          }
          onClick={() => {
            setSubnav(!subnav);
            if (e.children.length === 0) {
              navigate(e.path);
            }
          }}
        >
          <div className="d-flex ">
            <div className="d-flex mx-2 align-items-center fs-5">
              <img
                src={configs.api + e.icon}
                style={{
                  color: "white",
                  width: "30px",
                  maxHeight: "20px",
                  filter: "brightness(0) invert(1)",
                }}
              />
            </div>

            <div
              key={key}
              typeof="button"
              className="text-white px-2  fw-bolder text-start"
            >
              {e.menu}
            </div>
          </div>

          <div className=" align-items-center float-end me-0 d-flex ms-auto justify-content-end">
            {e.children.length != 0 ? (
              subnav ? (
                <FontAwesomeIcon
                  className="text-white float-end fs-5"
                  icon={["fas", "angle-up"]}
                />
              ) : (
                <FontAwesomeIcon
                  className="text-white float-end fs-5"
                  icon={["fas", "angle-down"]}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
        {subnav && e.children
          ? e.children.map((a: any, key: number) => {
              return (
                <div className="d-flex">
                  <div
                    className="mx-3 float-end px-4 pointer d-flex "
                    key={key}
                    onClick={() => {
                      dispatch(clear_single_partner_details(""));
                      navigate(a.path);
                    }}
                  >
                    <div
                      id={
                        locationPath[1] + "/" + locationPath[2] ===
                        a.path.split("/")[1] + "/" + a.path.split("/")[2]
                          ? "activesubnav"
                          : "notactivesubnav"
                      }
                      className="text-white p-4 fw-bolder  ms-auto text-start"
                    >
                      {a.menu}
                    </div>
                  </div>
                  {a.icon ? (
                    <div
                      id={
                        locationPath[1] + "/" + locationPath[2] ===
                        a.path.split("/")[1] + "/" + a.path.split("/")[2]
                          ? "iconactive"
                          : "notactivesubnav"
                      }
                      className="ms-auto d-flex mx-3 px-2 align-items-center"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        a.menu === "Onboarding" ? (
                          navigate("/business-partner/onboarding/add")
                        ) : (
                          <div></div>
                        );
                      }}
                    >
                      <img
                        src={"http://185.209.228.65:3035" + e.icon}
                        style={{
                          color: "white",
                          width: "30px",
                          filter: "brightness(0) invert(1)",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
};
export default SideNav;
