import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import { failure_api_call, success_api_call } from "./login-reducer";
import { isVariableStatement } from "typescript";
import {
  success_api_call_check_user,
  failure_api_call_check_user,
} from "./email-exist-reducer";
import {
  failure_api_call_menu,
  raise_api_call_menu,
  success_api_call_menu,
} from "./menu-reducer";
import { configs } from "../../common";
import Alert from "@mui/material/Alert";

const api = configs.api + "/api1/login";
// const api = "http://192.168.1.112:3035/api1/login";

const getCall = (a: any) => {
  return Axios.post(api, a.payload);
};
export function* LoginApi(e: any) {
  // console.log(response);
  try {
    const response: AxiosResponse = yield call(getCall, e);
    console.log(response);
    if (response.data.status) {
      yield put(success_api_call(response.data));
    } else {
      yield put(failure_api_call(response.data));
    }
  } catch (e: any) {
    console.log("e", e);
    if (e.code === "ERR_NETWORK") {
      e.message = "Backend is Disconnected";

      const responce = {
        status: false,
        status_code: 503,
        message:
          "The server is under maintenance. Please wait for some time and try again later.",
      };

      yield put(failure_api_call(e));
    }
  }
}

// check user
const apis = configs.api + "/api1/verify_mail";
// const api = "http://192.168.1.110:3035/api1/login";

const getCheckCall = (a: any) => {
  return Axios.post(apis, a.payload);
};
export function* CheckUserApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCheckCall, e);
    console.log(response);
    if (response.data.status) {
      yield put(success_api_call_check_user(response.data));
    } else {
      yield put(failure_api_call_check_user(response.data));
    }
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_check_user(e));
  }
}

// check user
const menu = configs.api + "/menu/List";
// const menu = "http://192.168.1.114:3035/menu/List";

const getMenuCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(menu, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* MenuApi(e: any) {
  // console.log(response);
  try {
    const response: AxiosResponse = yield call(getMenuCall, e);
    if (response.data.message === "Token is invalid") {
      window.location.pathname = "/login";
      localStorage.removeItem("SpApperalsToken");
    }
    if (response.data.name == "TokenExpiredError") {
      window.location.pathname = "/login";
      localStorage.removeItem("SpApperalsToken");
    }
    if (response.data.status) {
      yield put(success_api_call_menu(response.data));
    } else {
      yield put(failure_api_call_menu(response.data));
    }
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_menu(e));
  }
}
