import React, { useEffect, useState, createRef } from "react";
import "../../assets/css/business-partner/partner-detail.css";
import AutoComplete from "../components/autocomplete-input";
import SearchIcon from "@mui/icons-material/Search";
import TextFiealdInput from "../components/textFiald";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import AutoCompleteMultiple from "../components/multi-drop-down";

import { useSelect } from "@mui/base";
import TabContext from "@mui/lab/TabContext";
import MainTable from "../components/main-tables";
import TabList from "@mui/lab/TabList";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector, useDispatch } from "react-redux";
import {
  partner_master_isFirst,
  raise_api_call_list_partner_master,
} from "../../redux/business-partner/partner-master/list-partner-master";
import { addPartnerData } from "../../redux/business-partner/partner-master/partner-master-first-data";
import { useNavigate } from "react-router";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  success_api_call_Create_partner,
  Create_partner_is_success,
} from "../../redux/business-partner/partner-master/create-partner-master-reducer";
import {
  approve_partner_complete_api,
  approve_partner_is_succese,
  management_approve_partner,
} from "../../redux/business-partner/partner-master/approve-partner";
import { configs } from "../../common";
import {
  convert_sap_from_api,
  convert_sap_is_first,
} from "../../redux/business-partner/partner-master/move-xml-data";
import { raise_api_call_list_partner_one_detail } from "../../redux/business-partner/partner-master/list-one-partner-details";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import CompletePopup from "../components/response-pop-up";
//file supported formats
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const AccountTeam = () => {
  const [country_code, setCountry_code] = React.useState(91);
  const dispatch = useDispatch();
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  const PartnerMasterList = useSelector(
    (state: any) => state.listPartnerMaster
  );
  const managementApproveApi = useSelector(
    (state: any) => state.ApprovePartner
  );
  const movedSap = useSelector((state: any) => state.PartnerMasterToSap);
  const navigate = useNavigate();
  const activeDatas = useSelector((state: any) => state.listOnePartnerDetail);
  const activeData =
    activeDatas.data.length != 0
      ? activeDatas.data.result
        ? activeDatas.data.result[0]
        : {}
      : {};

  console.log(activeData);
  const payment_terms: any[] =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "Payment Terms") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  const validationSchema = Yup.object().shape({
    about: Yup.string().required("reason Is Required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      about: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  // if (firstData) {
  //   if (PartnerMasterList.data.length != 0) {
  //     setFirstData(false);
  //     if (PartnerMasterList.isFirst) {
  //       setActivePartner(PartnerMasterList.data.result[0]);
  //       dispatch(partner_master_isFirst(""));
  //     }
  //   }
  // }

  // submit
  const submit = (e: any) => {
    const data = {
      id: activeData.id,
      status: "rejected",
      description: e.about,
      url:
        localStorage.getItem("SpApperalsRole") === "management"
          ? "update_management"
          : "update_account_head",
    };
    if (!managementApproveApi.isLoading) {
      dispatch(management_approve_partner(data));
    }
  };

  console.log(activeData);

  // steppers

  const steps = [
    "Employee",
    "Management",
    "Partner",
    "Accounts",
    "Accounts Head",
    "SAP",
  ];

  //
  const [value, setValue] = React.useState("1");
  const [search, setSearch] = useState<string>("");
  //   Dialog
  const [open, setOpen] = React.useState(false);
  const [managementReject, setManagementReject] = React.useState(false);
  const [managementEdit, setManagementEdit] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditManagement = () => {
    setManagementEdit(true);
    navigate("/business-partner/onboarding/edit");
  };
  // reject and approve
  const RejectManagement = () => {
    setManagementReject(true);
  };
  const ManagementRejectClose = () => {
    setManagementReject(false);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const steperData = activeData.approvall
    ? activeData.approvall.length != 0
      ? activeData.approvall[activeData.approvall.length - 1].role_name ===
        "employee"
        ? 0
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
          "management"
        ? 1
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
          "partner"
        ? 2
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
          "account_team"
        ? 3
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
            "account_head" &&
          activeData.approvall[activeData.approvall.length - 1].status !=
            "approved"
        ? 4
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
            "account_head" &&
          activeData.approvall[activeData.approvall.length - 1].status ===
            "approved"
        ? 6
        : 6
      : 0
    : 0;
  const isRejected = activeData.approvall
    ? activeData.approvall.length != 0
      ? activeData.approvall[activeData.approvall.length - 1].status ===
        "rejected"
        ? true
        : false
      : false
    : false;
  // console.log(isRejected);
  // useEfect
  const account_head_approve_data = {
    id: activeData.id,
    status: "approved",
    description: "accounts team head approved",
    url: "update_account_head",
  };
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    if (managementApproveApi.isSuccess) {
      dispatch(approve_partner_is_succese(""));
      dispatch(raise_api_call_list_partner_one_detail("?id=" + activeData.id));
      // console.log(managementApproveApi);
      setOpen(false);
      ManagementRejectClose();
    }
    if (managementApproveApi.api_completed) {
      setResponsePopup(true);
      dispatch(approve_partner_complete_api(false));
    }
    dispatch(raise_api_call_list_partner_master(""));
  }, [managementApproveApi]);
  // partner details array

  const Partner_details_list: any[] = [
    {
      name: "Amazon",
      city: "Coimbatore",
      status: "Request Sent for Approval",
    },
    {
      name: "Amazon",
      city: "Coimbatore",
      status: "Request Sent for Approval",
    },
    {
      name: "Amazon",
      city: "Coimbatore",
      status: "Partner Approved",
    },
    {
      name: "Amazon",
      city: "Coimbatore",
      status: "Partner Approved",
    },
  ];

  const History = [
    {
      status: "Send for Approval",
      name: "Vignesh",
      date: "sep 27,2022 12:00pm",
    },
    {
      status: "Approved",
      name: "Vijay",
      date: "sep 27,2022 12:00pm",
    },
  ];

  // stepper line color change

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {},
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "#82CA89",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "#82CA89",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));
  console.log(activeData);

  return (
    <>
      <div className="partner-details ">
        {responsePopup ? (
          <CompletePopup
            data={managementApproveApi}
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            PartnerMasterList.isLoading || activeDatas.isLoading ? true : false
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className="row mb-5">
          <div className="col-5 pe-0 partner-details-list">
            <div className=" ms-5 me-2 my-4  d-flex ">
              <div className="title">
                <h5>Partner Details</h5>
              </div>
              <div className="filter ms-auto me-2">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="w-100"
                  name="search"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "45px",
                    },
                  }}
                />
              </div>
            </div>
            <div className="general-setting-list">
              <div className="list">
                {PartnerMasterList.data.length === 0
                  ? ""
                  : PartnerMasterList.data.result
                      .filter((item: any) => {
                        return Object.values(item)
                          .join("")
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      })
                      .map((e: any, key: number) => {
                        return (
                          <>
                            <div
                              key={key + 1}
                              id={e.id === activeData.id ? "active-lists" : ""}
                              onClick={(a) => {
                                setActivePartner(key);
                                dispatch(
                                  raise_api_call_list_partner_one_detail(
                                    "?id=" + e.id
                                  )
                                );
                              }}
                              className={"d-flex lists flex-column mt-2  p-3"}
                              style={{
                                height: "100px",
                                background: "#FFFFFF",
                              }}
                            >
                              <div className="mx-2 partner-name d-flex">
                                {e.company_name != null
                                  ? e.company_name
                                  : e.businessPartner[0].company_name}
                                <p className="ms-2 ms-auto mb-0">
                                  {e.vendor_customer_code
                                    ? e.vendor_customer_code
                                    : ""}
                                </p>
                              </div>
                              <div className="d-flex mt-auto partner-locations align-items-center">
                                <p className="ms-2 me-auto mb-0">
                                  {e.city != null
                                    ? e.city
                                    : e.contactAddress.length != 0
                                    ? e.contactAddress[0].city
                                    : "-"}
                                </p>
                                <p
                                  className={
                                    e.status === "Request Sent for Approval"
                                      ? "ms-auto me-2 mb-0 text-yellow"
                                      : "ms-auto text-yellow me-2 mb-0"
                                  }
                                >
                                  {e.status}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
              </div>
            </div>
          </div>
          <div className="col-7 vh-100 overflow-scroll pt-5 tracking px-0">
            {Object.keys(activeData).length === 0 ? (
              <>
                <div className="text-center text-bold">
                  Please Select Any One Partner
                </div>
              </>
            ) : (
              <>
                <div className="steper ">
                  <Box sx={{ width: "100%" }}>
                    <Stepper
                      activeStep={steperData}
                      alternativeLabel
                      connector={<ColorlibConnector />}
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel
                            sx={{
                              color: "#82CA89",
                            }}
                            error={
                              isRejected
                                ? steperData === index
                                  ? true
                                  : false
                                : false
                            }
                            icon={
                              isRejected ? (
                                steperData === index ? (
                                  <CancelIcon color="error" />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            }
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                <div className="partner-review-button mt-3">
                  <div
                    className="d-flex managementRole align-items-center"
                    style={{ gap: "140px", justifyContent: "space-between" }}
                  >
                    <p className="mb-0">
                      {activeData.approvall
                        ? activeData.approvall.length !== 0
                          ? activeData.approvall[
                              activeData.approvall.length - 1
                            ].description
                          : ""
                        : ""}
                    </p>
                    {activeData.approvall.filter((a: any, index: number) => {
                      if (index === activeData.approvall.length - 1) {
                        if (
                          (a.role_name === "account_team" &&
                            a.status === "Partner Onboarding Completed") ||
                          a.status === "Clarification Submitted" ||
                          a.status === "rejected"
                        ) {
                          return a;
                        }
                      }
                    }).length !== 0 ? (
                      <div
                        style={{
                          gap: "1px !important",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="me-5"
                      >
                        <div
                          className="ms-auto me-3 employee-role"
                          onClick={() => {
                            dispatch(addPartnerData(activeData));
                            navigate("/business-partner/acounts-onboarding");
                          }}
                        >
                          Review & Submit
                        </div>
                        {!(
                          activeData.approvall[activeData.approvall.length - 1]
                            .status === "rejected" ||
                          activeData.approvall[activeData.approvall.length - 1]
                            .status === "Clarification Submitted"
                        ) && (
                          <FontAwesomeIcon
                            style={{
                              height: 30,
                              marginRight: "20px",
                              cursor: "pointer",
                            }}
                            icon="edit" // You might need to specify the icon, for example, "edit"
                            title="Edit partner onboarding"
                            onClick={() => {
                              EditManagement();
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {/* {activeData.approvall.filter((a: any, index: number) => {
                      if (index === activeData.approvall.length - 1) {
                        if (
                          (a.role_name === "account_team" &&
                            a.status === "Partner Onboarding Completed") ||
                          a.status === "Clarification Submitted" ||
                          a.status === "rejected"
                        ) {
                          return a;
                        }
                      }
                    }).length !== 0 ? (
                      <div
                        style={{
                          gap: "1px !important",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="me-5"
                      >
                        <div
                          className="ms-auto me-3 employee-role"
                          onClick={() => {
                            dispatch(addPartnerData(activeData));
                            navigate("/business-partner/acounts-onboarding");
                          }}
                        >
                          Review & Submit
                        </div>
                        <FontAwesomeIcon
                          style={{
                            height: 30,
                            marginRight: 6,
                            cursor: "pointer",
                          }}
                          icon="edit" // You might need to specify the icon, for example, "edit"
                          title="Edit partner onboarding"
                          onClick={() => {
                            EditManagement();
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>

                <div className="details-information px-3">
                  <div className="row p-3">
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Company Name</label>
                      <p>
                        {activeData.company_name
                          ? activeData.company_name
                          : activeData.businessPartner[0].company_name}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Alternate Name</label>
                      <p>
                        {" "}
                        {activeData.foreign_name
                          ? activeData.foreign_name
                          : activeData.businessPartner[0].foreign_name}
                      </p>
                    </div>
                    <div className="col-4 mt-4"></div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Business Type</label>
                      <p>
                        {activeData.bussiness_type_1
                          ? activeData.bussiness_type_1
                          : ""}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Partner Type</label>
                      <p>
                        {activeData.partner_name
                          ? activeData.partner_name
                          : activeData.partner_type}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Vendor/Customer Code</label>
                      <p>
                        {activeData.vendor_customer_code
                          ? activeData.vendor_customer_code
                          : "-"}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Group Name</label>
                      <p>
                        {" "}
                        {activeData.groups_name
                          ? activeData.groups_name
                          : activeData.group_name}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Branch</label>
                      <div className="d-flex">
                        {activeData.branch.map((e: any, key: number) => {
                          return (
                            <div key={key} className="px-2">
                              <img
                                src={configs.api + "/" + e.branch_logo}
                                alt="branch"
                                style={{
                                  maxHeight: "50px",
                                  maxWidth: "200px",
                                }}
                              ></img>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-4 mt-4"></div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Contact Person</label>
                      <p>{activeData.contact_person}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Contact Email Id</label>
                      <Tooltip title={activeData.contact_email}>
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {activeData.contact_email}
                        </p>
                      </Tooltip>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Contact Phone No</label>
                      <p>{activeData.contact_phone}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Annual Turn Over</label>
                      <p> {activeData.annual_turnover}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Currency</label>
                      <p>
                        {activeData.currency_1
                          ? activeData.currency_1
                          : activeData.currency}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Payment Terms</label>
                      <p>
                        {activeData.payments_terms
                          ? payment_terms.find(
                              (terms) =>
                                terms.value === activeData.payments_terms
                            ).label
                          : ""}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Country</label>
                      <p>
                        {activeData.country_1
                          ? activeData.country_1
                          : activeData.country}
                      </p>
                    </div>{" "}
                    <div className="col-4 partner-detail-data mt-4">
                      <label>State</label>
                      <p>
                        {activeData.state_1
                          ? activeData.state_1
                          : activeData.state}
                      </p>
                    </div>{" "}
                    <div className="col-4 partner-detail-data mt-4">
                      <label>City</label>
                      <p>{activeData.city ? activeData.city : "-"}</p>
                    </div>{" "}
                    <div className="col-12 partner-detail-data mt-4">
                      <label>About</label>
                      <p
                        className="text-justify"
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        {activeData.about ? activeData.about : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                {/* tabs */}
                {activeData.accounts.length != 0 ? (
                  activeData.accounts[0].down_payment_clearing_accounts ? (
                    <div className="tabs mt-5 mb-5">
                      <div className="mx-5">
                        <Box sx={{ width: "100%" }}>
                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <TabList
                                onChange={handleTabChange}
                                className="w-100"
                                aria-label="lab API tabs example"
                              >
                                <Tab
                                  sx={{
                                    textTransform: "capitalize",
                                    fontWeight: 700,
                                    width: "14%",
                                  }}
                                  label="UDF"
                                  value="1"
                                />
                                <Tab
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "14%",
                                    fontWeight: 700,
                                  }}
                                  label="General"
                                  value="2"
                                />
                                <Tab
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "14%",
                                    fontWeight: 700,
                                  }}
                                  label="Contact Person"
                                  value="3"
                                />
                                <Tab
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "14%",
                                    fontWeight: 700,
                                  }}
                                  label="Address"
                                  value="4"
                                />
                                <Tab
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "14%",
                                    fontWeight: 700,
                                  }}
                                  label="Payment Terms"
                                  value="5"
                                />
                                <Tab
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "14%",
                                    fontWeight: 700,
                                  }}
                                  label="Accounts"
                                  value="6"
                                />
                                <Tab
                                  sx={{
                                    textTransform: "capitalize",
                                    width: "14%",
                                    fontWeight: 700,
                                  }}
                                  label="Attachments"
                                  value="7"
                                />
                              </TabList>
                            </Box>
                            <TabPanel
                              className="p-0 pt-4 "
                              style={{
                                height: "400px",
                              }}
                              value="1"
                            >
                              <UDF activeData={activeData} />
                            </TabPanel>
                            <TabPanel
                              className="p-0 pt-4"
                              style={{
                                height: "400px",
                              }}
                              value="2"
                            >
                              <General activeData={activeData} />
                            </TabPanel>
                            <TabPanel
                              className="p-0 pt-4"
                              style={{
                                height: "400px",
                              }}
                              value="3"
                            >
                              <ContactInfo activeData={activeData} />
                            </TabPanel>
                            <TabPanel
                              className="p-0 pt-4"
                              style={{
                                height: "400px",
                              }}
                              value="4"
                            >
                              <AddressDetail
                                activeData={activeData}
                                generalSettingList={generalSettingList}
                              />
                            </TabPanel>
                            <TabPanel
                              className="p-0 pt-4"
                              style={{
                                height: "400px",
                              }}
                              value="5"
                            >
                              <PaymentTerms activeData={activeData} />
                            </TabPanel>
                            <TabPanel
                              className="p-0 pt-4"
                              style={{
                                height: "400px",
                              }}
                              value="6"
                            >
                              <Accounts activeData={activeData} />
                            </TabPanel>
                            <TabPanel
                              className="p-0 pt-4"
                              style={{
                                height: "400px",
                              }}
                              value="7"
                            >
                              <Attachments activeData={activeData} />
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {/* history */}
                <div className="history p-4 ">
                  <div className="title-history ">History</div>
                  {activeData.approvall
                    ? activeData.approvall.map((e: any, index: number) => {
                        const monthNames = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        // create on
                        const data = { ...e };
                        const created_on = new Date(data.created_on);
                        // delete data.EffecDate;
                        const created_date =
                          created_on.getDate() +
                          "-" +
                          monthNames[created_on.getMonth()] +
                          "-" +
                          created_on.getFullYear();
                        data.created_on
                          ? (data.created_on = created_date)
                          : console.log("sdfdsf");
                        var seconds = created_on.getSeconds();
                        var minutes = created_on.getMinutes();
                        var hour = created_on.getHours();

                        return (
                          <>
                            <div key={index + 1} className="row">
                              <div className="col-4 partner-detail-data mt-4">
                                <label>Status</label>
                                <p>{e.status}</p>
                              </div>
                              <div className="col-4 partner-detail-data mt-4">
                                <label>Name</label>
                                <p>{e.creater_name}</p>
                              </div>
                              <div className="col-4 partner-detail-data mt-4">
                                <label>Date/Time</label>
                                <p>
                                  {data.created_on},
                                  {hour + ":" + minutes + ":" + seconds}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </div>
              </>
            )}

            {/* pop up diologs */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountTeam;

// UDF
const UDF = (props: any) => {
  if (props.activeData.udff.length != 0) {
    return (
      <>
        <div className="mt-3  udf ">
          <div className="udf-form">
            <div className="row">
              <div className="col-4 partner-detail-data mt-4">
                <label>Tolerance Percentage</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].tolerance_percentage}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Discount Group</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].discount_group}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Store Code</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].store_code}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Store Name</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].store_name}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>GST Type</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? "Un Register"
                    : props.activeData.udff[0].gst_type
                    ? props.activeData.udff[0].udf1
                      ? props.activeData.udff[0].udf1.gst_type1
                        ? props.activeData.udff[0].udf1.gst_type1
                        : "Un Register"
                      : "Un Register"
                    : "Un Register"}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Filling Status</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].filling_status
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>MSME</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].msme
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>MSME Type</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].msme_type
                    ? props.activeData.udff[0].udf1
                      ? props.activeData.udff[0].udf1.msme_type1
                      : ""
                    : props.activeData.udff[0].msme_type}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>MSME No</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].msme_no}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Business Channel</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].business_chanel
                    ? props.activeData.udff[0].udf1
                      ? props.activeData.udff[0].udf1.bussiness_channel
                      : ""
                    : props.activeData.udff[0].business_chanel}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Dimension State</label>
                <p>
                  {props.activeData.udff.length === 0
                    ? ""
                    : props.activeData.udff[0].state
                    ? props.activeData.udff[0].udf1
                      ? props.activeData.udff[0].udf1.state1
                      : ""
                    : props.activeData.udff[0].state}
                </p>
              </div>
              {props.activeData
                ? props.activeData.costcentercode.map((e: any, key: number) => {
                    return (
                      <div key={key} className="col-4 partner-detail-data mt-4">
                        <label>{e.branch} Location</label>
                        <p>{e.value}</p>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <>please complete udf tab</>;
  }
};
// general
const General = (props: any) => {
  if (props.activeData.generals.length != 0) {
    return (
      <>
        <div className="mt-3  udf ">
          <div className="udf-form">
            <div className="row">
              <div className="col-4 partner-detail-data mt-4">
                <label>Telephone No</label>
                <p>{props.activeData.generals[0].telephone_no}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label> Mobile Phone No</label>
                <p>{props.activeData.generals[0].mobile_phone_no}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Email Id</label>
                <p>{props.activeData.generals[0].email_Id}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Type of Business</label>
                <p>
                  {props.activeData.generals[0].type_of_business
                    ? props.activeData.generals[0].general1
                      ? props.activeData.generals[0].general1.type_of_bussiness
                      : ""
                    : props.activeData.generals[0].type_of_business}
                </p>
              </div>
              {/* <div className="col-4 partner-detail-data mt-4">
                <label>Branch</label>
                <p>{props.activeData.generals[0].branch}</p>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <>please complete general tab</>;
  }
};
// contact info
const ContactInfo = (props: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [editData, setEditData] = useState<{
    contact_person: string;
    title: string;
    phone: string;
    email: string;
    country_code: number;
    index: number;
  }>({
    contact_person: "",
    title: "",
    phone: "",
    email: "",
    country_code: 91,
    index: 0,
  });
  const validationSchema = Yup.object().shape({
    contact_person: Yup.string().required("Contact Person Is Required"),
    title: Yup.string().required("Title Is Required"),
    phone_number: Yup.number().required("Phone Number  Is Required"),
    email_id: Yup.string()
      .required("Email  Is Required")
      .email("Email is invalid"),
  });
  console.log(editData);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact_person: isEdit ? editData.contact_person : "",
      title: isEdit ? editData.title : "",
      phone_number: isEdit ? editData.phone : "",
      email_id: isEdit ? editData.email : "",
      country_code: isEdit ? editData.country_code : 91,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;

  const [age, setAge] = React.useState(91);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChanges = (event: any) => {
    setAge(event.target.value);
  };
  const handle = (e: string) => {
    console.log(e);
  };
  if (props.activeData.contactperson.length != 0) {
    // mainTable
    const mainTableData: any = [];
    // mainTable data
    props.activeData.contactperson.forEach((e: any, key: number) => {
      const data = {
        default: {
          value: e.default ? "Yes" : "",
        },
        contact_person: {
          value: e.contact_person,
        },
        title: {
          value: e.title,
        },
        phone_number: {
          value: e.phone,
        },
        email_id: {
          value: e.email,
        },
        action: {
          value: (
            <div
              className="d-flex table-edit-icon"
              style={{
                width: "23px",
                textAlign: "center",
              }}
            >
              <div
                className=" edit"
                onClick={() => {
                  setIsEdit(true);
                  let data = {
                    ...e,
                  };
                  data.index = key;
                  setEditData(data);
                  handleClickOpen();
                }}
              >
                <FontAwesomeIcon title="view" icon={["fas", "eye"]} />
              </div>
            </div>
          ),
        },
      };
      mainTableData.push(data);
    });

    return (
      <>
        <MainTable add={handle} mainTableData={mainTableData} />
        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle id="alert-dialog-title" className="font-weight-700">
              {"Add Contact"}{" "}
            </DialogTitle>
            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description"> */}
            <div className="d-flex flex-column">
              <div className="row">
                <div className="input-field px-3 col-6 d-flex flex-column">
                  <label>Contact Person</label>
                  <TextField
                    placeholder="Contact Person"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact_person}
                    error={
                      touched.contact_person && errors.contact_person
                        ? true
                        : false
                    }
                    helperText={
                      touched.contact_person && errors.contact_person
                        ? errors.contact_person
                        : ""
                    }
                    name="contact_person"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 col-6 d-flex flex-column">
                  <label>Title</label>
                  <TextField
                    placeholder="Title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={touched.title && errors.title ? true : false}
                    helperText={
                      touched.title && errors.title ? errors.title : ""
                    }
                    name="title"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                  <label>Phone No</label>
                  <TextField
                    placeholder=""
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    error={
                      touched.phone_number && errors.phone_number ? true : false
                    }
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                    helperText={
                      touched.phone_number && errors.phone_number
                        ? errors.phone_number
                        : ""
                    }
                    name="phone_number"
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <Select
                    //         value={age}
                    //         onChange={handleChanges}
                    //         // displayEmpty
                    //         inputProps={{ "aria-label": "Without label" }}
                    //         sx={{
                    //           borderRadius: "0px",
                    //           height: "50px",
                    //           width: "80%",
                    //         }}
                    //       >
                    //         <MenuItem value={91}>+91</MenuItem>
                    //         <MenuItem value={10}>+81</MenuItem>
                    //         <MenuItem value={20}>+1</MenuItem>
                    //         <MenuItem value={30}>+8</MenuItem>
                    //       </Select>
                    //     </InputAdornment>
                    //   ),
                    //   style: {
                    //     borderRadius: "0px",
                    //     height: "50px",
                    //     padding: "0px",
                    //   },
                    // }}
                  />
                </div>
                <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                  <label>Email Id</label>
                  <TextField
                    type="email"
                    placeholder="Company Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email_id}
                    error={touched.email_id && errors.email_id ? true : false}
                    helperText={
                      touched.email_id && errors.email_id ? errors.email_id : ""
                    }
                    name="email_id"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <div className="add-more px-3 col-3 d-flex flex-column">
                    <div onClick={handleClose} className="cancel">
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </DialogContentText> */}
          </DialogContent>
          {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
        </Dialog>
      </>
    );
  } else {
    return <>please complete contact Person</>;
  }
};

const AddressDetail = (props: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<{
    default: boolean;
    address_type: string;
    address_title: string;
    address_name2: string;
    address_name3: string;
    street_pobox: string;
    block: string;
    city: string;
    zip_code: string;
    country: string;
    state: string;
    gst_type: string;
    gstin: string;
    gst_certificate: any;
    index: number;
  }>({
    default: true,
    address_type: "pay_to",
    address_title: "",
    address_name2: "",
    address_name3: "",
    street_pobox: "",
    block: "",
    city: "",
    zip_code: "",
    country: "",
    state: "",
    gst_type: "",
    gstin: "",
    gst_certificate: {
      name: "",
    },
    index: 0,
  });
  const validationSchema = Yup.object().shape({
    address_name3: Yup.string().required("Address Name3 Is Required"),
    address_name2: Yup.string().required("Address Name2  Is Required"),
    street_pobox: Yup.string().required("Street Is Required"),
    block: Yup.string().required("Block  Is Required"),
    zip_code: Yup.string().required("Zip Code  Is Required"),
    country: Yup.string().required("Country  Is Required"),
    state: Yup.string().required("State  Is Required"),
    city: Yup.string().required("city  Is Required"),
    gst_type: Yup.string().required("Gst Type  Is Required"),
    gstin: Yup.string().required("GSTIN  Is Required"),
    gst_certificate: Yup.mixed()
      .test("fileFormat", "Allowed Format - jpg,png,pdf,jpeg.", (value) => {
        if (value) {
          SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      })
      .notRequired()
      .nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      default: isEdit ? editData.default : true,
      address_type: isEdit ? editData.address_type : "pay_to",
      address_title: isEdit ? editData.address_title : "",
      address_name2: isEdit ? editData.address_name2 : "",
      address_name3: isEdit ? editData.address_name3 : "",
      street_pobox: isEdit ? editData.street_pobox : "",
      block: isEdit ? editData.block : "",
      city: isEdit ? editData.city : "",
      zip_code: isEdit ? editData.zip_code : "",
      country: isEdit ? editData.country : "",
      state: isEdit ? editData.state : "",
      gst_type: isEdit ? editData.gst_type : "",
      gstin: isEdit ? editData.gstin : "",
      gst_certificate: isEdit
        ? editData.gst_certificate
        : {
            name: "",
          },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  const [age, setAge] = React.useState(91);
  const [open, setOpen] = React.useState(false);
  const GSTFileRep = createRef<any>();
  const [stateKey, setStateKey] = useState<string>("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (props.activeData.contactAddress.length != 0) {
    // mainTable data
    const mainTableData: any = [];
    props.activeData.contactAddress.forEach((e: any, key: number) => {
      const data = {
        address_type: {
          value: e.address_type == "B" ? "Pay To " : "Ship From ",
        },

        city: {
          value: e.city,
        },
        zip_code: {
          value: e.zip_code,
        },
        GST_type: {
          value: e.gst_type,
        },
        GSTIN: {
          value: e.gstin,
        },

        default: {
          value: e.default ? "Yes" : "",
        },
        action: {
          value: (
            <div
              className="d-flex table-edit-icon"
              style={{
                width: "23px",
                textAlign: "center",
              }}
            >
              <div
                className=" edit"
                onClick={() => {
                  setIsEdit(true);
                  let data = {
                    ...e,
                  };
                  data.index = key;
                  setEditData(data);
                  handleClickOpen();
                }}
              >
                <FontAwesomeIcon title="view" icon={["fas", "eye"]} />
              </div>
            </div>
          ),
        },
      };
      mainTableData.push(data);
    });
    const country_option =
      props.generalSettingList.data.length != 0
        ? props.generalSettingList.data.status
          ? props.generalSettingList.data.result
              .filter((e: any) => {
                if (e.page === "country") {
                  return e;
                }
              })
              .sort((a: any, b: any) => {
                return a.description > b.description ? 1 : -1;
              })
              .map((e: any) => {
                const data = { label: e.description, value: e.name };
                return data;
              })
          : [{ label: "No data", value: null }]
        : [{ label: "No data", value: null }];

    const state_option =
      props.generalSettingList.data.length != 0
        ? props.generalSettingList.data.status
          ? props.generalSettingList.data.result
              .filter((e: any) => {
                if (e.page === "state") {
                  // console.log(stateKey);
                  // console.log(e);
                  if (e.key === stateKey) {
                    return e;
                  }
                }
              })
              .sort((a: any, b: any) => {
                return a.description > b.description ? 1 : -1;
              })
              .map((e: any) => {
                const data = { label: e.description, value: e.name };
                return data;
              })
          : [{ label: "No data", value: null }]
        : [{ label: "No data", value: null }];
    //  gst type options
    const GstTypeOption = props.generalSettingList.data.status
      ? props.generalSettingList.data.result
          .filter((e: any) => {
            if (e.page === "gst_type") {
              return e;
            }
          })
          .map((e: any) => {
            const data = { label: e.description, value: e.name };
            return data;
          })
      : [{ label: "No data", value: "no data" }];

    return (
      <>
        <MainTable mainTableData={mainTableData} />
        <Dialog
          PaperProps={{
            style: { borderRadius: 0 },
          }}
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex">
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontWeight: "700",
              }}
            >
              {"View Address"}
            </DialogTitle>
            <div className="ms-auto p-3">
              <CancelIcon
                onClick={() => {
                  handleClose();
                }}
                sx={{
                  color: "#D1D1D1",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description"> */}
            <div className="d-flex flex-column">
              <div className="row address-types">
                <div className="d-flex">
                  <div className="d-flex align-items-center set-default-address">
                    <Checkbox
                      name="default"
                      checked={values.default ? true : false}
                      readOnly
                      onChange={(e: any) => {
                        // setFieldValue("default", e.target.checked);
                      }}
                    />
                    <label>Set to Default</label>
                  </div>
                  {/* {!isEdit ? (
                  <div className="d-flex align-items-center set-default-address">
                    <Checkbox
                      name="default"
                      checked={ship_address_same}
                      onChange={(e: any) => {
                        if (props.values.address_details.length != 0) {
                          setCopyData(props.values.address_details[0]);
                          setShip_address_same(!ship_address_same);
                        }
                      }}
                    />
                    <label>copy address</label>
                  </div>
                ) : (
                  ""
                )} */}
                </div>
                <div className="col-12 align-items-center mt-1 ps-2 address-types d-flex">
                  <div className="col-4 d-flex align-items-center mt-4">
                    <label>Address Type </label>
                    <FormGroup
                      row
                      aria-readonly
                      onChange={(e: any) => {
                        // console.log(e.target.name);
                        // if (!touched.address_title && e.target.name == "B") {
                        //   setFieldValue("address_title", "Pay To Address1");
                        // }
                        // if (!touched.address_title && e.target.name == "S") {
                        //   setFieldValue("address_title", "Ship From Address1");
                        // }
                        // setFieldValue("address_type", e.target.name);
                      }}
                    >
                      <FormControlLabel
                        label="Pay To"
                        sx={{
                          fontSize: "0.9vw",
                        }}
                        name="B"
                        className="ms-3"
                        control={
                          <Checkbox
                            checked={values.address_type === "B" ? true : false}
                          />
                        }
                      />

                      <FormControlLabel
                        label="Ship Form"
                        sx={{
                          fontSize: "0.8vw",
                        }}
                        name="S"
                        className="ms-3"
                        control={
                          <Checkbox
                            checked={values.address_type === "S" ? true : false}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                  {/* <div className="input-field px-3 col-4 d-flex flex-column">
                  <label>
                    Address Id <span>*</span>
                  </label>
                  <TextField
                    placeholder="Address Id"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": {
                        top: 0,
                        border: "2px solid #c4c4c4",
                        borderRadius: "0px",
                      },
                      // borderRadius: "0px",
                      fontWeight: "700",
                      fontFamily: "Roboto",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_id}
                    error={
                      touched.address_id && errors.address_id ? true : false
                    }
                    helperText={
                      touched.address_id && errors.address_id
                        ? errors.address_id
                        : ""
                    }
                    name="address_id"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                    }}
                  />
                </div> */}
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    Address Title <span>*</span>
                  </label>
                  <TextField
                    placeholder="Address Title"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": {
                        top: 0,
                        border: "none",
                        borderRadius: "0px",
                      },
                      // borderRadius: "0px",
                      fontWeight: "700",
                      fontFamily: "Roboto",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_title}
                    error={
                      touched.address_title && errors.address_title
                        ? true
                        : false
                    }
                    helperText={
                      touched.address_title && errors.address_title
                        ? errors.address_title
                        : ""
                    }
                    name="address_title"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    Address Name2 <span>*</span>
                  </label>
                  <TextField
                    placeholder="Address Name2"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_name2}
                    error={
                      touched.address_name2 && errors.address_name2
                        ? true
                        : false
                    }
                    helperText={
                      touched.address_name2 && errors.address_name2
                        ? errors.address_name2
                        : ""
                    }
                    name="address_name2"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    Address Name3 <span>*</span>
                  </label>
                  <TextField
                    placeholder="Address Name3"
                    sx={{
                      "& legend": { display: "none", border: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_name3}
                    error={
                      touched.address_name3 && errors.address_name3
                        ? true
                        : false
                    }
                    helperText={
                      touched.address_name3 && errors.address_name3
                        ? errors.address_name3
                        : ""
                    }
                    name="address_name3"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    Street / PO Box <span>*</span>
                  </label>
                  <TextField
                    placeholder="Street"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.street_pobox}
                    error={
                      touched.street_pobox && errors.street_pobox ? true : false
                    }
                    helperText={
                      touched.street_pobox && errors.street_pobox
                        ? errors.street_pobox
                        : ""
                    }
                    name="street_pobox"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    Block <span>*</span>
                  </label>
                  <TextField
                    placeholder="Block"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.block}
                    error={touched.block && errors.block ? true : false}
                    helperText={
                      touched.block && errors.block ? errors.block : ""
                    }
                    name="block"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    City <span>*</span>
                  </label>

                  <TextField
                    placeholder="City"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": {
                        top: 0,
                        border: "none",
                        borderRadius: "0px",
                      },
                      // borderRadius: "0px",
                      fontWeight: "700",
                      fontFamily: "Roboto",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && errors.city ? true : false}
                    helperText={touched.city && errors.city ? errors.city : ""}
                    name="city"
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    Zip Code <span>*</span>
                  </label>
                  <TextField
                    placeholder="Zip Code"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                      readOnly: true,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip_code}
                    error={touched.zip_code && errors.zip_code ? true : false}
                    helperText={
                      touched.zip_code && errors.zip_code ? errors.zip_code : ""
                    }
                    name="zip_code"
                  />
                </div>

                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    Country <span>*</span>
                  </label>
                  <Autocomplete
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": {
                        top: 0,
                        border: "none",
                        borderRadius: "0px",
                      },
                      // borderRadius: "0px",
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      height: "50px",
                      borderRadius: "0px",
                    }}
                    value={
                      country_option.filter((e: any) => {
                        return e.value === values.country;
                      }).length != 0
                        ? country_option.filter((e: any) => {
                            return e.value === values.country;
                          })[0].label
                        : values.country
                    }
                    options={country_option}
                    onChange={(e, value: any) => {
                      const a = value ? value.value : "";
                      // vari(a);
                      setStateKey(a);
                      setFieldValue("country", a);
                    }}
                    readOnly
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onBlur={handleBlur}
                        error={touched.country && errors.country ? true : false}
                        helperText={
                          touched.country && errors.country
                            ? errors.country
                            : ""
                        }
                        name="country"
                        placeholder="Select Type"
                        {...params}
                      />
                    )}
                  />
                </div>

                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    State <span>*</span>
                  </label>
                  <Autocomplete
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": {
                        top: 0,
                        border: "none",
                        borderRadius: "0px",
                      },
                      // borderRadius: "0px",
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      height: "50px",
                      borderRadius: "0px",
                    }}
                    value={
                      state_option.filter((e: any) => {
                        return e.value === values.state;
                      }).length != 0
                        ? state_option.filter((e: any) => {
                            return e.value === values.state;
                          })[0].label
                        : values.state
                    }
                    readOnly
                    freeSolo
                    options={state_option}
                    onChange={(e, value: any) => {
                      const a = value ? value.value : "";
                      // vari(a);

                      setFieldValue("state", a);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onBlur={handleBlur}
                        error={touched.state && errors.state ? true : false}
                        helperText={
                          touched.state && errors.state ? errors.state : ""
                        }
                        name="state"
                        placeholder="Select Type"
                        {...params}
                      />
                    )}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    GST Type <span>*</span>
                  </label>
                  <Autocomplete
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      height: "50px",
                      borderRadius: "0px",
                    }}
                    value={
                      GstTypeOption.filter((e: any) => {
                        return e.value === values.gst_type;
                      }).length != 0
                        ? GstTypeOption.filter((e: any) => {
                            return e.value === values.gst_type;
                          })[0].label
                        : values.gst_type
                    }
                    options={GstTypeOption}
                    onChange={(e, value: any) => {
                      const a = value ? value.value : "";
                      // vari(a);

                      setFieldValue("gst_type", a);
                    }}
                    readOnly
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.gst_type && errors.gst_type ? true : false
                        }
                        helperText={
                          touched.gst_type && errors.gst_type
                            ? errors.gst_type
                            : ""
                        }
                        name="gst_type"
                        placeholder="Select Type"
                        {...params}
                      />
                    )}
                  />
                </div>
                <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>
                    GSTIN <span>*</span>
                  </label>
                  <TextField
                    placeholder="GSTIN"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0, border: "none" },
                      borderRadius: "0px",
                    }}
                    inputProps={{
                      style: {
                        height: "20px",
                        textTransform: "uppercase",
                      },
                      readOnly: true,
                    }}
                    onChange={(e: any) => {
                      setFieldValue("gstin", e.target.value.toUpperCase());
                    }}
                    onBlur={handleBlur}
                    value={values.gstin}
                    error={touched.gstin && errors.gstin ? true : false}
                    helperText={
                      touched.gstin && errors.gstin ? errors.gstin : ""
                    }
                    name="gstin"
                  />
                </div>
                {/* <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                  <label>GST Certificate</label>
                  <a href={api + values.msme_certificate} target="_blank">
                    Document
                  </a>
                </div> */}
                <div className="col-12 mt-4 d-flex justify-content-center">
                  <div className="add-more px-3 col-3 d-flex flex-column">
                    <div onClick={handleClose} className="cancel">
                      Ok
                    </div>
                  </div>
                  {/* <div className="add-more px-3 col-3 d-flex flex-column">
                    <div
                      className="add"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* </DialogContentText> */}
          </DialogContent>
          {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
        </Dialog>
      </>
    );
  } else {
    return <>please complete address</>;
  }
};
// payment terms

const PaymentTerms = (props: any) => {
  if (props.activeData.paymentTerms.length != 0) {
    return (
      <>
        <div className="mt-3  udf ">
          <div className="udf-form">
            <div className="row">
              <div className="col-4 partner-detail-data mt-4">
                <label>Payment Term</label>
                <p>
                  {props.activeData.paymentTerms[0].payments_terms
                    ? !props.activeData.paymentTerms[0].payment_terms1
                      ? ""
                      : props.activeData.paymentTerms[0].payment_terms1
                          .payment_term1
                    : props.activeData.paymentTerms[0].payments_terms}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Price List</label>
                <p>
                  {props.activeData.paymentTerms[0].price_list
                    ? !props.activeData.paymentTerms[0].payment_terms1
                      ? ""
                      : props.activeData.paymentTerms[0].payment_terms1
                          .price_list1
                    : props.activeData.paymentTerms[0].price_list}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Credit Limit</label>
                <p>{props.activeData.paymentTerms[0].credit_limit}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Commitment Limit</label>
                <p>{props.activeData.paymentTerms[0].commitment_limit}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Bank Country/Region</label>
                <p>
                  {props.activeData.paymentTerms[0].bank_country_region
                    ? !props.activeData.paymentTerms[0].payment_terms1
                      ? ""
                      : props.activeData.paymentTerms[0].payment_terms1
                          .bank_country_region
                    : props.activeData.paymentTerms[0].bank_country_region}
                </p>
              </div>

              <div className="col-4"></div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Bank Name</label>
                <p>{props.activeData.paymentTerms[0].bank_name}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>BIC/IFSC/SWIFT Code</label>
                <p>{props.activeData.paymentTerms[0].bic_swift_code}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Account No</label>
                <p>{props.activeData.paymentTerms[0].account_no}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Bank Account Name</label>
                <p>{props.activeData.paymentTerms[0].bank_account_name}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Branch</label>
                <p>{props.activeData.paymentTerms[0].branch}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <>please complete payment tab</>;
  }
};

// accounts

const Accounts = (props: any) => {
  if (props.activeData.accounts.length != 0) {
    return (
      <>
        <div className="mt-3  udf ">
          <div className="udf-form">
            <div className="row">
              <div className="col-4 partner-detail-data mt-4">
                <label>Consolidation Business Partner</label>
                <p>
                  {props.activeData.accounts[0].consolidation_business_partner
                    ? !props.activeData.accounts[0].accounts1
                      ? ""
                      : props.activeData.accounts[0].accounts1
                          .consolidate_bussiness_partner
                    : props.activeData.accounts[0]
                        .consolidation_business_partner}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Accounts Payable</label>
                <p>
                  {props.activeData.accounts[0].accounts_payable
                    ? !props.activeData.accounts[0].accounts1
                      ? ""
                      : props.activeData.accounts[0].accounts1.accounts_payable1
                    : props.activeData.accounts[0].accounts_payable}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Down Payment Clearing Account</label>
                <p>
                  {props.activeData.accounts[0].down_payment_clearing_accounts
                    ? !props.activeData.accounts[0].accounts1
                      ? ""
                      : props.activeData.accounts[0].accounts1
                          .downpayment_clearing_accounts
                    : props.activeData.accounts[0]
                        .down_payment_clearing_accounts}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Down Payment Interim Account</label>
                <p>
                  {props.activeData.accounts[0].down_payment_interim_accounts
                    ? !props.activeData.accounts[0].accounts1
                      ? ""
                      : props.activeData.accounts[0].accounts1
                          .downpayment_interium_accounts
                    : props.activeData.accounts[0]
                        .down_payment_interim_accounts}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>PAN No</label>
                <p>{props.activeData.accounts[0].pan_number}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Subject To Withholding Tax</label>
                <p>
                  {props.activeData.accounts[0].subject_holding ? "yes" : "no"}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Assessee Type</label>
                <p>
                  {props.activeData.accounts[0].Assesse_type
                    ? !props.activeData.accounts[0].accounts1
                      ? ""
                      : props.activeData.accounts[0].accounts1.assessee_type
                    : props.activeData.accounts[0].Assesse_type}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>WTAX Code</label>
                {typeof props.activeData.accounts[0].wtax_code === "string"
                  ? props.activeData.accounts[0].wtax_code
                  : props.activeData.accounts[0].wtax_code.map(
                      (e: any, index: number) => {
                        return <p key={index}>{e.WTName},</p>;
                      }
                    )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <>please complete accounts tab</>;
  }
};

// Attachments

// const Attachments = (props: any) => {
//   const gstCertificateRep = createRef<any>();
//   const pageOption = [
//     { label: "Type 1", value: "Type 1" },
//     { label: "Type 2", value: "Type 2" },
//   ];
//   const api = configs.api + "/";

//   return (
//     <>
//       <div className="mt-3  udf ">
//         <div className="udf-form">
//           <div className="row">
//             <div className="col-4 input-field d-flex flex-column">
//               <label>GST Certificate</label>
//               <div className={"gst-certificate-view-page"}>
//                 <a
//                   className="px-3"
//                   href={
//                     props.activeData.contactAddress.length === 0
//                       ? ""
//                       : api + props.activeData.contactAddress[0].gst_certificate
//                   }
//                   target="_blank"
//                 >
//                   GST Certificate.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   {/* <label className="text-dark">1.4mb</label> */}
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span
//               // className={
//               //   errors.msme_certificate && touched.msme_certificate
//               //     ? "text-danger"
//               //     : ""
//               // }
//               >
//                 Supported file(.pdf,.jpg,.png)
//               </span>
//             </div>
//             <div className="col-4 input-field d-flex flex-column">
//               <label>Cancelled Cheque Leaf</label>
//               <div className={"gst-certificate-view-page"}>
//                 <a
//                   className="px-3"
//                   href={
//                     props.activeData.bankDetail.length != 0
//                       ? api +
//                         props.activeData.bankDetail[0].cancelled_cheque_url
//                       : ""
//                   }
//                   target="_blank"
//                 >
//                   Cancelled Cheque.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span
//               // className={
//               //   errors.msme_certificate && touched.msme_certificate
//               //     ? "text-danger"
//               //     : ""
//               // }
//               >
//                 Supported file(.pdf,.jpg,.png)
//               </span>
//             </div>
//             <div className="col-4 input-field d-flex flex-column">
//               <label>MSME Certificate</label>
//               <div className={"gst-certificate-view-page"}>
//                 <a
//                   className="px-3"
//                   href={
//                     props.activeData.businessPartner.length === 0
//                       ? ""
//                       : api +
//                         props.activeData.businessPartner[0]
//                           .msme_certificate_file
//                   }
//                   target="_blank"
//                 >
//                   MSME Certificate.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span>Supported file(.pdf,.jpg,.png)</span>
//             </div>
//             <div className="col-4 input-field d-flex flex-column">
//               <label>PANCARD Certificate</label>
//               <div className={"gst-certificate-view-page"}>
//                 <a
//                   className="px-3"
//                   href={
//                     props.activeData.businessPartner.length === 0
//                       ? ""
//                       : api +
//                         props.activeData.businessPartner[0]
//                           .pancard_certificate_file
//                   }
//                   target="_blank"
//                 >
//                   PANCARD Certificate.pdf
//                 </a>
//                 <div className="text-dark text-end ms-auto me-2">
//                   <label className="text-dark">1.4mb</label>
//                   <DownloadIcon
//                     className="mx-2 "
//                     sx={{
//                       color: "#46A4E3",
//                     }}
//                   />
//                 </div>
//               </div>
//               <span>Supported file(.pdf,.jpg,.png)</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

const Attachments = (props: any) => {
  const gstCertificateRep = createRef<any>();
  const pageOption = [
    { label: "Type 1", value: "Type 1" },
    { label: "Type 2", value: "Type 2" },
  ];
  const api = configs.api + "/";

  const handleDownload = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const extractFileName = (url: string | undefined): string => {
    if (!url) return "default-filename";
    return url.split("/").pop() || "default-filename";
  };
  return (
    <>
      <div className="mt-3 udf">
        <div className="udf-form">
          <div className="row">
            {props.activeData.contactAddress.length > 0 &&
              props.activeData.contactAddress[0].gst_certificate && (
                <div className="col-4 input-field d-flex flex-column">
                  <label>GST Certificate</label>
                  <div className="gst-certificate-view-page">
                    <a
                      className="px-3"
                      href={
                        props.activeData.contactAddress.length === 0
                          ? ""
                          : api +
                            props.activeData.contactAddress[0].gst_certificate
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GST Certificate.pdf
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <DownloadIcon
                        className="mx-2"
                        sx={{ color: "#46A4E3" }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.activeData.contactAddress[0]
                                .gst_certificate,

                            extractFileName(
                              props.activeData.contactAddress[0]
                                ?.gst_certificate
                            )
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}

            {props.activeData.bankDetail.length > 0 &&
              props.activeData.bankDetail[0].cancelled_cheque_url && (
                <div className="col-4 input-field d-flex flex-column">
                  <label>Cancelled Cheque Leaf</label>
                  <div className="gst-certificate-view-page">
                    <a
                      className="px-3"
                      href={
                        props.activeData.bankDetail.length != 0
                          ? api +
                            props.activeData.bankDetail[0].cancelled_cheque_url
                          : ""
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cancelled Cheque.pdf
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <DownloadIcon
                        className="mx-2"
                        sx={{
                          color: "#46A4E3",
                        }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.activeData.bankDetail[0]
                                .cancelled_cheque_url,
                            extractFileName(
                              props.activeData.bankDetail[0]?.cancelled_cheque
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}

            {props.activeData.businessPartner.length > 0 &&
              props.activeData.businessPartner[0].msme_certificate_file && (
                <div className="col-4 input-field d-flex flex-column">
                  <label>MSME Certificate</label>
                  <div className="gst-certificate-view-page">
                    <a
                      className="px-3"
                      href={
                        props.activeData.businessPartner.length === 0
                          ? ""
                          : api +
                            props.activeData.businessPartner[0]
                              .msme_certificate_file
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MSME Certificate.pdf
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <DownloadIcon
                        className="mx-2"
                        sx={{
                          color: "#46A4E3",
                        }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.activeData.businessPartner[0]
                                .msme_certificate_file,
                            extractFileName(
                              props.activeData.businessPartner[0]
                                .msme_certificate_file
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}

            {props.activeData.businessPartner.length > 0 &&
              props.activeData.businessPartner[0].pancard_certificate_file && (
                <div className="col-4 input-field d-flex flex-column">
                  <label>PANCARD Certificate</label>
                  <div className="gst-certificate-view-page">
                    <a
                      className="px-3"
                      href={
                        props.activeData.businessPartner.length === 0
                          ? ""
                          : api +
                            props.activeData.businessPartner[0]
                              .pancard_certificate_file
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PANCARD Certificate.pdf
                    </a>
                    <div className="text-dark text-end ms-auto me-2">
                      <DownloadIcon
                        className="mx-2"
                        sx={{
                          color: "#46A4E3",
                        }}
                        onClick={() =>
                          handleDownload(
                            api +
                              props.activeData.businessPartner[0]
                                .pancard_certificate_file,
                            extractFileName(
                              props.activeData.businessPartner[0]
                                .pancard_certificate_file
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                  <span>Supported file(.pdf,.jpg,.png)</span>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
