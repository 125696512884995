import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const onboardingEditContact: any = createSlice({
  name: "onboardingEditContact",
  initialState: {
    data: [],
    isCompleted: false,
    isLoading: false,
  },
  reducers: {
    raise_api_call_edit_contact: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_edit_call_contact: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_edit_call_contact: (state, action) => {
      state.isLoading = false;
      FAILED(action.payload);
    },
  },
});
export default onboardingEditContact.reducer;
export const {
  raise_api_call_edit_contact,
  success_api_edit_call_contact,
  failure_api_edit_call_contact,
} = onboardingEditContact.actions;
