import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const SinkDownPayAccountMaster = createSlice({
  name: "SinkDownPayAccountMaster",
  initialState: {
    isLoading: false,
    isSuccess: false,
    data: [],
  },
  reducers: {
    sink_down_pay_account_master: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_sink_down_pay_account_master: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_sink_down_pay_account_master: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    sink_down_pay_account_isSuccess: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default SinkDownPayAccountMaster.reducer;
export const {
  sink_down_pay_account_master,
  success_api_call_sink_down_pay_account_master,
  sink_down_pay_account_isSuccess,
  failure_api_call_sink_down_pay_account_master,
} = SinkDownPayAccountMaster.actions;
