import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const ListNotification = createSlice({
  name: "ListNotification",
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    list_notification: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call_list: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_list: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default ListNotification.reducer;
export const {
  list_notification,
  success_api_call_list,
  failure_api_call_list,
} = ListNotification.actions;
