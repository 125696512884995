import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  success_api_call_agreement_master,
  failure_api_call_agreement_master,
} from "./list-agreement-master";
import {
  success_api_call_list_branch_master,
  failure_api_call_list_branch_master,
} from "./list-branch-master";
import {
  success_api_call_add_branch_master,
  failure_api_call_add_branch_master,
} from "./add-branch-master";
import {
  success_api_call_add_agreement_master,
  failure_api_call_add_agreement_master,
} from "./add-agreement";
import {
  failure_api_call_business_channel,
  success_api_call_business_channel,
} from "./business-channel-list";
import {
  failure_api_call_add_business_channel,
  success_api_call_add_business_channel,
} from "./add-business-channel";
import {
  failure_api_call_edit_business_channel,
  success_api_call_edit_business_channel,
} from "./edit-business-channel";
import {
  failure_api_call_edit_branch_master,
  success_api_call_edit_branch_master,
} from "./edit-branch-master";
import {
  failure_api_call_edit_agreement_master,
  success_api_call_edit_agreement_master,
} from "./edit-agreement-master";
import {
  failure_api_call_add_state_master,
  success_api_call_add_state_master,
} from "./add-state-master";
import {
  failure_api_call_list_state_master,
  success_api_call_list_state_master,
} from "./list-state-master";
import {
  failure_api_call_edit_state_master,
  success_api_call_edit_state_master,
} from "./edit-state-master";
import { configs } from "../../common";
import {
  failure_api_call_list_one_agreement_master,
  success_api_call_list_one_agreement_master,
} from "./list-one-agreement";

// server ip
const api = configs.api;

// local ip

// const api = "http://192.168.1.114:3035";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/branch_master/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListBranchMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);
    if (response.data.status) {
      yield put(success_api_call_list_branch_master(response.data));
    } else {
      yield put(failure_api_call_list_branch_master(response.data));
    }
  } catch (e: any) {
    console.log(e);
    if (e.code === "ERR_NETWORK") {
      e.message = "Branh Master is failed";
    }
    yield put(failure_api_call_list_branch_master(e));
  }
}

// add branch master

const addCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api + "/api/branch_master/create", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddBranchMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(addCall, e);
    if (response.data.status) {
      yield put(success_api_call_add_branch_master(response.data));
    } else {
      yield put(failure_api_call_add_branch_master(response.data));
    }
  } catch (e: any) {
    console.log(e);
    if (e.code === "ERR_NETWORK") {
      e.message = "AddBranch master is not failed";
    }
    yield put(failure_api_call_add_branch_master(e));
  }
}

// edit branch master

const editCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(api + "/api/branch_master/update", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* EditBranchMasterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editCall, e);
    if (response.data.status) {
      yield put(success_api_call_edit_branch_master(response.data));
    } else {
      yield put(failure_api_call_edit_branch_master(response.data));
    }
  } catch (e: any) {
    console.log(e);
    if (e.code === "ERR_NETWORK") {
      e.message = "Edit Branch master is failed";
    }
    yield put(failure_api_call_edit_branch_master(e));
  }
}

// list Agreement master
const agreementCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/aggrement_master/list_agreement", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListAgreementMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(agreementCall, e);

    yield put(success_api_call_agreement_master(response.data));
  } catch (e) {
    yield put(failure_api_call_agreement_master(e));
  }
}

// list one agreement
const oneAgreementCall = async (a: any) => {
  console.log(a);
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/aggrement_master/list?id=" + a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListOneAgreementMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(oneAgreementCall, e);

    yield put(success_api_call_list_one_agreement_master(response.data));
  } catch (e) {
    yield put(failure_api_call_list_one_agreement_master(e));
  }
}

// add agreement master

const addAgreementCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api + "/api/aggrement_master/create", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddAgreementMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(addAgreementCall, e);
    if (response.data.status) {
      yield put(success_api_call_add_agreement_master(response.data));
    } else {
      yield put(failure_api_call_add_agreement_master(response.data));
    }
  } catch (e) {
    yield put(failure_api_call_add_agreement_master(e));
  }
}

// edit agreement master

const editAgreementCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(api + "/api/aggrement_master/update", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* EditAgreementMaster(e: any) {
  try {
    const response: AxiosResponse = yield call(editAgreementCall, e);
    if (response.data.status) {
      yield put(success_api_call_edit_agreement_master(response.data));
    } else {
      yield put(failure_api_call_edit_agreement_master(response.data));
    }
  } catch (e) {
    yield put(failure_api_call_edit_agreement_master(e));
  }
}
// business channel list api

const BusinessChannelListCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/bussinesschannel/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListBusinessChannel(e: any) {
  try {
    console.log(e);
    const response: AxiosResponse = yield call(BusinessChannelListCall, e);

    yield put(success_api_call_business_channel(response.data));
  } catch (e) {
    yield put(failure_api_call_business_channel(e));
  }
}
// add business channel

const addBranchCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api + "/api/bussinesschannel/create", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddBusinessChannelApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addBranchCall, e);
    if (response.data.status) {
      yield put(success_api_call_add_business_channel(response.data));
    } else {
      yield put(failure_api_call_add_business_channel(response.data));
    }
  } catch (e) {
    yield put(failure_api_call_add_business_channel(e));
  }
}

// add business channel

const editBusinessCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(api + "/api/bussinesschannel/update", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* EditBusinessChannelApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editBusinessCall, e);
    if (response.data.status) {
      yield put(success_api_call_edit_business_channel(response.data));
    } else {
      yield put(failure_api_call_edit_business_channel(response.data));
    }
  } catch (e) {
    yield put(failure_api_call_edit_business_channel(e));
  }
}

// add state master

const addstateMaster = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api + "/api/state/create", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddStateMasterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addstateMaster, e);
    if (response.data.status) {
      yield put(success_api_call_add_state_master(response.data));
    } else {
      yield put(failure_api_call_add_state_master(response.data));
    }
  } catch (e) {
    yield put(failure_api_call_add_state_master(e));
  }
}

// list state master

const liststateMaster = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(api + "/api/state/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* ListStateMasterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(liststateMaster, e);

    yield put(success_api_call_list_state_master(response.data));
  } catch (e) {
    yield put(failure_api_call_list_state_master(e));
  }
}

// update state master

const editstateMaster = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(api + "/api/state/update", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* editStateMasterApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editstateMaster, e);
    if (response.data.status) {
      yield put(success_api_call_edit_state_master(response.data));
    } else {
      yield put(failure_api_call_edit_state_master(response.data));
    }
  } catch (e) {
    yield put(failure_api_call_edit_state_master(e));
  }
}
