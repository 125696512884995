import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import {
  failure_api_call,
  success_api_call,
} from "./get-aggrement-otp-reducer";
import { isVariableStatement } from "typescript";
import { configs } from "../../../common";

// const api = "http://192.168.1.114:3035/api5/generateotp";
const api = configs.api + "/api/partner/generateotp";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api, a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* getAggrementOtp(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);

    yield put(success_api_call(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call(e));
  }
}
