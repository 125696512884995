import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import CompletePopup from "../../../pages/components/response-pop-up";

import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};
export const CreatePartnerDetails: any = createSlice({
  name: "CreatePartnerDetails",
  initialState: {
    data: [],
    api_completed: false,
    isCompleted: false,
    isLoading: false,
    isSuccess: false,
  },
  reducers: {
    raise_api_call_Create_partner: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_Create_partner: (state, { payload }) => {
      // console.log(action);

      state.data = payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      // SUCCESE(payload.message);
    },
    failure_api_call_Create_partner: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
      state.api_completed = true;
      // FAILED(payload);
    },
    Create_partner_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_partner_complete_api: (state, action?) => {
      state.api_completed = false;
    },
  },
});
export default CreatePartnerDetails.reducer;
export const {
  raise_api_call_Create_partner,
  create_partner_complete_api,
  success_api_call_Create_partner,
  failure_api_call_Create_partner,
  Create_partner_is_success,
} = CreatePartnerDetails.actions;
