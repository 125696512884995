import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const editUser = createSlice({
  name: "editUser",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    edit_user: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_edit_user: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_edit_user: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    edit_user_is_success: (state, action) => {
      state.isSuccess = false;
    },
    edit_user_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default editUser.reducer;
export const {
  edit_user,
  success_api_call_edit_user,
  edit_user_complete_api,
  failure_api_call_edit_user,
  edit_user_is_success,
} = editUser.actions;
