import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import "../../assets/css/login.css";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { raise_api_call_create_password } from "../../redux/login-and-create-password/create-password-reducer";
import { useNavigate } from "react-router";
import { raise_api_call_check_user } from "../../redux/login-and-create-password/email-exist-reducer";

const CreateNewPassword = () => {
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<Boolean>(false);
  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const validationSchema = Yup.object().shape({
    Password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    ConfirmPassword: Yup.string()
      .required("Confirm Password is required")

      .oneOf([Yup.ref("Password")], "password must be match"),
  });
  const formik = useFormik({
    initialValues: {
      Password: "",
      ConfirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    errors,
  } = formik;
  console.log(values);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CreatePassword = useSelector((state: any) => state.CreatePassword);

  // useEffect
  useEffect(() => {
    dispatch(
      raise_api_call_check_user({
        email: email,
      })
    );
    if (CreatePassword.data.status) {
      navigate("/login");
    }
  }, [CreatePassword]);

  // submit values
  const submit = (data: any) => {
    const create_password_data = {
      email: email,
      password: data.Password,
    };
    dispatch(raise_api_call_create_password(create_password_data));
  };

  return (
    <>
      <div className="create_password">
        <div
          className="page col-12"
          style={{
            minHeight: "100% !important",
            maxHeight: "100% !important",
            display:"flex",
            flexDirection:"row-reverse",
          }}
        >
          <div className="col-12">
          <img
              className="logo_image"
              src={require("../../assets/images/banner_Logo_2.jpg")}
            />
          </div>
          <div
              className="login_form" 
              style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
              }}>
              <div className=" d-flex justify-content-center align-items-center w-100">
                <div
                  className="create-password-form m-0"
                  style={
                    {
                      // maxWidth: "80vw",
                      // height: "500px",
                    }
                  }
                >
                  <h2>create new password</h2>
                  <p>Enter a valid password</p>
                  <div className="forms">
                    <div
                      className="text-fieald w-100"
                      style={{
                        height: "65px",
                      }}
                    >
                      <TextField
                        type={showPassword ? "text" : "Password"}
                        id="outlined-basic"
                        label={"Password"}
                        variant="outlined"
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="Password"
                        error={
                          touched.Password && errors.Password ? true : false
                        }
                        helperText={touched.Password && errors.Password}
                        className="w-100"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {showPassword ? (
                                <VisibilityOff
                                  type="button"
                                  onClick={() => {
                                    setShowPassword(false);
                                  }}
                                />
                              ) : (
                                <Visibility
                                  type="button"
                                  onClick={() => {
                                    setShowPassword(true);
                                  }}
                                />
                              )}
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: "0px",
                          },
                        }}
                      />
                    </div>
                    <div
                      className="text-fieald w-100"
                      style={{
                        height: "65px",
                      }}
                    >
                      <TextField
                        type={showConfirmPassword ? "text" : "password"}
                        id="outlined-basic"
                        label="Confirm Password"
                        variant="outlined"
                        value={values.ConfirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="ConfirmPassword"
                        error={
                          touched.ConfirmPassword && errors.ConfirmPassword
                            ? true
                            : false
                        }
                        helperText={
                          touched.ConfirmPassword && errors.ConfirmPassword
                        }
                        className="w-100"
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       {showConfirmPassword ? (
                        //         <VisibilityOff
                        //           type="button"
                        //           onClick={() => {
                        //             setShowConfirmPassword(false);
                        //           }}
                        //         />
                        //       ) : (
                        //         <Visibility
                        //           type="button"
                        //           onClick={() => {
                        //             setShowConfirmPassword(true);
                        //           }}
                        //         />
                        //       )}
                        //     </InputAdornment>
                        //   ),
                        //   style: {
                        //     borderRadius: "0px",
                        //   },
                        // }}
                      />
                    </div>
                    <div className="text-fieald login-button  w-100">
                      <LoadingButton
                        disabled={CreatePassword.isLoading ? true : false}
                        className="w-100 text-white "
                        onClick={() => {
                          handleSubmit();
                        }}
                        sx={{
                          borderRadius: "0px",
                          width: "100px",
                          height: "55px",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: 700,
                          backgroundColor: "#46A4E3",
                          textTransform: "capitalize",
                        }}
                        variant="outlined"
                      >
                        {CreatePassword.isLoading ? "Please Wait..." : "Submit"}
                      </LoadingButton>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
      
      {/* <div className="create_password">
        <div className="d-flex  w-100">
          <div className="SPRL-white-logo">
            <img
              className="w-100 h-100"
              src={require("../../assets/images/SPRVL-add.png")}
            />
          </div>
        </div>
        <div className="white_space d-flex">
          <div className="col-lg-7">
            <div className="SPRVL-black-log"></div>
            <div className="desc_feature">
              <h1>feature 1</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
          <div className="col-lg-5 d-flex">
            <div className="create-password-form">
              <h2>create new password</h2>
              <p>Enter a valid password</p>
              <div className="forms">
                <div className="text-fieald">
                  <TextField
                    type={showPassword ? "text" : "Password"}
                    id="outlined-basic"
                    label={"Password"}
                    variant="outlined"
                    value={values.Password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="Password"
                    error={touched.Password && errors.Password ? true : false}
                    helperText={touched.Password && errors.Password}
                    className="w-100"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <VisibilityOff
                              type="button"
                              onClick={() => {
                                setShowPassword(false);
                              }}
                            />
                          ) : (
                            <Visibility
                              type="button"
                              onClick={() => {
                                setShowPassword(true);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "0px",
                      },
                    }}
                  />
                </div>
                <div className="text-fieald">
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    id="outlined-basic"
                    label="Confirm Password"
                    variant="outlined"
                    value={values.ConfirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="ConfirmPassword"
                    error={
                      touched.ConfirmPassword && errors.ConfirmPassword
                        ? true
                        : false
                    }
                    helperText={
                      touched.ConfirmPassword && errors.ConfirmPassword
                    }
                    className="w-100"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showConfirmPassword ? (
                            <VisibilityOff
                              type="button"
                              onClick={() => {
                                setShowConfirmPassword(false);
                              }}
                            />
                          ) : (
                            <Visibility
                              type="button"
                              onClick={() => {
                                setShowConfirmPassword(true);
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "0px",
                      },
                    }}
                  />
                </div>
                <div className="text-fieald">
                  <LoadingButton
                    disabled={CreatePassword.isLoading ? true : false}
                    className="w-100 text-white "
                    onClick={() => {
                      handleSubmit();
                    }}
                    sx={{
                      borderRadius: "0px",
                      width: "100px",
                      height: "55px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 700,
                      backgroundColor: "#46A4E3",
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                  >
                    {CreatePassword.isLoading ? "Please Wait..." : "Submit"}
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default CreateNewPassword;
