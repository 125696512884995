import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};
export const AddBusinessBhannel = createSlice({
  name: "AddBusinessBhannel",
  initialState: {
    isLoading: false,
    api_completed: false,
    isSuccess: false,
    data: [],
  },
  reducers: {
    add_business_channel: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_add_business_channel: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_business_channel: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_business_channel_isSuccess: (state, action) => {
      state.isSuccess = false;
    },
    add_business_channel_api_complete: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddBusinessBhannel.reducer;
export const {
  add_business_channel,
  success_api_call_add_business_channel,
  add_business_channel_isSuccess,
  add_business_channel_api_complete,
  failure_api_call_add_business_channel,
} = AddBusinessBhannel.actions;
