import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const DownPayAccount = createSlice({
  name: "DownPayAccount",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_down_pay_account: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call_down_pay_account: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_down_pay_account: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    down_pay_receivable_isFirst: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default DownPayAccount.reducer;
export const {
  list_down_pay_account,
  success_api_call_down_pay_account,
  failure_api_call_down_pay_account,
  down_pay_receivable_isFirst,
} = DownPayAccount.actions;
