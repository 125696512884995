import React, { useEffect, createRef, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import "../../assets/css/sap-master/sap-master.css";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  agreement_master_is_first,
  list_agreement_master,
} from "../../redux/prefered-master/list-agreement-master";
import FormControlLabel from "@mui/material/FormControlLabel";
import { InputAdornment } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AutoComplete from "../components/autocomplete-input";
import {
  add_agreement_master,
  add_agreement_isSuccess,
  add_agreement_master_api_complete,
} from "../../redux/prefered-master/add-agreement";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import { addGroupDataData } from "../../redux/sap-master/group-master-active-data-reducer";
import { list_group_master } from "../../redux/sap-master/group-master";
import {
  edit_agreement_isSuccess,
  edit_agreement_master,
  edit_agreement_master_api_complete,
} from "../../redux/prefered-master/edit-agreement-master";
import CompletePopup from "../components/response-pop-up";
import { configs } from "../../common";
import AutoCompleteMultiple from "../components/multi-drop-down";
import { list_one_agreement_master } from "../../redux/prefered-master/list-one-agreement";
const SUPPORTED_FORMATS = [ "image/pdf"];
// api
// const api = "http://192.168.1.102:3035/";
const api = configs.api + "/";

const AgreementMaster = () => {
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const editAgreementMaster = useSelector(
    (state: any) => state.EditAgreementMaster
  );
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  const agreement_master_data = useSelector(
    (state: any) => state.ListAgreementMaster
  );
  const [search, setSearch] = useState<string>("");
  const add_agreement_master_data = useSelector(
    (state: any) => state.AddAgreementMaster
  );
  const fileRep = createRef<any>();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<{
    partner_type: string;
    group_name: string;
    active: boolean;
    valid_from_edit: string;
    valid_to_edit: string;
    edit_certificate_url: any;
    agreement_name: string;
  }>({
    partner_type: "",
    group_name: "",
    valid_from_edit: "",
    active: true,
    valid_to_edit: "",
    agreement_name: "",
    edit_certificate_url: "",
  });
  const [value, setValue] = React.useState<Date | null>(null);
  const group_master_data = useSelector((state: any) => state.GroupMasterList);
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const activeDatas = useSelector((state: any) => state.GroupMasterActiveData);
  const list_one_agreement = useSelector(
    (state: any) => state.ListOneAgreementMaster
  );
  const activeData = list_one_agreement.data;

  //   validations
  const validationSchema = Yup.object().shape({
    partner_type: Yup.string().required("partner type Is Required"),
    agreement_name: Yup.string().required("Agreement name Is Required"),
    Valid_from: Yup.string().required("valid from date Is Required"),
    group_name: Yup.array().min(1, "group name is required").nullable(),
    Valid_to: Yup.string().required("valid to date Is Required"),
    documentfile_url: Yup.mixed()
      .test(
        "fileFormat",
        "Unsupported Format - We only allow images.",
        (value) => {
          if (value) {
            SUPPORTED_FORMATS.includes(value.type);
          }
          return true;
        }
      )
      .required("agreement is required")
      .nullable(),
  });
  // console.log(editData.valid_from_edit.substring(0, 10));

  const edit_agreement_name: string = isEdit
    ? activeData.result.aggrement_name
    : "";
  const edit_valid_from: string = isEdit
    ? !activeData.result.Valid_from
      ? ""
      : activeData.result.Valid_from.substring(0, 10)
    : "";
  const edit_valid_to: string = isEdit
    ? !activeData.result.Valid_to
      ? ""
      : activeData.result.Valid_to.substring(0, 10)
    : "";
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner_type: isEdit ? activeData.result.partner_type : "",
      group_name: isEdit
        ? activeData.result.aggrement.map((e: any, index: number) => {
            return { label: e.groups_name, value: e.group_name };
          })
        : [],
      active: isEdit ? activeData.result.active : true,
      agreement_name: edit_agreement_name,
      Valid_from: edit_valid_from,
      Valid_to: edit_valid_to,
      documentfile_url: {
        name: "",
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  console.log(values);
  const submit = (a: any) => {
    if (!isEdit) {
      const formData = new FormData();
      formData.append("partner_type", a.partner_type);
      formData.append("active", a.active);

      formData.append("aggrement_name", a.agreement_name);
      formData.append("Valid_from", a.Valid_from);
      formData.append("Valid_to", a.Valid_to);
      formData.append("documentfile_url", a.documentfile_url);
      a.group_name.forEach((e: any) => {
        formData.append("group_name[]", e.value);
      });
      dispatch(add_agreement_master(formData));
    } else {
      // console.log("dshgsdgusdg");
      const formData = new FormData();
      formData.append("id", activeData.result.id);
      formData.append("partner_type", a.partner_type);
      formData.append("aggrement_name", a.agreement_name);
      formData.append("active", a.active);

      formData.append("Valid_from", a.Valid_from);
      formData.append("Valid_to", a.Valid_to);
      formData.append(
        "documentfile_url",
        a.documentfile_url.name
          ? a.documentfile_url
          : activeData.result.documentfile_url
      );
      a.group_name.forEach((e: any) => {
        formData.append("group_name[]", e.value);
      });
      dispatch(edit_agreement_master(formData));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  // console.log(activeData);

  // useEffect
  useEffect(() => {
    dispatch(raise_api_call_list_general_setting(""));
    dispatch(list_group_master(""));
    dispatch(list_agreement_master(""));
    if (add_agreement_master_data.isSuccess) {
      dispatch(add_agreement_isSuccess(""));
      setOpen(false);
      resetForm();
    }
    if (editAgreementMaster.isSuccess) {
      dispatch(edit_agreement_isSuccess(""));
      dispatch(list_one_agreement_master(activeData.result.id));
      setOpen(false);
      setEdit(false);
      resetForm();
    }
    if (add_agreement_master_data.api_completed) {
      setResponsePopup(true);
      dispatch(add_agreement_master_api_complete(false));
    }
    if (editAgreementMaster.api_completed) {
      setResponseEdit(true);
      dispatch(edit_agreement_master_api_complete(false));
      setResponsePopup(true);
    }
  }, [add_agreement_master_data, editAgreementMaster]);

  // mainTable data
  var list_one_data: any = {};
  const mainTable = () => {
    if (activeData.result != 0) {
      let data = { ...activeData.result };
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      data.edit_certificate_url = data.documentfile_url;
      data.valid_to_edit = data.Valid_to;
      data.valid_from_edit = data.Valid_from;
      data.documentfile_url = (
        <a href={api + data.documentfile_url} target="_blank">
          Document
        </a>
      );
      // valid from
      const valid_from = new Date(data.Valid_from);
      // delete data.EffecDate;
      const valid_from_date =
        valid_from.getDate() +
        "-" +
        monthNames[valid_from.getMonth()] +
        "-" +
        valid_from.getFullYear();
      data.Valid_from
        ? (data.Valid_from = valid_from_date)
        : console.log("sdfdsf");

      // delete data.EffecDate;

      //   valid to
      const valid_to = new Date(data.Valid_to);

      const valid_to_date =
        valid_to.getDate() +
        "-" +
        monthNames[valid_to.getMonth()] +
        "-" +
        valid_to.getFullYear();
      data.Valid_to ? (data.Valid_to = valid_to_date) : console.log("sdfdsf");

      // create on
      const created_on = new Date(data.created_on);
      // delete data.EffecDate;
      const created_date =
        created_on.getDate() +
        "-" +
        monthNames[created_on.getMonth()] +
        "-" +
        created_on.getFullYear();
      data.created_on
        ? (data.created_on = created_date)
        : console.log("sdfdsf");

      // update on
      const updated_on = new Date(data.updated_On);
      // delete data.EffecDate;
      const update_date =
        updated_on.getDate() +
        "-" +
        monthNames[updated_on.getMonth()] +
        "-" +
        updated_on.getFullYear();
      data.updated_On ? (data.updated_On = update_date) : console.log("sdfdsf");
      list_one_data = data;
    }
    // console.log(list_one_data);
    return (
      <>
        {
          <>
            <div className="list col-5 ">
              <div className=" px-3 my-4  d-flex align-items-center">
                <div className="title d-flex ">
                  <h5>Agreement Master</h5>
                </div>

                <div className="filter ms-auto me-3 d-flex align-items-center">
                  <TextField
                    placeholder="Search"
                    variant="outlined"
                    className="w-100 me-2"
                    name="search"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "0px",
                        height: "45px",
                      },
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      dispatch(list_agreement_master(""));
                    }}
                    className="mx-2"
                    style={{
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                    icon={["fas", "arrows-rotate"]}
                  />
                </div>
              </div>
              <div className="list ">
                {agreement_master_data.data.length === 0
                  ? ""
                  : agreement_master_data.data.result
                      .filter((item: any) => {
                        return Object.values(item)
                          .join("")
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      })
                      .map((e: any, key: number) => {
                        // if (agreement_master_data.isFirst && key === 0) {
                        //   dispatch(agreement_master_is_first(""));
                        //   dispatch(addGroupDataData(e));
                        // }
                        return (
                          <>
                            <div
                              key={key + 1}
                              id={
                                (list_one_data.id ? list_one_data.id : "") ===
                                e.id
                                  ? "active-lists"
                                  : ""
                              }
                              onClick={(a) => {
                                setActivePartner(key);
                                dispatch(list_one_agreement_master(e.id));
                              }}
                              className={"d-flex lists flex-column mt-2  p-3"}
                              style={{
                                height: "100px",
                                background: "#FFFFFF",
                              }}
                            >
                              <div
                                className="mx-2 partner-name"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {e.aggrement_name}
                              </div>
                              <div className="d-flex mt-auto partner-locations align-items-center">
                                <p className="ms-2 me-auto mb-0">
                                  {/* {e.partner_name} */}
                                </p>
                                <p
                                  className={
                                    e.status === "Request Sent for Approval"
                                      ? "ms-auto me-2 mb-0 text-yellow"
                                      : "ms-auto text-light-green me-2 mb-0"
                                  }
                                >
                                  {/* {e.status} */}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
              </div>
            </div>
            <div
              className="col-7 tracking pt-4 px-4"
              style={{
                height: "100vh",
              }}
            >
              {Object.keys(list_one_data).length != 0 && list_one_data.id ? (
                <>
                  <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
                    <div
                      className="mx-4 add-branch"
                      onClick={() => {
                        handleClickOpen();
                      }}
                    >
                      Add Agreement
                    </div>
                    <div>
                      <FontAwesomeIcon
                        onClick={() => {
                          setEdit(true);
                          setEditData(activeData);
                          handleClickOpen();
                        }}
                        title="edit"
                        className="mx-2"
                        style={{
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                        icon={["fas", "pen-to-square"]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Agreement Name</label>
                      <p
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {list_one_data.aggrement_name}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Partner Type</label>
                      <p>{list_one_data.partner_name}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Agreement</label>
                      <p>{list_one_data.documentfile_url}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Valid From</label>
                      <p>{list_one_data.Valid_from}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Valid To</label>
                      <p>{list_one_data.Valid_to}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Active</label>
                      <p>{list_one_data.active ? "Active" : "InActive"}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Create On</label>
                      <p>{list_one_data.created_on}</p>
                    </div> 
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Update On</label>
                      <p>{list_one_data.updated_On}</p>
                    </div>
                    <div className="col-12 partner-detail-data mt-4">
                      <label>Group Name</label>
                      <div className="d-flex">
                        {list_one_data.aggrement
                          ? list_one_data.aggrement.length != 0
                            ? list_one_data.aggrement.map(
                                (e: any, key: number) => {
                                  return (
                                    <>
                                      <p
                                        key={key}
                                        className="mb-0"
                                        style={{
                                          fontWeight: "700",
                                        }}
                                      >
                                        {e.groups_name}
                                        {key ===
                                        list_one_data.aggrement.length - 1
                                          ? " "
                                          : ","}{" "}
                                        &nbsp;
                                      </p>
                                    </>
                                  );
                                }
                              )
                            : ""
                          : ""}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
                    <div
                      className="mx-4 add-branch"
                      onClick={() => {
                        handleClickOpen();
                      }}
                    >
                      Add Agreement
                    </div>
                  </div>
                  <div>Please Select Anyone</div>
                </>
              )}

              {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
            </div>
          </>
        }
      </>
    );
  };

  const partner_type_option =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "partner_type") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const group_name_option =
    group_master_data.data.length != 0
      ? group_master_data.data.status
        ? group_master_data.data.result
            .filter((e: any) => {
              if (values.partner_type === "V" && "S" === e.group_type) {
                return e;
              }
              if (values.partner_type === e.group_type) {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.group_name, value: e.group_code };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  return (
    <>
      <div className="general-setting sap-master">
        {responsePopup ? (
          <CompletePopup
            data={
              responseEdit ? editAgreementMaster : add_agreement_master_data
            }
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
              setResponseEdit(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            generalSettingList.isLoading ||
            agreement_master_data.isLoading ||
            list_one_agreement.isLoading
              ? true
              : false
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        {/* <div className="title d-flex">
          <h5>Agreement Master</h5>
          <div className="ms-auto me-2 d-flex align-items-center">
            <div
              className="mx-4 add-branch"
              onClick={() => {
                handleClickOpen();
              }}
            >
              Add Agreement
            </div>
            <FontAwesomeIcon
              onClick={() => {
                dispatch(list_agreement_master(""));
              }}
              style={{
                fontSize: "22px",
                cursor: "pointer",
              }}
              icon={["fas", "arrows-rotate"]}
            />
          </div>
        </div> */}
        <div className="forms d-flex">{mainTable()}</div>
      </div>
      {/* add agreemetn */}
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700">
            {"Add Agreement"}{" "}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="d-flex flex-column">
            <div className="row">
              <div className="input-field px-3 col-6  d-flex flex-column">
                <label className="px-3">
                  Agreement Name<span>*</span>
                </label>

                <TextField
                  className="px-3"
                  placeholder="Agreement Name"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agreement_name}
                  error={
                    touched.agreement_name && errors.agreement_name
                      ? true
                      : false
                  }
                  helperText={
                    touched.agreement_name && errors.agreement_name
                      ? errors.agreement_name
                      : ""
                  }
                  name="agreement_name"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 col-6 d-flex flex-column">
                <AutoComplete
                  pageOption={partner_type_option}
                  name="partner_type"
                  label="Partner Type"
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  vari={(e: any) => {
                    setFieldValue("partner_type", e ? e.value : "");
                  }}
                />
              </div>
              <div className="input-field px-3 col-12 mt-3 d-flex flex-column">
                <AutoCompleteMultiple
                  pageOption={group_name_option}
                  name="group_name"
                  label="Group Name"
                  touched={touched}
                  notRequire={!values.group_name ? true : false}
                  readOnly={!values.group_name ? true : false}
                  errors={errors}
                  values={values}
                  handleBlur={handleBlur}
                  vari={(e: any) => {
                    // console.log(e);
                    setFieldValue("group_name", e);
                  }}
                />
              </div>
              <div className="input-field px-3 col-6 mt-3 d-flex flex-column">
                <label className="px-3">
                  Valid from<span>*</span>
                </label>

                <TextField
                  type="date"
                  className="px-3"
                  placeholder="Address Id"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Valid_from}
                  error={touched.Valid_from && errors.Valid_from ? true : false}
                  helperText={
                    touched.Valid_from && errors.Valid_from
                      ? errors.Valid_from
                      : ""
                  }
                  name="Valid_from"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 col-6 mt-3 d-flex flex-column">
                <label className="px-3">
                  Valid To <span>*</span>
                </label>
                <TextField
                  type="date"
                  className="px-3"
                  placeholder="Address Id"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Valid_to}
                  error={touched.Valid_to && errors.Valid_to ? true : false}
                  helperText={
                    touched.Valid_to && errors.Valid_to ? errors.Valid_to : ""
                  }
                  name="Valid_to"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>

              <div className="input-field px-3  col-6 d-flex mt-3 flex-column">
                <label className="mx-3">Agreement</label>
                <div
                  className={
                    errors.documentfile_url && touched.documentfile_url
                      ? "msme-certificate-require mx-3"
                      : "msme-certificate mx-3"
                  }
                  onClick={() => {
                    fileRep.current.click();
                    // console.log("DSF");
                  }}
                >
                  <FontAwesomeIcon
                    className="ms-50"
                    icon={["fas", "paperclip"]}
                    style={{
                      fontSize: "1vw",
                      marginLeft: "10px",
                      marginRight: "10px",
                      color: "#908f8f",
                    }}
                  />
                  <label>
                    {values.documentfile_url.name === ""
                      ? "Upload agreement"
                      : values.documentfile_url.name}
                  </label>
                </div>
                <span
                  className={
                    errors.documentfile_url && touched.documentfile_url
                      ? "text-danger mx-3"
                      : "mx-3"
                  }
                >
                  Supported file(pdf)
                </span>
                <TextField
                  inputRef={fileRep}
                  type="file"
                  // value={values.msme_certificate}
                  name="documentfile_url"
                  onChange={(e: any) => {
                    setFieldValue("documentfile_url", e.target.files[0]);
                    setFieldTouched("documentfile_url", true, false);
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    display: "none",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <div className="col-6 d-flex ms-3 align-items-center">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox checked={values.active} />}
                    label="Is Active"
                    onChange={handleChange}
                    name="active"
                    labelPlacement="end"
                    sx={{
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    onClick={() => {
                      handleClose();
                      setEdit(false);
                    }}
                    className="cancel"
                  >
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {add_agreement_master_data.isLoading
                      ? "Please Wait"
                      : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AgreementMaster;
