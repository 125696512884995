import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { configs } from "../../../common";
export const VerifyOtpOnboarding: any = createSlice({
  name: "VerifyOtpOnboarding",
  initialState: {
    data: [],
    isCompleted: false,
    isLoading: false,
    isSuccess: false,
    isVerified: false,
  },
  reducers: {
    raise_api_call_verify_otp_onboarding: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_verify_otp_onboarding: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      if (action.payload.status) {
        state.isVerified = true;
      }
    },
    failure_api_call_verify_otp_onboarding: (state, action) => {
      state.isLoading = false;
    },
    verify_otp_onboarding_is_success: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default VerifyOtpOnboarding.reducer;
export const {
  raise_api_call_verify_otp_onboarding,
  success_api_call_verify_otp_onboarding,
  failure_api_call_verify_otp_onboarding,
  verify_otp_onboarding_is_success,
} = VerifyOtpOnboarding.actions;

// const api = "http://185.209.228.65:3035";
// const api = "http://192.168.1.112:3035";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(configs.api + "/api/partner/verifyotp", a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export function* VerifyOtpOnboardingApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e.payload);
    if (response.data.status) {
      yield put(success_api_call_verify_otp_onboarding(response.data));
    } else {
      yield put(failure_api_call_verify_otp_onboarding(response.data));
    }
  } catch (err) {
    yield put(failure_api_call_verify_otp_onboarding(err));
  }
}
