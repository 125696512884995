import React, { useEffect, useState, createRef } from "react";
import "../../assets/css/business-partner/partner-detail.css";
import AutoComplete from "../components/autocomplete-input";
import SearchIcon from "@mui/icons-material/Search";
import TextFiealdInput from "../components/textFiald";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import AutoCompleteMultiple from "../components/multi-drop-down";

import { useSelect } from "@mui/base";
import TabContext from "@mui/lab/TabContext";
import MainTable from "../components/main-tables";
import TabList from "@mui/lab/TabList";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector, useDispatch } from "react-redux";
import {
  partner_master_isFirst,
  raise_api_call_list_partner_master,
} from "../../redux/business-partner/partner-master/list-partner-master";
import { addPartnerData } from "../../redux/business-partner/partner-master/partner-master-first-data";
import { useNavigate } from "react-router";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  success_api_call_Create_partner,
  Create_partner_is_success,
} from "../../redux/business-partner/partner-master/create-partner-master-reducer";
import {
  approve_partner_complete_api,
  approve_partner_is_succese,
  management_approve_partner,
} from "../../redux/business-partner/partner-master/approve-partner";
import { configs } from "../../common";
import {
  convert_sap_from_api,
  convert_sap_is_first,
} from "../../redux/business-partner/partner-master/move-xml-data";
import { raise_api_call_list_partner_one_detail } from "../../redux/business-partner/partner-master/list-one-partner-details";
import CompletePopup from "../components/response-pop-up";
import EditIcon from "@mui/icons-material/Edit";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
//file supported formats
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const Management = () => {
  const [country_code, setCountry_code] = React.useState(91);
  const dispatch = useDispatch();
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const [firstData, setFirstData] = useState<boolean>(true);

  const PartnerMasterList = useSelector(
    (state: any) => state.listPartnerMaster
  );
  const managementApproveApi = useSelector(
    (state: any) => state.ApprovePartner
  );
  const movedSap = useSelector((state: any) => state.PartnerMasterToSap);
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  const navigate = useNavigate();
  const activeDatas = useSelector((state: any) => state.listOnePartnerDetail);
  const activeData =
    activeDatas.data.length != 0 ? activeDatas.data.result[0] : {};

  const validationSchema = Yup.object().shape({
    about: Yup.string().required("reason Is Required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      about: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  // if (firstData) {
  //   if (PartnerMasterList.data.length != 0) {
  //     setFirstData(false);
  //     if (PartnerMasterList.isFirst) {
  //       setActivePartner(PartnerMasterList.data.result[0]);
  //       dispatch(partner_master_isFirst(""));
  //     }
  //   }
  // }

  // submit
  const submit = (e: any) => {
    const data = {
      id: activeData.id,
      status: "rejected",
      description: e.about,
      url: "update_management",
    };
    if (!managementApproveApi.isLoading) {
      dispatch(management_approve_partner(data));
    }
  };

  console.log(activeData);

  // steppers

  const steps = [
    "Employee",
    "Management",
    "Partner",
    "Accounts",
    "Accounts Head",
    "SAP",
  ];
  const payment_terms =
    generalSettingList.data.length != 0
      ? generalSettingList.data.status
        ? generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "Payment Terms") {
                return e;
              }
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  //
  const [value, setValue] = React.useState("1");
  const [search, setSearch] = useState<string>("");
  //   Dialog
  const [open, setOpen] = React.useState(false);
  const [managementReject, setManagementReject] = React.useState(false);
  const [managementEdit, setManagementEdit] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //  and approve
  const RejectManagement = () => {
    setManagementReject(true);
  };

  const EditManagement = () => {
    setManagementEdit(true);
    navigate("/business-partner/onboarding/edit");
  };
  const ManagementRejectClose = () => {
    setManagementReject(false);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const steperData = activeData.approvall
    ? activeData.approvall.length != 0
      ? activeData.approvall[activeData.approvall.length - 1].role_name ===
        "employee"
        ? 0
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
          "management"
        ? 1
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
          "partner"
        ? 2
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
          "account_team"
        ? 3
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
            "account_head" &&
          activeData.approvall[activeData.approvall.length - 1].status !=
            "approved"
        ? 4
        : activeData.approvall[activeData.approvall.length - 1].role_name ===
            "account_head" &&
          activeData.approvall[activeData.approvall.length - 1].status ===
            "approved"
        ? 6
        : 6
      : 0
    : 0;
  const isRejected = activeData.approvall
    ? activeData.approvall.length != 0
      ? activeData.approvall[activeData.approvall.length - 1].status ===
        "rejected"
        ? true
        : false
      : false
    : false;
  // console.log(isRejected);
  // useEfect

  useEffect(() => {
    dispatch(raise_api_call_list_partner_master(""));
    dispatch(raise_api_call_list_general_setting(""));

    if (managementApproveApi.isSuccess) {
      dispatch(approve_partner_is_succese(""));
      dispatch(raise_api_call_list_partner_one_detail("?id=" + activeData.id));
      // console.log(managementApproveApi);
      setOpen(false);
      ManagementRejectClose();
    }
    if (managementApproveApi.api_completed) {
      setResponsePopup(true);
      dispatch(approve_partner_complete_api(false));
    }
  }, [managementApproveApi]);

  // stepper line color change

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {},
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "#82CA89",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "#82CA89",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));
  console.log(activeData);

  return (
    <>
      <div className="partner-details ">
        {responsePopup ? (
          <CompletePopup
            data={managementApproveApi}
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            PartnerMasterList.isLoading ||
            activeDatas.isLoading ||
            managementApproveApi.isLoading
              ? true
              : false
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className="row mb-5">
          <div className="col-5 pe-0 partner-details-list">
            <div className=" ms-5 me-2 my-4  d-flex ">
              <div className="title">
                <h5>Partner Details</h5>
              </div>
              <div className="filter ms-auto me-2">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="w-100"
                  name="search"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "45px",
                    },
                  }}
                />
              </div>
            </div>
            <div className="general-setting-list">
              <div className="list">
                {PartnerMasterList.data.length === 0
                  ? ""
                  : PartnerMasterList.data.result
                      .filter((item: any) => {
                        return Object.values(item)
                          .join("")
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      })
                      .map((e: any, key: number) => {
                        return (
                          <>
                            <div
                              key={key + 1}
                              id={e.id === activeData.id ? "active-lists" : ""}
                              onClick={(a) => {
                                setActivePartner(key);
                                dispatch(
                                  raise_api_call_list_partner_one_detail(
                                    "?id=" + e.id
                                  )
                                );
                              }}
                              className={"d-flex lists flex-column mt-2  p-3"}
                              style={{
                                height: "100px",
                                background: "#FFFFFF",
                              }}
                            >
                              <div className="mx-2 partner-name">
                                {e.company_name != null
                                  ? e.company_name
                                  : e.businessPartner[0].company_name}
                              </div>
                              <div className="d-flex mt-auto partner-locations align-items-center">
                                <p className="ms-2 me-auto mb-0">
                                  {e.city != null
                                    ? e.city
                                    : e.contactAddress.length != 0
                                    ? e.contactAddress[0].city
                                    : "-"}
                                </p>
                                <p
                                  className={
                                    e.status === "Request Sent for Approval"
                                      ? "ms-auto me-2 mb-0 text-yellow"
                                      : "ms-auto text-yellow me-2 mb-0"
                                  }
                                >
                                  {e.status}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
              </div>
            </div>
          </div>
          <div className="col-7 vh-100 overflow-scroll pt-5 tracking px-0">
            {Object.keys(activeData).length === 0 ? (
              <>
                <div className="text-center text-bold">
                  Please Select Any One Partner
                </div>
              </>
            ) : (
              <>
                <div className="steper ">
                  <Box sx={{ width: "100%" }}>
                    <Stepper
                      activeStep={steperData}
                      alternativeLabel
                      connector={<ColorlibConnector />}
                    >
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel
                            sx={{
                              color: "#82CA89",
                            }}
                            error={
                              isRejected
                                ? steperData === index
                                  ? true
                                  : false
                                : false
                            }
                            icon={
                              isRejected ? (
                                steperData === index ? (
                                  <CancelIcon color="error" />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            }
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                <div className="partner-review-button mt-3">
                  <div className="d-flex managementRole align-items-center">
                    <p className="mb-0">
                      {activeData.approvall
                        ? activeData.approvall.length !== 0
                          ? activeData.approvall[
                              activeData.approvall.length - 1
                            ].description
                          : ""
                        : ""}
                    </p>
                    {activeData.approvall.filter((a: any, index: number) => {
                      if (index === activeData.approvall.length - 1) {
                        if (
                          a.role_name === "management" &&
                          a.status === "Request send to management"
                        ) {
                          return a;
                        }
                      }
                    }).length != 0 ? (
                      <div className="d-flex mb-0 ms-auto me-4">
                        <div
                          className="reject"
                          onClick={() => {
                            RejectManagement();
                          }}
                        >
                          Reject
                        </div>
                        <div
                          className="agree"
                          onClick={() => {
                            handleClickOpen();
                          }}
                        >
                          Approve
                        </div>
                        <FontAwesomeIcon
                          style={{
                            height: 30,
                            marginRight: 6,
                            cursor: "pointer",
                          }}
                          icon="edit" // You might need to specify the icon, for example, "edit"
                          title="Edit partner onboarding"
                          onClick={() => {
                            EditManagement();
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="details-information px-3">
                  <div className="row p-3">
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Company Name</label>
                      <p>
                        {activeData.company_name
                          ? activeData.company_name
                          : activeData.businessPartner[0].company_name}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Alternate Name</label>
                      <p>
                        {" "}
                        {activeData.foreign_name
                          ? activeData.foreign_name
                          : activeData.businessPartner[0].foreign_name}
                      </p>
                    </div>
                    <div className="col-4 mt-4"></div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Business Type</label>
                      <p>
                        {activeData.bussiness_type_1
                          ? activeData.bussiness_type_1
                          : ""}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Partner Type</label>
                      <p>
                        {activeData.partner_name
                          ? activeData.partner_name
                          : activeData.partner_type}
                      </p>
                    </div>
                    <div className="col-4 mt-4"></div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Group Name</label>
                      <p>
                        {" "}
                        {activeData.groups_name
                          ? activeData.groups_name
                          : activeData.group_name}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Branch</label>
                      <div className="d-flex">
                        {activeData.branch.map((e: any, key: number) => {
                          return (
                            <div key={key} className="px-2">
                              <img
                                src={configs.api + "/" + e.branch_logo}
                                alt="branch"
                                style={{
                                  maxHeight: "50px",
                                  maxWidth: "200px",
                                }}
                              ></img>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-4 mt-4"></div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Contact Person</label>
                      <p>{activeData.contact_person}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Contact Email Id</label>
                      <Tooltip title={activeData.contact_email}>
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {activeData.contact_email}
                        </p>
                      </Tooltip>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Contact Phone No</label>
                      <p>{activeData.contact_phone}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Annual Turn Over</label>
                      <p> {activeData.annual_turnover}</p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Currency</label>
                      <p>
                        {activeData.currency_1
                          ? activeData.currency_1
                          : activeData.currency}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Payment Terms</label>
                      <p>
                        {activeData.payments_terms
                          ? payment_terms.find(
                              (terms: any) =>
                                terms.value === activeData.payments_terms
                            ).label
                          : ""}
                      </p>
                    </div>
                    <div className="col-4 partner-detail-data mt-4">
                      <label>Country</label>
                      <p>
                        {activeData.country_1
                          ? activeData.country_1
                          : activeData.country}
                      </p>
                    </div>{" "}
                    <div className="col-4 partner-detail-data mt-4">
                      <label>State</label>
                      <p>
                        {activeData.state_1
                          ? activeData.state_1
                          : activeData.state}
                      </p>
                    </div>{" "}
                    <div className="col-4 partner-detail-data mt-4">
                      <label>City</label>
                      <p>{activeData.city ? activeData.city : "-"}</p>
                    </div>{" "}
                    <div className="col-12 partner-detail-data mt-4">
                      <label>About</label>
                      <p
                        className="text-justify"
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        {activeData.about ? activeData.about : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                {/* history */}
                <div className="history p-4 ">
                  <div className="title-history ">History</div>
                  {activeData.approvall
                    ? activeData.approvall.map((e: any, index: number) => {
                        const monthNames = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        // create on
                        const data = { ...e };
                        const created_on = new Date(data.created_on);
                        // delete data.EffecDate;
                        const created_date =
                          created_on.getDate() +
                          "-" +
                          monthNames[created_on.getMonth()] +
                          "-" +
                          created_on.getFullYear();
                        data.created_on
                          ? (data.created_on = created_date)
                          : console.log("sdfdsf");
                        var seconds = created_on.getSeconds();
                        var minutes = created_on.getMinutes();
                        var hour = created_on.getHours();

                        return (
                          <>
                            <div key={index + 1} className="row">
                              <div className="col-4 partner-detail-data mt-4">
                                <label>Status</label>
                                <p>{e.status}</p>
                              </div>
                              <div className="col-4 partner-detail-data mt-4">
                                <label>Name</label>
                                <p>{e.creater_name}</p>
                              </div>
                              <div className="col-4 partner-detail-data mt-4">
                                <label>Date/Time</label>
                                <p>
                                  {data.created_on},
                                  {hour + ":" + minutes + ":" + seconds}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </div>
              </>
            )}

            {/* pop up diologs */}

            {/* management approve */}
            <Dialog
              PaperProps={{
                style: { borderRadius: 0 },
              }}
              open={open}
              onClose={handleClose}
              maxWidth={"md"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="d-flex">
                <DialogTitle
                  id="alert-dialog-title"
                  className="font-weight-700"
                >
                  {"Confirmation"}{" "}
                </DialogTitle>

                <div className="ms-auto p-3">
                  <CancelIcon
                    onClick={() => {
                      handleClose();
                    }}
                    sx={{
                      color: "#D1D1D1",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure want to approve?
                </DialogContentText>
                {/* <DialogContentText id="alert-dialog-description"> */}
                <div className="d-flex flex-column">
                  <div></div>
                  <div className="col-12 d-flex justify-content-center">
                    <div
                      className="add-more px-3 col-3 d-flex flex-column"
                      style={{
                        width: "200px",
                      }}
                    >
                      <div onClick={handleClose} className="cancel">
                        Cancel
                      </div>
                    </div>
                    <div
                      className="add-more px-3 col-3 d-flex flex-column"
                      style={{
                        width: "200px",
                      }}
                    >
                      <div
                        className="add"
                        onClick={() => {
                          const data = {
                            id: activeData.id,
                            status: "approved",
                            description: "management approved",

                            url: "update_management",
                          };
                          if (!managementApproveApi.isLoading) {
                            dispatch(management_approve_partner(data));
                          }
                        }}
                      >
                        {managementApproveApi.isLoading
                          ? "Please Wait"
                          : "Confirm"}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            {/* management reject reason */}
            <Dialog
              PaperProps={{
                style: { borderRadius: 0, width: "700px" },
              }}
              open={managementReject}
              onClose={ManagementRejectClose}
              maxWidth={"lg"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="d-flex">
                <DialogTitle
                  id="alert-dialog-title"
                  className="font-weight-700 pb-0"
                >
                  Reason for Rejection
                </DialogTitle>

                <div className="ms-auto p-3">
                  <CancelIcon
                    onClick={() => {
                      ManagementRejectClose();
                    }}
                    sx={{
                      color: "#D1D1D1",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
              <DialogContent className="pt-0">
                <div className="input-field pt-0">
                  <label>About</label>
                  <TextField
                    fullWidth
                    id="reason-reject"
                    className="reason-reject"
                    multiline
                    name="about"
                    onChange={handleChange}
                    value={values.about}
                    onBlur={handleBlur}
                    error={touched.about && errors.about ? true : false}
                    helperText={
                      touched.about && errors.about ? errors.about : ""
                    }
                    sx={{
                      height: "80px",
                    }}
                    inputProps={{
                      style: {
                        height: "80px",
                      },
                    }}
                  />
                </div>
                {/* <DialogContentText id="alert-dialog-description"> */}
                <div className="d-flex flex-column">
                  <div></div>
                  <div className="col-12 d-flex justify-content-center">
                    <div
                      className="add-more px-3 col-3 d-flex flex-column"
                      style={{
                        width: "200px",
                      }}
                    >
                      <div onClick={ManagementRejectClose} className="cancel">
                        Cancel
                      </div>
                    </div>
                    <div
                      className="add-more px-3 col-3 d-flex flex-column"
                      style={{
                        width: "200px",
                      }}
                    >
                      <div
                        className="add"
                        onClick={() => {
                          // ManagementRejectClose();
                          handleSubmit();
                        }}
                      >
                        {managementApproveApi.isLoading
                          ? "Please Wait"
                          : "Send"}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
};
export default Management;
