import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const CustomerReceivable = createSlice({
  name: "CustomerReceivable",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_customer_receivable: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call_customer_receivable: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_customer_receivable: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    customer_receivable_isFirst: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default CustomerReceivable.reducer;
export const {
  list_customer_receivable,
  success_api_call_customer_receivable,
  failure_api_call_customer_receivable,
  customer_receivable_isFirst,
} = CustomerReceivable.actions;
