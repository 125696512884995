import React, { createRef, useEffect, useRef, useState } from "react";
import "../../assets/css/business-partner/add-onboarding.css";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Backdrop from "@mui/material/Backdrop";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import * as FileSaver from "file-saver";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "@mui/material/Checkbox";
import MainTable from "../components/main-tables";
import Dialog from "@mui/material/Dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  raise_api_for_list_onboarding,
  submit_basic_info_state,
} from "../../redux/business-partner/onboarding/basic-info-reducer";
import { submit_contact_info_state } from "../../redux/business-partner/onboarding/contact-details-reducer";
import { submit_Address_info_state } from "../../redux/business-partner/onboarding/address-reducer";
import { submit_bank_detail_state } from "../../redux/business-partner/onboarding/bank-detail-reducer";
import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import {
  add_basic_info_loading_complete,
  raise_api_call_add_basicInfo,
} from "../../redux/business-partner/onboarding/basic-info-add-reducer";
import DownloadIcon from "@mui/icons-material/Download";
import { list_bank_master } from "../../redux/sap-master/bank-master";
import {
  clear_data_bank_Ifsc_code,
  list_bank_Ifsc_code,
} from "../../redux/sap-master/get-bank-ifsc-code";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { get_agreement_from_api } from "../../redux/business-partner/onboarding/get-agreements";
import { get_otp_from_api } from "../../redux/business-partner/onboarding/get-aggrement-otp-reducer";
import { raise_api_call_verify_otp_onboarding } from "../../redux/business-partner/onboarding/verify-otp-reducer";
import { list_branch_master } from "../../redux/prefered-master/list-branch-master";
import AutoComplete from "../components/autocomplete-input";
import { raise_api_call_list_partner_master } from "../../redux/business-partner/partner-master/list-partner-master";
import { raise_api_call_list_partner_one_master } from "../../redux/business-partner/partner-master/list-one-partner-master";
import {
  edit_basic_info_loading_complete,
  raise_api_call_edit_basic_info,
} from "../../redux/business-partner/onboarding/basic-info-edit-reducer";
import { submit_clarification } from "../../redux/business-partner/onboarding/clarification-submited";
import { configs, COUNTRIES } from "../../common";
import { edit_bank_is_success } from "../../redux/business-partner/onboarding/bank-detail-edit-reducer";
import { create_bank_is_success } from "../../redux/business-partner/onboarding/bank-detail-add-reducer";
import { Link } from "react-router-dom";
import axios from "axios";
import { ReactNode } from "react";
import { toast } from "react-toastify";
// import sample from "../../assets/Resume.pdf";

//file supported formats
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const AddOnBoarding = () => {
  const [value, setValue] = React.useState("1");
  const [load, setLoad] = useState<boolean>(false);

  const [scrolledPage, setScrolledPage] = useState<boolean>(false);
  const [submitData, setSubmitData] = useState<boolean>(false);
  const [banksubmitForm, setSubmitForm] = useState<boolean>(false);
  const PartnerMasterList = useSelector(
    (state: any) => state.listOnePartnerMaster
  );
  const [clarificationData, setClarificationData] = useState<{
    basicInfo: any;
    contact_info: any;
    address_info: any;
    bank_detail: any;
  }>({ basicInfo: "", contact_info: "", address_info: "", bank_detail: "" });

  const BasicInfoRep = createRef<any>();
  const dispatch = useDispatch();
  const basicInfoData = useSelector((state: any) => state.onboardingBasicInfo);
  const [gst_filling_status, setGstFillingStatus] = useState<boolean>(
    basicInfoData.isCompleted ? basicInfoData.data.gst_filling_status : true
  );
  const verifyed_otp = useSelector((state: any) => state.VerifyOtpOnboarding);
  const [firstNumber, setFirstNumber] = useState<string | null>("");
  const [secondNumber, setSecondNumber] = useState<string | null>("");
  const [thirdNumber, setThirdNumber] = useState<string | null>("");
  const [forthNumber, setForthNumber] = useState<string | null>("");
  const [fifthNumber, setFifthNumber] = useState<string | null>("");

  const agreementData = useSelector(
    (state: any) => state.getOnboardingAggrement
  );
  const ContactInfoData = useSelector(
    (state: any) => state.onboardingContactInfo
  );

  const AddressInfoData = useSelector(
    (state: any) => state.onboardingAddressInfo
  );
  const bank_Detail_Data = useSelector(
    (state: any) => state.onboardingBankDetail
  );

  const SubmitClarification = useSelector(
    (state: any) => state.SubmitClarification
  );
  const partnerOneList = useSelector(
    (state: any) => state.listOnePartnerMaster
  );
  const createBasicInfo = useSelector(
    (state: any) => state.onboardingAddBasicInfo
  );
  const createContact = useSelector((state: any) => state.onboardingAddContact);
  const createAddress = useSelector((state: any) => state.onboardingAddress);
  const createBank = useSelector(
    (state: any) => state.onboardingAddBankDetails
  );

  const editBasicInfo = useSelector(
    (state: any) => state.onboardingEditBasicInfo
  );
  const editContact = useSelector((state: any) => state.onboardingEditContact);
  const editAddress = useSelector((state: any) => state.onboardingEditAddress);
  const editBank = useSelector((state: any) => state.onboardingEditBankDetails);
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  const [basicInfo, setBasicInfo] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<object | null>(null);
  const [AddressInfo, setAddressInfo] = useState<object | null>(null);
  const [BankDetail, setBankDetail] = useState<boolean>(false);
  const [CompletePopup, setCompletePopup] = useState<boolean>(false);
  const [CheckSaveErrors, SetCheckSaveErrors] = useState<boolean>(false);
  console.log(">>Check Error on Save and Proceed ", CheckSaveErrors);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "2") {
      if (!(basicInfoData.isCompleted && basicInfo && !CheckSaveErrors)) {
        console.error("Basic info is incomplete");
        validate.current.click();
        return;
      }
    }

    if (newValue === "3") {
      if (!(ContactInfoData.isCompleted && ContactInfoData.data.length !== 0)) {
        console.error("Contact info is incomplete");

        return;
      }
    }

    if (newValue === "4") {
      if (!(AddressInfoData.isCompleted && AddressInfoData.data.length !== 0)) {
        console.error("Address info is incomplete");
        return;
      }
    }

    if (newValue === "5") {
      if (
        !(
          bank_Detail_Data.isCompleted &&
          bank_Detail_Data.data.length !== 0 &&
          !CheckSaveErrors
        )
      ) {
        console.error("Bank detail is incomplete");
        bank_validate.current.click();
        return;
      }
    }

    setValue(newValue);
  };

  // Form submit handler

  const bank_master_data = useSelector((state: any) => state.BankMasterListApi);
  const branch_master_data = useSelector(
    (state: any) => state.ListBranchMaster
  );

  // useEffect

  useEffect(() => {
    if (!basicInfoData.isCompleted) {
      dispatch(raise_api_call_list_partner_one_master(""));
      dispatch(list_branch_master(""));
      dispatch(list_bank_master(""));
      dispatch(get_agreement_from_api(""));
      dispatch(raise_api_call_list_general_setting(""));
      dispatch(raise_api_for_list_onboarding(""));
    } else {
      dispatch(get_agreement_from_api(""));
    }
    if (createBank.isSuccess) {
      // dispatch(add_basic_info_loading_complete(""));
      dispatch(create_bank_is_success(""));
      setCompletePopup(true);
    }
    if (editBank.isSuccess) {
      // dispatch(edit_basic_info_loading_complete(""));
      dispatch(edit_bank_is_success(""));
      setCompletePopup(true);
    }
  }, [verifyed_otp, createBank, editBank]);

  // basic info details
  const basic_info_error = (e: object) => {
    setBasicInfo(true);
    setValue("2");
  };
  const validate = createRef<any>();
  // contact info details
  const contact_info_error = (e: object) => {
    setContactInfo(e);
  };
  const address_info_error = (e: object) => {
    setAddressInfo(e);
  };
  const bank_detail_error = (e: object) => {
    setBankDetail(true);
    setValue("5");
  };
  const contact_validate = createRef<any>();
  const address_validate = createRef<any>();
  const bank_validate = createRef<any>();
  const [requestForClarification, setRequestForClarification] =
    React.useState(false);
  const openRequestClarification = () => {
    setRequestForClarification(true);
  };
  const CloseRequestClarification = () => {
    setRequestForClarification(false);
    setLoad(false);
  };

  const validationSchema = Yup.object().shape({
    about: Yup.string().required("reson for clarification is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      about: "",
    },
    validationSchema: validationSchema,
    onSubmit: (a: any) => {
      submit(a);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;

  const submit = (e: any) => {
    const data = {
      id:
        PartnerMasterList.data.length != 0
          ? PartnerMasterList.data.result[0].id
          : "",
      description: e.about,
      status: "Clarification Submitted",
    };
    dispatch(submit_clarification(data));
    if (basicInfoData.isFetchedData) {
      dispatch(raise_api_call_edit_basic_info(clarificationData));
    } else {
      dispatch(raise_api_call_add_basicInfo(clarificationData));
    }
  };
  //(
  //   agreementData
  //     ? agreementData.data.length === 0
  //       ? false
  //       : agreementData.data.result.length != 0
  //       ? agreementData.data.result
  //       : false
  //     : false
  // );
  //

  const validateForm = () => {
    const isBasicInfoComplete = basicInfoData.isCompleted;
    const isContactInfoComplete = ContactInfoData.isCompleted;
    const isAddressInfoComplete = AddressInfoData.isCompleted;
    const isBankDetailComplete = bank_Detail_Data.isCompleted;
    const isOtpVerified = verifyed_otp.isVerified;

    const isAgreementVerified = agreementData
      ? agreementData.data.length === 0
        ? false
        : agreementData.data.result.length !== 0
        ? agreementData.data.result.Verify
        : false
      : false;

    return (
      (isBasicInfoComplete &&
        isContactInfoComplete &&
        isAddressInfoComplete &&
        isBankDetailComplete &&
        isOtpVerified) ||
      isAgreementVerified
    );
  };

  const handleOkClick = (event: any) => {
    event.preventDefault();
    setCompletePopup(false);
    if (window.location.pathname !== "/login") {
      window.location.href = "/login"; // Directly setting window location
    }
  };

  const booll =
    generalSettingList.isLoading ||
    bank_master_data.isLoading ||
    branch_master_data.isLoading ||
    createBasicInfo.isLoading ||
    editBasicInfo.isLoading ||
    createContact.isLoading ||
    createAddress.isLoading ||
    createBank.isLoading ||
    editContact.isLoading ||
    editAddress.isLoading ||
    editBank.isLoading
      ? true
      : false;

  console.log("basicInfoData.isFetchedData", basicInfoData.isFetchedData);
  const check = load
    ? basicInfoData.isFetchedData
      ? editBank.isCompleted == false
        ? true
        : false
      : createBank.isCompleted == false
      ? true
      : false
    : booll;
  return (
    <>
      <div className="d-flex flex-column h-100">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            check
            // true
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className=" h-auto ">
          <div className="onboarding">
            <h3 className="ms-5">Fill these information</h3>
          </div>
          <div className="mx-5">
            <Box sx={{ width: "100%", marginBottom: "173px" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeTab}
                    className="w-100"
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        fontWeight: 700,
                        width: "20%",
                      }}
                      label="Basic Info"
                      value="1"
                    />

                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "20%",
                        fontWeight: 700,
                      }}
                      label="Contact Person"
                      value="2"
                    />

                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "20%",
                        fontWeight: 700,
                      }}
                      label="Address"
                      value="3"
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "20%",
                        fontWeight: 700,
                      }}
                      label="Bank Details"
                      value="4"
                    />

                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        width: "20%",
                        fontWeight: 700,
                      }}
                      label="Agreement"
                      value="5"
                    />
                  </TabList>
                </Box>
                <TabPanel className="p-0 pt-4" value="1">
                  <BasicInfo
                    validate={validate}
                    branch_master_data={branch_master_data}
                    partnerOneList={partnerOneList}
                    basic_info_error={basic_info_error}
                    BasicInfoRep={BasicInfoRep}
                    setGstFillingStatus={setGstFillingStatus}
                    PartnerMasterList={PartnerMasterList}
                    basicInfoData={basicInfoData}
                    generalSettingList={generalSettingList}
                    SetCheckSaveErrors={SetCheckSaveErrors}
                  />
                </TabPanel>
                <TabPanel className="p-0 pt-4" value="2">
                  <ContactInfo
                    contact_validate={contact_validate}
                    contact_info_error={contact_info_error}
                    ContactInfoData={ContactInfoData}
                    generalSettingList={generalSettingList}
                  />
                </TabPanel>

                <TabPanel className="p-0 pt-4" value="3">
                  <AddressDetail
                    address_validate={address_validate}
                    AddressInfoData={AddressInfoData}
                    PartnerMasterList={PartnerMasterList}
                    gst_filling_status={gst_filling_status}
                    generalSettingList={generalSettingList}
                    address_info_error={address_info_error}
                  />
                </TabPanel>
                <TabPanel className="p-0 pt-4" value="4">
                  <BankDetails
                    bank_master_data={bank_master_data}
                    bank_Detail_Data={bank_Detail_Data}
                    bank_detail_error={bank_detail_error}
                    generalSettingList={generalSettingList}
                    bank_validate={bank_validate}
                    SetCheckSaveErrors={SetCheckSaveErrors}
                  />
                </TabPanel>

                <TabPanel className="p-0 pt-4" value="5">
                  <Aggrement
                    agreementData={agreementData}
                    verifyed_otp={verifyed_otp}
                    setSecondNumber={setSecondNumber}
                    firstNumber={firstNumber}
                    thirdNumber={thirdNumber}
                    PartnerMasterList={PartnerMasterList}
                    secondNumber={secondNumber}
                    setFirstNumber={setFirstNumber}
                    setFifthNumber={setFifthNumber}
                    setThirdNumber={setThirdNumber}
                    fifthNumber={fifthNumber}
                    forthNumber={forthNumber}
                    setForthNumber={setForthNumber}
                    setScrolledPage={setScrolledPage}
                    scrolledPage={scrolledPage}
                    submitData={bank_master_data}
                    setSubmitData={setSubmitData}
                    addressdata={AddressInfoData}
                    contactdata={ContactInfoData}
                    bankdata={bank_Detail_Data}
                    basicInfoCompleted={basicInfoData.isCompleted}
                    contactInfoCompleted={ContactInfoData.isCompleted}
                    addressInfoCompleted={AddressInfoData.isCompleted}
                    bankDetailCompleted={bank_Detail_Data.isCompleted}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
        <div className="add-onboarding-buttons">
          <div className="botom-navigation d-flex justify-content-end align-items-center ">
            <div className="next-and-back">
              {value !== "1" ? (
                <div
                  onClick={() => {
                    setValue(String(Number(value) - 1));
                  }}
                  className="back"
                >
                  Back
                </div>
              ) : (
                ""
              )}
              {value !== "5" ? (
                <div
                  onClick={() => {
                    if (value === "1") {
                      if (
                        (basicInfoData.isCompleted &&
                          basicInfo &&
                          !CheckSaveErrors) ||
                        (basicInfo && !CheckSaveErrors)
                      ) {
                        setValue(String(Number(value) + 1));
                      }
                      validate.current.click();
                    }

                    if (value === "2") {
                      if (
                        ContactInfoData.isCompleted &&
                        ContactInfoData.data.length !== 0
                      ) {
                        setValue(String(Number(value) + 1));
                      }
                      // contact_validate.current.click();
                    }
                    if (value === "3") {
                      if (
                        AddressInfoData.isCompleted &&
                        AddressInfoData.data.length !== 0
                      ) {
                        setValue(String(Number(value) + 1));
                      }
                      // address_validate.current.click();
                    }
                    if (value === "4") {
                      if (
                        bank_Detail_Data.isCompleted &&
                        bank_Detail_Data.data.length !== 0 &&
                        !CheckSaveErrors
                      ) {
                        setSubmitForm(true);
                        setValue(String(Number(value) + 1));
                      }
                      bank_validate.current.click();
                    }
                  }}
                  style={{
                    width: "200px",
                  }}
                  className="next"
                >
                  Save & Proceed
                </div>
              ) : (
                <div
                  className={
                    (basicInfoData.isCompleted &&
                      ContactInfoData.isCompleted &&
                      AddressInfoData.isCompleted &&
                      bank_Detail_Data.isCompleted &&
                      verifyed_otp.isVerified) ||
                    (agreementData
                      ? agreementData.data.length === 0
                        ? false
                        : agreementData.data.result.length !== 0
                        ? agreementData.data.result.Verify
                        : false
                      : false)
                      ? "next"
                      : "notSubmit"
                  }
                  onClick={() => {
                    setLoad(true);
                    if (
                      (basicInfoData.isCompleted &&
                        basicInfoData.data.length != 0 &&
                        ContactInfoData.isCompleted &&
                        ContactInfoData.data.length != 0 &&
                        AddressInfoData.isCompleted &&
                        AddressInfoData.data.length != 0 &&
                        bank_Detail_Data.isCompleted &&
                        bank_Detail_Data.data.length != 0 &&
                        verifyed_otp.isVerified) ||
                      (agreementData
                        ? agreementData.data.length === 0
                          ? false
                          : agreementData.data.result.length !== 0
                          ? agreementData.data.result.Verify
                          : false
                        : false)
                    ) {
                      const basicInfoFormData: any = new FormData();
                      basicInfoFormData.append("id", basicInfoData.data.id);
                      basicInfoFormData.append(
                        "company_name",
                        basicInfoData.data.company_name
                      );
                      // const branches: any = [];
                      // basicInfoData.data.branch.forEach((a: any) => {
                      //   if (a.value) {
                      //     branches.push(a.id);
                      //   }
                      // });
                      // basicInfoFormData.append("branch[]", branches);
                      basicInfoFormData.append(
                        "pan_no",
                        basicInfoData.data.pan_no
                      );
                      basicInfoFormData.append(
                        "foreign_name",
                        basicInfoData.data.alternate_name
                      );
                      basicInfoFormData.append("msme", basicInfoData.data.msme);
                      basicInfoFormData.append(
                        "msme_type",
                        basicInfoData.data.msme_type
                      );
                      basicInfoFormData.append(
                        "msme_certificate_file",
                        basicInfoData.data.msme_certificate
                      );

                      basicInfoFormData.append(
                        "pancard_certificate_file",
                        basicInfoData.data.pancard_certificate
                      );
                      basicInfoFormData.append(
                        "business_type",
                        basicInfoData.data.business_type
                      );
                      basicInfoFormData.append(
                        "msme_no",
                        basicInfoData.data.msme_no
                      );
                      basicInfoFormData.append(
                        "gst_filling_status",
                        basicInfoData.data.gst_filling_status
                      );
                      basicInfoFormData.append(
                        "gst_type",
                        basicInfoData.data.gst_type
                      );

                      const contactDetail: any = [];
                      ContactInfoData.data.forEach((e: any) => {
                        const data = {
                          default: e.default,
                          id: e.id,
                          phone: e.phone,
                          title: e.title,
                          email: e.email,
                          country_code: e.country_code,
                          contact_person: e.contact_person,
                        };
                        contactDetail.push(data);
                      });

                      const addressFormData = new FormData();
                      // const addressDetail = AddressInfoData.data;
                      AddressInfoData.data.forEach((a: any, index: any) => {
                        addressFormData.append(
                          "gst_certificate" + index,
                          a.gst_certificate
                        );

                        const data = {
                          id: a.id,
                          address_type: a.address_type,
                          address_title: a.address_title,
                          address_name2: a.address_name2,
                          block: a.block,
                          address_name3: a.address_name3,
                          street_pobox: a.street_pobox,
                          gst_type: a.gst_type,
                          city: a.city,
                          zip_code: a.zip_code,
                          country: a.country,
                          state: a.state,
                          gstin: a.gstin,
                          default: a.default,
                        };

                        addressFormData.append(
                          "address[]",
                          JSON.stringify(data)
                        );
                      });

                      // for (const image of AddressInfoData.data) {
                      //   addressFormData.append("images", a.gst_certificate);
                      // }
                      const bankDetailFormData = new FormData();
                      bankDetailFormData.append("id", bank_Detail_Data.data.id);
                      bankDetailFormData.append(
                        "bank_name",
                        bank_Detail_Data.data.bank_name
                      );
                      bankDetailFormData.append(
                        "bank_code",
                        bank_Detail_Data.data.bank_code
                      );
                      bankDetailFormData.append(
                        "bic_ifsc_swift_code",
                        bank_Detail_Data.data.IFSC_code
                      );

                      bankDetailFormData.append(
                        "account_no",
                        bank_Detail_Data.data.account_number
                      );
                      bankDetailFormData.append(
                        "bank_account_name",
                        bank_Detail_Data.data.bank_account_name
                      );
                      bankDetailFormData.append(
                        "branch",
                        bank_Detail_Data.data.branch
                      );
                      bankDetailFormData.append(
                        "cancelled_cheque_url",
                        bank_Detail_Data.data.cancelled_cheque
                      );
                      for (const value of addressFormData.values()) {
                      }
                      const submitData: any = {
                        basicInfo: basicInfoFormData,
                        contact_info: contactDetail,
                        address_info: addressFormData,
                        bank_detail: bankDetailFormData,
                      };
                      if (
                        partnerOneList.data.length !== 0 &&
                        partnerOneList.data.result[0].status ===
                          "Request For Clarification"
                      ) {
                        openRequestClarification();
                        setClarificationData(submitData);
                      } else {
                        for (var pair of submitData.address_info.entries()) {
                        }
                        if (basicInfoData.isFetchedData) {
                          dispatch(raise_api_call_edit_basic_info(submitData));
                        } else {
                          dispatch(raise_api_call_add_basicInfo(submitData));
                        }
                      }
                    }
                  }}
                >
                  Submit
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        PaperProps={{
          style: { borderRadius: 0, width: "700px" },
        }}
        open={requestForClarification}
        onClose={CloseRequestClarification}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700 pb-0">
            Submission for Clarification
          </DialogTitle>

          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                CloseRequestClarification();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent className="pt-0">
          <div className="input-field pt-0">
            <label>Submission for Clarification</label>
            <TextField
              fullWidth
              id="reason-reject"
              className="reason-reject"
              multiline
              name="about"
              onChange={handleChange}
              value={values.about}
              onBlur={handleBlur}
              error={touched.about && errors.about ? true : false}
              helperText={
                touched.about && errors.about
                  ? (errors.about as ReactNode)
                  : undefined
              }
              sx={{
                height: "80px",
              }}
              inputProps={{
                style: {
                  height: "80px",
                },
              }}
            />
          </div>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="d-flex flex-column">
            <div></div>
            <div className="col-12 d-flex justify-content-center">
              <div
                className="add-more px-3 col-3 d-flex flex-column"
                style={{
                  width: "200px",
                }}
              >
                <div onClick={CloseRequestClarification} className="cancel">
                  Cancel
                </div>
              </div>
              <div
                className="add-more px-3 col-3 d-flex flex-column"
                style={{
                  width: "200px",
                }}
              >
                <div
                  className="add"
                  onClick={() => {
                    // ManagementRejectClose();
                    handleSubmit();
                  }}
                >
                  {SubmitClarification.isLoading || basicInfoData.isLoading
                    ? "Please Wait"
                    : "submit"}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: { borderRadius: 0, width: "700px", height: "270px" },
        }}
        open={CompletePopup}
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex  justify-content-center">
          <div
            style={{
              marginTop: "40px",
              fontSize: "23px",
            }}
            id="alert-dialog-title"
            className="font-weight-700 pb-0 d-flex justify-content-center"
          >
            Partner onboarding form is submitted to account's team
          </div>
        </div>
        <DialogContent className="pt-0">
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="d-flex flex-column">
            <div></div>
            <div className="col-12 d-flex justify-content-center mt-4">
              <div
                className="add-more px-3 col-3 d-flex flex-column "
                style={{
                  width: "200px",
                }}
              >
                <div
                  onClick={handleOkClick}
                  className="cancel d-flex justify-content-center"
                >
                  OK
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddOnBoarding;

// basic info
const BasicInfo = (props: any) => {
  // const [msmeMandatory, setMemeMandatory] = useState<boolean>(true);
  const employee_read_only =
    props.PartnerMasterList.data.length !== 0
      ? props.PartnerMasterList.data.result.length !== 0
        ? props.PartnerMasterList.data.result[0].partner_type === "V" &&
          props.PartnerMasterList.data.result[0].group_name === "162"
        : true
      : false;
  const validationSchema = Yup.object().shape({
    company_name: Yup.string()
      .max(100, "maximum 100 character")
      .required("Company Name Is Required"),
    alternate_name: Yup.string()
      .max(100, "maximum 100 character")
      .required("Alternate Name Is Required"),
    business_type: Yup.string().required("Business Type  Is Required"),
    pan_no: employee_read_only
      ? Yup.mixed().notRequired()
      : Yup.mixed()
          .required("Pan No  Is Required")
          .test("panNumber", "invalid pan number", (value: any) => {
            let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

            if (regex.test(value)) {
              return true;
            }
            return false;
          }),
    msme: Yup.boolean(),
    msme_type: Yup.string().when("msme", {
      is: (value: boolean) => value === true,
      then: Yup.string().required("MSME Type is Required"),
    }),

    msme_no: Yup.string().when("msme", {
      is: (value: boolean) => value === true,
      then: Yup.string()
        .max(25, "maximum 25 character")
        .required("msme number is required"),
      otherwise: Yup.string().notRequired(),
    }),
    gst_type: Yup.string().required("GST Type Required"),
    gst_filling_status: Yup.boolean().notRequired(),
    msme_certificate: Yup.mixed().when("msme", {
      is: (value: boolean) => value == true,
      then: Yup.mixed()
        .test(
          "MSME Certificate Required",
          "Unsupported Format - We only allow jpg,pdf,png,jpeg .",
          (value) => {
            if (value.type) {
              if (SUPPORTED_FORMATS.includes(value.type)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        )
        .test(
          "MSME Certificate required",
          "MSME Certificate Required",
          (value: any) => {
            console.log("value", value);

            // if (basicInfoData.isCompleted) {
            //   return true;
            // }
            if (value.name) {
              return true;
            } else {
              return false;
            }
          }
        )
        .test("file size", "maximum file size 1MB", (value) => {
          const fileSize = value.size / 1024 / 1024;
          if (value.type) {
            if (fileSize <= 1) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })
        .required("required msme certificate"),
      otherwise: Yup.mixed()
        // .test(
        //   "Cancelled Cheque Required",
        //   "Unsupported Format - We only allow images.",
        //   (value) => SUPPORTED_FORMATS.includes(value.type)
        // )
        .nullable()
        .notRequired(),
    }),
    pancard_certificate: Yup.mixed()
      .required("PAN card certificate is required")
      .test(
        "Pancard is required",
        "Unsupported Format - We only allow jpg,pdf,png,jpeg .",
        (value) => {
          if (value.type) {
            if (SUPPORTED_FORMATS.includes(value.type)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      )
      .test(
        "PANCARD Certificate required",
        "PANCARD Certificate Required",
        (value: any) => {
          if (basicInfoData.isCompleted) {
            return true;
          }
          if (value.name) {
            return true;
          } else {
            return false;
          }
        }
      )
      .test("file size", "maximum file size 1MB", (value) => {
        const fileSize = value.size / 1024 / 1024;
        if (value.type) {
          if (fileSize <= 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .required("required pancard certificate"),
    otherwise: Yup.mixed()
      // .test(
      //   "Cancelled Cheque Required",
      //   "Unsupported Format - We only allow images.",
      //   (value) => SUPPORTED_FORMATS.includes(value.type)
      // )
      .nullable()
      .notRequired(),
  });

  type basicData = {
    id: any;
    branch: any;
    company_name: string;
    alternate_name: string;
    business_type: string;
    pan_no: string;
    msme: boolean;
    gst_type: string;
    gst_filling_status: boolean;
    msme_type: string;
    msme_no: string;
    msme_certificate: any;
    pancard_certificate: any;
  };

  const basicInfoData: { data: basicData; isCompleted: boolean } =
    props.basicInfoData;

  // const updateData={
  //   company_name:(props.partnerOneList.data.length != 0
  //   ? props.partnerOneList.data.result[0].businessPartner.length != 0
  //     ? props.partnerOneList.data.result[0].businessPartner[0]
  //         .company_name
  //     :"")
  // }
  const company_name: string =
    props.PartnerMasterList.data.length !== 0
      ? props.PartnerMasterList.data.result.length !== 0
        ? props.PartnerMasterList.data.result[0].company_name
        : ""
      : "";
  const alter_name: string =
    props.PartnerMasterList.data.length !== 0
      ? props.PartnerMasterList.data.result.length !== 0
        ? props.PartnerMasterList.data.result[0].foreign_name
        : ""
      : "";
  const type_of_business: string =
    props.PartnerMasterList.data.length !== 0
      ? props.PartnerMasterList.data.result.length !== 0
        ? props.PartnerMasterList.data.result[0].bussiness_type
          ? props.PartnerMasterList.data.result[0].bussiness_type
          : ""
        : ""
      : "";

  const basic_info_formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: basicInfoData.isCompleted ? basicInfoData.data.id : "",
      branch: basicInfoData.isCompleted ? basicInfoData.data.branch : [],
      company_name: basicInfoData.isCompleted
        ? basicInfoData.data.company_name
        : company_name,
      alternate_name: basicInfoData.isCompleted
        ? basicInfoData.data.alternate_name
        : alter_name,
      business_type: basicInfoData.isCompleted
        ? basicInfoData.data.business_type
        : type_of_business,
      pan_no: basicInfoData.isCompleted ? basicInfoData.data.pan_no : "",
      gst_filling_status: basicInfoData.isCompleted
        ? basicInfoData.data.gst_filling_status
        : !employee_read_only,
      gst_type: employee_read_only
        ? "UR"
        : basicInfoData.isCompleted
        ? basicInfoData.data.gst_type
        : "",
      msme: basicInfoData.isCompleted
        ? basicInfoData.data.msme
        : !employee_read_only,
      msme_type: basicInfoData.isCompleted ? basicInfoData.data.msme_type : "",
      msme_no: basicInfoData.isCompleted ? basicInfoData.data.msme_no : "",
      msme_certificate: basicInfoData.isCompleted
        ? basicInfoData.data.msme_certificate
        : {
            name: "",
          },
      pancard_certificate: basicInfoData.isCompleted
        ? basicInfoData.data.pancard_certificate
        : {
            name: "",
          },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = basic_info_formik;
  useEffect(() => {
    props.SetCheckSaveErrors(
      Object.entries(basic_info_formik.errors).length > 0 ? true : false
    );
  }, [basic_info_formik.errors, props]);

  const dispatch = useDispatch();

  const fileRep = createRef<any>();

  const PanfileRep = createRef<any>();
  const msme_certificate_error: any =
    errors.msme_certificate !== undefined ? errors.msme_certificate : "";
  const pancard_certificate_error: any =
    errors.pancard_certificate !== undefined ? errors.pancard_certificate : "";

  //  type of business options
  const TypeOfBusinessOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "type_of_business") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  // gst filling status
  const GstTypeOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "filling status") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  // msme type
  const MSMEOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "msme_type") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];
  // submit
  const submit = (e: any) => {
    dispatch(submit_basic_info_state(e));
    props.basic_info_error(errors);
  };
  return (
    <>
      <div className="d-flex flex-column">
        <div className="row">
          <div className="col-12 p-0">
            <div className="input-field col-12 px-3">
              <label>
                Branch <span>*</span>
              </label>

              <div className="d-flex align-items-center set-default-address">
                <div className=" d-flex align-items-center ">
                  {props.partnerOneList.data.length !== 0
                    ? props.partnerOneList.data.result[0].branch.map(
                        (a: any, index: number) => {
                          return (
                            <>
                              <div
                                style={{
                                  margin: "0px 10px",
                                }}
                              >
                                <img
                                  src={configs.api + "/" + a.branch_logo}
                                  style={{
                                    maxHeight: "50px",
                                    maxWidth: "200px",
                                  }}
                                  alt="branch"
                                ></img>
                              </div>
                            </>
                          );
                        }
                      )
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-0 mt-3 d-flex">
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>
                Company Name <span>*</span>
              </label>
              <TextField
                placeholder="Company Name"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  borderRadius: "0px",
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company_name}
                error={
                  touched.company_name && errors.company_name ? true : false
                }
                helperText={
                  touched.company_name && errors.company_name
                    ? errors.company_name
                    : ""
                }
                name="company_name"
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>
                Alternate Name <span>*</span>
              </label>
              <TextField
                placeholder="Alternate Name"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                }}
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.alternate_name}
                error={
                  touched.alternate_name && errors.alternate_name ? true : false
                }
                helperText={
                  touched.alternate_name && errors.alternate_name
                    ? errors.alternate_name
                    : ""
                }
                name="alternate_name"
              />
            </div>
          </div>
          <div className="col-12 p-0 mt-3 d-flex">
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>
                Type of Business <span>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  height: "50px",
                  borderRadius: "0px",
                }}
                value={
                  TypeOfBusinessOption.filter((e: any) => {
                    return e.value === values.business_type;
                  }).length !== 0
                    ? TypeOfBusinessOption.filter((e: any) => {
                        return e.value === values.business_type;
                      })[0].label
                    : values.business_type
                }
                options={TypeOfBusinessOption}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  // vari(a);

                  setFieldValue("business_type", a);
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: Partial<any>
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.business_type && errors.business_type
                        ? true
                        : false
                    }
                    helperText={
                      touched.business_type && errors.business_type
                        ? errors.business_type
                        : ""
                    }
                    name="business_type"
                    placeholder="Select Type"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>PAN No {!employee_read_only ? <span>*</span> : ""}</label>
              <TextField
                placeholder="PAN No"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                }}
                value={values.pan_no}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.pan_no && errors.pan_no ? true : false}
                helperText={
                  touched.pan_no && errors.pan_no ? errors.pan_no : ""
                }
                name="pan_no"
                inputProps={{
                  style: {
                    height: "20px",
                    textTransform: "uppercase",
                  },
                  readOnly: employee_read_only,
                }}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>
                GST type <span>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  height: "50px",
                }}
                options={GstTypeOption}
                value={
                  GstTypeOption.filter((e: any) => {
                    return e.value === values.gst_type;
                  }).length !== 0
                    ? GstTypeOption.filter((e: any) => {
                        return e.value === values.gst_type;
                      })[0].label
                    : values.gst_type
                }
                readOnly={employee_read_only}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: Partial<any>
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";

                  if (a !== "RG") {
                    setFieldValue("gst_filling_status", false);
                    props.setGstFillingStatus(false);
                  }

                  setFieldValue("gst_type", a);

                  // vari(a);
                }}
                renderInput={(params) => (
                  <TextField
                    onBlur={handleBlur}
                    error={touched.gst_type && errors.gst_type ? true : false}
                    helperText={
                      touched.gst_type && errors.gst_type ? errors.gst_type : ""
                    }
                    name="gst_type"
                    sx={{
                      textTransform: "capitalize",
                    }}
                    placeholder="Select Type"
                    {...params}
                  />
                )}
              />
            </div>
          </div>

          <div className="col-12 p-0 mt-3 d-flex">
            <div className="col-4 mt-4 d-flex align-items-center">
              <FormControlLabel
                label={" GST Filling Status"}
                sx={{
                  fontSize: "0.8vw",
                  color: "",
                }}
                value={values.gst_filling_status}
                onChange={(e: any) => {
                  if (values.gst_type == "RG") {
                    setFieldValue("gst_filling_status", e.target.checked);
                    props.setGstFillingStatus(e.target.checked);
                  }
                }}
                className="ms-3"
                control={
                  <Checkbox
                    name="gst_filling_status"
                    checked={values.gst_filling_status ? true : false}
                  />
                }
              />
            </div>
            <div className="col-4 mt-4 d-flex align-items-center">
              <FormControlLabel
                label={"MSME"}
                sx={{
                  fontSize: "0.8vw",
                  color: "",
                }}
                value={values.msme}
                onChange={(e: any) => {
                  if (!employee_read_only) {
                    setFieldValue("msme", e.target.checked);
                  }
                }}
                className="ms-3"
                control={
                  <Checkbox name="msme" checked={values.msme ? true : false} />
                }
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>MSME Type {values.msme ? <span>*</span> : ""}</label>

              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  height: "50px",
                }}
                value={
                  MSMEOption.filter((e: any) => {
                    return e.value === values.msme_type;
                  }).length !== 0
                    ? MSMEOption.filter((e: any) => {
                        return e.value === values.msme_type;
                      })[0].label
                    : values.msme_type
                }
                readOnly={!values.msme ? true : false}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: Partial<any>
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                options={MSMEOption}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  // vari(a);
                  setFieldValue("msme_type", a);
                }}
                renderInput={(params) => (
                  <TextField
                    onBlur={handleBlur}
                    error={touched.msme_type && errors.msme_type ? true : false}
                    helperText={
                      touched.msme_type && errors.msme_type
                        ? errors.msme_type
                        : ""
                    }
                    name="msme_type"
                    sx={{
                      textTransform: "capitalize",
                    }}
                    placeholder="Select Type"
                    {...params}
                  />
                )}
              />
            </div>
          </div>
          <div className="input-field px-3 col-4 my-4 d-flex flex-column">
            <label>MSME No {values.msme ? <span>*</span> : ""}</label>
            <TextField
              value={values.msme_no}
              onChange={handleChange}
              onBlur={handleBlur}
              name="msme_no"
              placeholder="MSME No"
              sx={{
                "& legend": { display: "none" },
                "& fieldset": {
                  top: 0,
                  border: "2px solid #c4c4c4",
                  borderRadius: "0px",
                },
                // borderRadius: "0px",
                fontWeight: "700",
                fontFamily: "Roboto",
              }}
              error={touched.msme_no && errors.msme_no ? true : false}
              helperText={
                touched.msme_no && errors.msme_no ? errors.msme_no : ""
              }
              inputProps={{
                style: {
                  height: "20px",
                },
                readOnly: !values.msme ? true : false,
              }}
            />
          </div>
          <div className="input-field px-3 col-4 my-4 d-flex flex-column">
            <label>MSME Certificate {values.msme ? <span>*</span> : ""}</label>
            <div
              className={
                errors.msme_certificate && touched.msme_certificate
                  ? "msme-certificate-require"
                  : "msme-certificate"
              }
              onClick={() => {
                if (values.msme) {
                  fileRep.current.click();
                }
              }}
            >
              <FontAwesomeIcon
                className="ms-50"
                icon={["fas", "paperclip"]}
                style={{
                  fontSize: "1vw",
                  marginLeft: "10px",
                  marginRight: "10px",
                  color: "#908f8f",
                }}
              />

              <label>
                {values.msme_certificate.name == ""
                  ? "Upload MSME Certificate"
                  : values.msme_certificate.name}
              </label>
            </div>
            <span
              className={
                errors.msme_certificate && touched.msme_certificate
                  ? "text-danger"
                  : ""
              }
            >
              {errors.msme_certificate && touched.msme_certificate ? (
                msme_certificate_error
              ) : values.msme_certificate.type ? (
                <span
                  style={{
                    color: "#09b909",
                    fontSize: "10px",
                  }}
                >
                  File attached
                </span>
              ) : (
                "Supported file(.pdf,.jpg,.png) && maximum file size 1MB"
              )}
            </span>
            <TextField
              inputRef={fileRep}
              type="file"
              name="msme_certificate"
              onChange={(e: any) => {
                setFieldValue("msme_certificate", e.target.files[0]);
                setFieldTouched("msme_certificate", true, false);
              }}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": {
                  top: 0,
                  border: "2px solid #c4c4c4",
                  borderRadius: "0px",
                },
                // borderRadius: "0px",
                fontWeight: "700",
                fontFamily: "Roboto",
                display: "none",
              }}
              inputProps={{
                style: {
                  height: "20px",
                },
              }}
            />
          </div>
          <div className="input-field px-3 col-4 my-4 d-flex flex-column">
            <label>
              PANCARD Certificate {!employee_read_only ? <span> * </span> : ""}
            </label>
            <div
              className={
                errors.pancard_certificate && touched.pancard_certificate
                  ? "pancard_certificate-require"
                  : "pancard_certificate"
              }
              onClick={() => {
                if (values.pancard_certificate) {
                  PanfileRep.current.click();
                }
              }}
            >
              <FontAwesomeIcon
                className="ms-50"
                icon={["fas", "paperclip"]}
                style={{
                  fontSize: "1vw",
                  marginLeft: "10px",
                  marginRight: "10px",
                  color: "#908f8f",
                }}
              />
              <label>
                {values.pancard_certificate?.name === ""
                  ? "Upload PANCARD Certificate"
                  : values.pancard_certificate?.name}
              </label>
            </div>
            <span
              className={
                errors.pancard_certificate && touched.pancard_certificate
                  ? "text-danger"
                  : ""
              }
            >
              {errors.pancard_certificate && touched.pancard_certificate ? (
                pancard_certificate_error
              ) : values.pancard_certificate?.type ? (
                <span
                  style={{
                    color: "#09b909",
                    fontSize: "10px",
                  }}
                >
                  File attached
                </span>
              ) : (
                "Supported file (.pdf, .jpg, .png) & maximum file size 1MB"
              )}
            </span>
            <TextField
              inputRef={PanfileRep}
              placeholder="Pancard Certificate"
              type="file"
              name="pancard_certificate"
              onChange={(e: any) => {
                setFieldValue("pancard_certificate", e.target.files[0]);
                setFieldTouched("pancard_certificate", true, false);
              }}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": {
                  top: 0,
                  border: "2px solid #c4c4c4",
                  borderRadius: "0px",
                },
                fontWeight: "700",
                fontFamily: "Roboto",
                display: "none",
              }}
              inputProps={{
                style: {
                  height: "20px",
                },
              }}
            />
          </div>
          <div
            ref={props.validate}
            onClick={() => {
              handleSubmit();
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

// contact info

const ContactInfo = (props: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<{
    id: any;
    default: boolean;
    contact_person: string;
    title: string;
    phone: string;
    email: string;
    country_code: string;
    index: number;
  }>({
    id: "",
    default: true,
    contact_person: "",
    title: "",
    phone: "",
    email: "",
    country_code: "+91",
    index: 0,
  });
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    contact_person: Yup.string().max(50).required("Contact Person Is Required"),
    title: Yup.string().max(50).required("Title Is Required"),
    phone: Yup.string()
      .required("Phone Number  Is Required")
      .max(50)
      .min(8)
      .matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string()
      .required("Email  Is Required")
      .max(100)
      .email("Email is invalid"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: isEdit ? editData.id : "",
      default: isEdit ? editData.default : true,
      contact_person: isEdit ? editData.contact_person : "",
      title: isEdit ? editData.title : "",
      phone: isEdit ? editData.phone : "",
      email: isEdit ? editData.email : "",
      country_code: isEdit ? editData.country_code : "+91",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  // console.log(errors);

  let titleCheck = props.ContactInfoData.data
    .map((item: any) => item.title.toLowerCase()) // Convert titles to lowercase
    .includes(values.title.toLowerCase()); // Check if values.title exists in lowercase

  const submit = (e: {
    id: string;
    default: boolean;
    contact_person: string;
    title: string;
    phone: string;
    email: string;
    country_code: string;
  }) => {
    const data = [...props.ContactInfoData.data];

    if (titleCheck) {
      // Show toast notification to the user
      toast.error(
        "TitleName already exists. Please choose a different titlename."
      );
      return; // Stop further execution of the function
    }
    if (isEdit) {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });
      filterData[editData.index] = e;
      dispatch(submit_contact_info_state(filterData));

      setIsEdit(false);
    } else {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });

      filterData.push(e);
      dispatch(submit_contact_info_state(filterData));
      setIsEdit(false);
    }
    resetForm();
    handleClose();
  };
  const [age, setAge] = React.useState(+91);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChanges = (event: any) => {
    setAge(event.target.value);
  };
  const handle = (e: string) => {};

  // submit values
  // const submit = (e: {
  //   id: string;
  //   default: boolean;
  //   contact_person: string;
  //   title: string;
  //   phone: string;
  //   email: string;
  //   country_code: string;
  // }) => {
  //   const data = [...props.ContactInfoData.data];
  //   if (isEdit) {
  //     const filterData = data.map((value: any) => {
  //       const a = { ...value };
  //       if (e.default) {
  //         if (a.default) {
  //           a.default = false;
  //           return a;
  //         } else {
  //           return a;
  //         }
  //       } else {
  //         return a;
  //       }
  //     });
  //     filterData[editData.index] = e;
  //     dispatch(submit_contact_info_state(filterData));

  //     setIsEdit(false);
  //   } else {
  //     const filterData = data.map((value: any) => {
  //       const a = { ...value };
  //       if (e.default) {
  //         if (a.default) {
  //           a.default = false;
  //           return a;
  //         } else {
  //           return a;
  //         }
  //       } else {
  //         return a;
  //       }
  //     });

  //     filterData.push(e);
  //     dispatch(submit_contact_info_state(filterData));
  //     setIsEdit(false);
  //   }
  //   resetForm();
  //   handleClose();
  // };

  // mainTable data
  const mainTableData: any = [];
  props.ContactInfoData.data.forEach((e: any, key: number) => {
    const data = {
      default: {
        value: e.default ? "Yes" : "",
      },
      Name: {
        value: e.contact_person,
      },
      Title: {
        value: e.title,
      },
      phone_no: {
        value: e.phone,
      },
      email_id: {
        value: e.email,
      },
      action: {
        value: (
          <div
            className="d-flex table-edit-icon"
            style={{
              width: "23px",
              textAlign: "center",
            }}
          >
            <div
              className=" edit"
              onClick={() => {
                setIsEdit(true);
                let data = {
                  ...e,
                };
                data.index = key;
                setEditData(data);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
            </div>
            <div
              className=" add"
              onClick={() => {
                setIsEdit(false);
                handleClickOpen();
                resetForm();
              }}
            >
              <FontAwesomeIcon icon={["fas", "plus"]} />
            </div>
          </div>
        ),
      },
    };
    mainTableData.push(data);
  });
  const country_code = COUNTRIES.map((e: any) => {
    const data = { label: e.mobileCode, value: e.mobileCode };
    return data;
  });
  return (
    <>
      {props.ContactInfoData.isCompleted && mainTableData.length != 0 ? (
        <MainTable add={handle} mainTableData={mainTableData} />
      ) : (
        <div className="d-flex justify-content-end align-items-center">
          <div
            className="add-contact"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Contact
          </div>
        </div>
      )}

      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700">
            {"Add Contact"}{" "}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="d-flex flex-column">
            <div className="row address-types mb-3">
              <div className="d-flex">
                <div className="d-flex align-items-center set-default-address">
                  <Checkbox
                    name="default"
                    checked={values.default ? true : false}
                    onChange={(e: any) => {
                      setFieldValue("default", e.target.checked);
                    }}
                  />
                  <label>Set to Default</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="input-field px-3 col-6 d-flex flex-column">
                <label>
                  Name <span>*</span>
                </label>
                <TextField
                  placeholder="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_person}
                  error={
                    touched.contact_person && errors.contact_person
                      ? true
                      : false
                  }
                  helperText={
                    touched.contact_person && errors.contact_person
                      ? errors.contact_person
                      : ""
                  }
                  name="contact_person"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 col-6 d-flex flex-column">
                <label>
                  Title <span>*</span>
                </label>
                <TextField
                  placeholder="Title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={touched.title && errors.title ? true : false}
                  helperText={touched.title && errors.title ? errors.title : ""}
                  name="title"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <label>
                  Phone No <span>*</span>
                </label>
                <TextField
                  placeholder=""
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={touched.phone && errors.phone ? true : false}
                  helperText={touched.phone && errors.phone ? errors.phone : ""}
                  name="phone"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          value={values.country_code}
                          onChange={(e) => {
                            setFieldValue("country_code", `${e.target.value}`);
                          }}
                          name="country_code"
                          // displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            borderRadius: "0px",
                            height: "50px",
                            width: "100%",
                          }}
                        >
                          {COUNTRIES.map((e: any) => {
                            return (
                              <MenuItem value={e.mobileCode}>
                                {e.mobileCode}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "50px",
                      padding: "0px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <label>
                  Email Id <span>*</span>
                </label>
                <TextField
                  type="email"
                  placeholder="Email Id"
                  onChange={(e: any) => {
                    setFieldValue("email", e.target.value.toLowerCase());
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && errors.email ? true : false}
                  helperText={touched.email && errors.email ? errors.email : ""}
                  name="email"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    onClick={() => {
                      handleClose();
                      setIsEdit(false);
                    }}
                    className="cancel"
                  >
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    ref={props.contact_validate}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
//Address Details
const AddressDetail = (props: any) => {
  const employee_read_only =
    props.PartnerMasterList.data.length !== 0
      ? props.PartnerMasterList.data.result.length !== 0
        ? props.PartnerMasterList.data.result[0].partner_type === "V" &&
          props.PartnerMasterList.data.result[0].group_name === "162"
          ? false
          : props.gst_filling_status
        : props.gst_filling_status
      : props.gst_filling_status;
  const [ship_address_same, setShip_address_same] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isAddAddress, setIsAddAddress] = useState<boolean>(true);
  const [stateKey, setStateKey] = useState<string>("");
  const [crtAddType, setCrtAddType] = useState<string>("");
  const [crtTitle, setCrtTitle] = useState<number>();
  const [editData, setEditData] = useState<{
    id: any;
    default: boolean;
    address_type: string;
    address_title: string;
    address_name3: string;
    address_name2: string;
    street_pobox: string;
    block: string;
    city: string;
    zip_code: string;
    country: string;
    state: string;
    gst_type: string;
    gstin: string;
    gst_certificate: any;
    index: number;
  }>({
    id: "",
    default: true,
    address_type: "B",
    address_title: "",
    address_name3: "",
    address_name2: "",
    street_pobox: "",
    block: "",
    city: "",
    zip_code: "",
    country: "",
    state: "",
    gst_type: "",
    gstin: "",
    gst_certificate: {
      name: "",
    },
    index: 0,
  });
  const [copyData, setCopyData] = useState<{
    id: any;
    default: boolean;
    address_type: string;
    address_title: string;
    address_name3: string;
    address_name2: string;
    street_pobox: string;
    block: string;
    city: string;
    zip_code: string;
    country: string;
    state: string;
    gst_type: string;
    gstin: string;
    gst_certificate: any;
    index: number;
  }>({
    id:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].id,
    default:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].default,
    address_type:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].address_type,
    address_title:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].address_title,
    address_name3:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].address_name3,
    address_name2:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].address_name2,
    street_pobox:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].street_pobox,
    block:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].block,
    city:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].city,
    zip_code:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].zip_code,
    country:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].country,
    state:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].state,
    gst_type:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].gst_type,
    gstin:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].gstin,
    gst_certificate:
      props.AddressInfoData.data.length === 0
        ? ""
        : props.AddressInfoData.data[0].gst_certificate,
    index: 0,
  });
  var gstinformat =
    /[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;

  const validationSchema = Yup.object().shape({
    address_title: Yup.string().required("Address Title Is Required"),
    address_name3: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Address Name3 Is Required"),
    address_name2: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Address Name2 Is Required"),
    street_pobox: Yup.string().max(100, "Maximum 100 characters").notRequired(),
    block: Yup.string().max(100, "Maximum 100 characters").notRequired(),
    zip_code: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Zip Code Is Required"),
    country: Yup.string().required("Country Is Required"),
    state: Yup.string().required("State Is Required"),
    city: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("City Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field"),
    gst_type: employee_read_only
      ? Yup.string().required("Gst Type  Is Required")
      : Yup.string().notRequired(),
    gstin: employee_read_only
      ? Yup.mixed()
          .required("GSTIN  Is Required")
          .test("gstvalidate", "invalid gst number", (value: any) => {
            if (gstinformat.test(value)) {
              return true;
            } else {
              return false;
            }
          })
      : Yup.mixed()
          .test("gstvalidate", "invalid gst number", (value: any) => {
            if (value) {
              if (gstinformat.test(value)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          .notRequired(),

    gst_certificate: employee_read_only
      ? Yup.mixed()
          .required("required gst certificate")
          .test(
            "GST Certificate required",
            "Gst Certificate Required",
            (value) => {
              if (value != null) {
                if (isEdit || ship_address_same) {
                  return true;
                }
                if (typeof value != "object" && typeof value === "object") {
                  return true;
                }
                if (value.name) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            }
          )
          .test(
            "MSME Certificate Required",
            "Unsupported Format - We only allow jpg,pdf,png,jpeg .",
            (value) => {
              if (value != null) {
                if (value.type) {
                  if (SUPPORTED_FORMATS.includes(value.type)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
          )
          .test("file size", "maximum file size 1MB", (value) => {
            if (value != null) {
              const fileSize = value.size / 1024 / 1024;
              if (value.type) {
                if (fileSize <= 1) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return false;
            }
          })
      : Yup.mixed()
          .test(
            "MSME Certificate Required",
            "Unsupported Format - We only allow jpg,pdf,png,jpeg .",
            (value) => {
              if (value) {
                if (value.type) {
                  if (SUPPORTED_FORMATS.includes(value.type)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          )
          .test("file size", "maximum file size 1MB", (value) => {
            if (value) {
              const fileSize = value.size / 1024 / 1024;
              if (value.type) {
                if (fileSize <= 1) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return true;
            }
          })
          .notRequired()
          .nullable(),
  });

  const pay_address_length = props.AddressInfoData.data.filter((a: any) => {
    return a.address_type === "B";
  }).length;
  console.log("IsAddAddress>>>>>", isAddAddress);

  const pay_to_address_length: any =
    pay_address_length != 0 && isAddAddress && !isEdit
      ? pay_address_length + 1
      : isAddAddress && isEdit && crtAddType == "B"
      ? crtTitle
      : pay_address_length + 1;

  const ship_add_length = props.AddressInfoData.data.filter((a: any) => {
    return a.address_type === "S";
  }).length;
  const ship_from_address_length: any =
    ship_add_length != 0 && isAddAddress && !isEdit
      ? ship_add_length + 1
      : isAddAddress && isEdit && crtAddType == "S"
      ? crtTitle
      : ship_add_length + 1;

  const address_title_default: any = isEdit
    ? editData.address_type
    : ship_address_same
    ? copyData.address_type
    : "B";

  // AddressInfoData

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: isEdit ? editData.id : ship_address_same ? copyData.id : "",
      default: isEdit
        ? editData.default
        : ship_address_same
        ? copyData.default
        : true,
      address_type: isEdit
        ? editData.address_type
        : ship_address_same
        ? copyData.address_type
        : "B",
      address_title: isEdit
        ? editData.address_title
        : ship_address_same && !setIsAddAddress
        ? copyData.address_title
        : address_title_default == "B"
        ? "Pay To Address" + pay_to_address_length
        : "Ship From Address" + ship_from_address_length,
      address_name3: isEdit
        ? editData.address_name3
        : ship_address_same
        ? copyData.address_name3
        : "",
      address_name2: isEdit
        ? editData.address_name2
        : ship_address_same
        ? copyData.address_name2
        : "",
      street_pobox: isEdit
        ? editData.street_pobox
        : ship_address_same
        ? copyData.street_pobox
        : "",
      block: isEdit ? editData.block : ship_address_same ? copyData.block : "",
      city: isEdit ? editData.city : ship_address_same ? copyData.city : "",
      zip_code: isEdit
        ? editData.zip_code
        : ship_address_same
        ? copyData.zip_code
        : "",
      country: isEdit
        ? editData.country
        : ship_address_same
        ? copyData.country
        : "",
      state: isEdit ? editData.state : ship_address_same ? copyData.state : "",
      gst_type: isEdit
        ? editData.gst_type
        : ship_address_same
        ? copyData.gst_type
        : "",
      gstin: isEdit ? editData.gstin : ship_address_same ? copyData.gstin : "",
      gst_certificate: isEdit
        ? editData.gst_certificate
        : ship_address_same
        ? copyData.gst_certificate
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  // console.log("IsEdit>>>>>>>>", isEdit);
  // console.log("IsAddAddress>>>>>>>>", isAddAddress);
  const crt_add_type = isEdit
    ? editData.address_type
    : ship_address_same
    ? copyData.address_type
    : "B";
  useEffect(() => {
    if (crt_add_type) {
      setCrtAddType(crt_add_type);
    }
  }, [crt_add_type]);
  // setCrtAddType(crt_add_type);
  console.log("AddressType>>>>>", crt_add_type);
  const addressTit = isEdit
    ? editData.address_title
    : ship_address_same && !setIsAddAddress
    ? copyData.address_title
    : address_title_default == "B"
    ? "Pay To Address" + pay_to_address_length
    : "Ship From Address" + ship_from_address_length;

  useEffect(() => {
    if (addressTit) {
      const match = addressTit.match(/\d+/);
      if (match) {
        const addTitNum = match[0];
        setCrtTitle(parseInt(addTitNum));
      } else {
        setCrtTitle(0); // or any default value you prefer
      }
    }
  }, [addressTit]);
  console.log("AddressTitle>>>>>", addressTit);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  console.log(errors);

  const [age, setAge] = React.useState(91);
  const [open, setOpen] = React.useState(false);

  const fileRep = createRef<any>();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShip_address_same(false);
  };
  // submit data
  const submit = (e: any) => {
    const data = [...props.AddressInfoData.data];

    if (isEdit) {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default && a.address_type === e.address_type) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });
      filterData[editData.index] = e;

      dispatch(submit_Address_info_state(filterData));

      setIsEdit(false);
    } else {
      const filterData = data.map((value: any) => {
        const a = { ...value };
        if (e.default) {
          if (a.default && a.address_type === e.address_type) {
            a.default = false;
            return a;
          } else {
            return a;
          }
        } else {
          return a;
        }
      });

      filterData.push(e);
      dispatch(submit_Address_info_state(filterData));

      setIsEdit(false);
    }

    resetForm();
    handleClose();
    setShip_address_same(false);
  };

  // mainTable data
  const mainTableData: any = [];

  props.AddressInfoData.data.forEach((e: any, key: number) => {
    const data = {
      default: {
        value: e.default ? "Yes" : "",
      },
      address_type: {
        value: e.address_type == "B" ? "Pay To " : "Ship From ",
      },
      address_title: {
        value: e.address_title ? e.address_title : "",
      },

      city: {
        value: e.city,
      },
      zip_code: {
        value: e.zip_code,
      },

      GSTIN: {
        value: e.gstin,
      },

      action: {
        value: (
          <div
            className="d-flex table-edit-icon"
            style={{
              width: "23px",
              textAlign: "center",
            }}
          >
            <div
              className=" edit"
              onClick={() => {
                // setIsAddAddress(false);
                setIsEdit(true);
                let data = {
                  ...e,
                };
                data.index = key;
                setEditData(data);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon title="edit" icon={["fas", "pen-to-square"]} />
            </div>
            <div
              className=" edit"
              onClick={() => {
                let data = {
                  ...e,
                };
                data.index = key;
                setShip_address_same(true);
                setCopyData(data);
                setIsEdit(false);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon title="Copy" icon={["fas", "copy"]} />
            </div>
            <div
              className=" add"
              onClick={() => {
                setIsEdit(false);
                handleClickOpen();
              }}
            >
              <FontAwesomeIcon title="Copy" icon={["fas", "plus"]} />
            </div>
          </div>
        ),
      },
    };

    mainTableData.push(data);
  });
  const gst_certificate: any =
    errors.gst_certificate != undefined ? errors.gst_certificate : null;
  //  type of business options
  const country_option =
    props.generalSettingList.data.length != 0
      ? props.generalSettingList.data.status
        ? props.generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "country") {
                return e;
              }
            })
            .sort((a: any, b: any) => {
              return a.description > b.description ? 1 : -1;
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  const state_option =
    props.generalSettingList.data.length != 0
      ? props.generalSettingList.data.status
        ? props.generalSettingList.data.result
            .filter((e: any) => {
              if (e.page === "state") {
                if (e.key === stateKey) {
                  return e;
                }
              }
            })
            .sort((a: any, b: any) => {
              return a.description > b.description ? 1 : -1;
            })
            .map((e: any) => {
              const data = { label: e.description, value: e.name };
              return data;
            })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  //  gst type options
  const GstTypeOption = props.generalSettingList.data.status
    ? props.generalSettingList.data.result
        .filter((e: any) => {
          if (e.page === "gst_type") {
            return e;
          }
        })
        .map((e: any) => {
          const data = { label: e.description, value: e.name };
          return data;
        })
    : [{ label: "No data", value: "no data" }];

  return (
    <>
      {props.AddressInfoData.isCompleted ? (
        props.AddressInfoData.data.length != 0 ? (
          <MainTable mainTableData={mainTableData} />
        ) : (
          <div className="d-flex justify-content-end align-items-center">
            <div
              className="add-contact"
              onClick={() => {
                handleClickOpen();
              }}
            >
              Add Address
            </div>
          </div>
        )
      ) : (
        <div className="d-flex justify-content-end align-items-center">
          <div
            className="add-contact"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Add Address
          </div>
        </div>
      )}
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"xl"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "700",
            }}
          >
            {isEdit ? "Edit Address" : "Add Address"}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
                setIsAddAddress(true);
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="d-flex flex-column">
            <div className="row address-types">
              <div className="d-flex">
                <div className="d-flex align-items-center set-default-address">
                  <Checkbox
                    name="default"
                    checked={values.default ? true : false}
                    onChange={(e: any) => {
                      setFieldValue("default", e.target.checked);
                    }}
                  />
                  <label>Set to Default</label>
                </div>
              </div>
              <div className="col-12 align-items-center mt-1 ps-2 address-types d-flex">
                <div className="col-4 d-flex align-items-center mt-4">
                  <label>Address Type </label>
                  <FormGroup
                    row
                    onChange={(e: any) => {
                      if (e.target.name == "B") {
                        // console.log("address Title Touched PAY", e.target.name);
                        // console.log(
                        //   "Pay Address Length>>>",
                        //   pay_to_address_length
                        // );
                        setFieldValue(
                          "address_title",
                          "Pay To Address" + pay_to_address_length
                        );
                      }
                      if (e.target.name == "S") {
                        // console.log(
                        //   "address Title Touched Ship",
                        //   e.target.name
                        // );
                        // console.log(
                        //   "Ship Address Length>>>",
                        //   ship_from_address_length
                        // );

                        setFieldValue(
                          "address_title",
                          "Ship From Address" + ship_from_address_length
                        );
                      }
                      setFieldValue("address_type", e.target.name);
                    }}
                  >
                    <FormControlLabel
                      label="Pay To"
                      sx={{
                        fontSize: "0.9vw",
                      }}
                      name="B"
                      className="ms-3"
                      control={
                        <Checkbox
                          checked={values.address_type === "B" ? true : false}
                        />
                      }
                    />

                    <FormControlLabel
                      label="Ship Form"
                      sx={{
                        fontSize: "0.8vw",
                      }}
                      name="S"
                      className="ms-3"
                      control={
                        <Checkbox
                          checked={values.address_type === "S" ? true : false}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Address Title <span>*</span>
                </label>
                <TextField
                  placeholder="Address Title"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_title}
                  error={
                    touched.address_title && errors.address_title ? true : false
                  }
                  helperText={
                    touched.address_title && errors.address_title
                      ? errors.address_title
                      : ""
                  }
                  name="address_title"
                  InputProps={{
                    readOnly: true,
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Address Name2 <span>*</span>
                </label>
                <TextField
                  placeholder="Address Name2"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_name2}
                  error={
                    touched.address_name2 && errors.address_name2 ? true : false
                  }
                  helperText={
                    touched.address_name2 && errors.address_name2
                      ? errors.address_name2
                      : ""
                  }
                  name="address_name2"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Address Name3 <span>*</span>
                </label>
                <TextField
                  placeholder="Address Name3"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_name3}
                  error={
                    touched.address_name3 && errors.address_name3 ? true : false
                  }
                  helperText={
                    touched.address_name3 && errors.address_name3
                      ? errors.address_name3
                      : ""
                  }
                  name="address_name3"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>Street / PO Box</label>
                <TextField
                  placeholder="Street"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.street_pobox}
                  error={
                    touched.street_pobox && errors.street_pobox ? true : false
                  }
                  helperText={
                    touched.street_pobox && errors.street_pobox
                      ? errors.street_pobox
                      : ""
                  }
                  name="street_pobox"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>Block</label>
                <TextField
                  placeholder="Block"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.block}
                  error={touched.block && errors.block ? true : false}
                  helperText={touched.block && errors.block ? errors.block : ""}
                  name="block"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  City <span>*</span>
                </label>

                <TextField
                  placeholder="City"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    textTransform: "capitalize",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  onChange={(e: any) => {
                    if (e.target.value) {
                      setFieldValue(
                        "city",
                        e.target.value[0].toUpperCase() +
                          e.target.value.slice(1).toLowerCase()
                      );
                    } else {
                      setFieldValue("city", e.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.city}
                  error={touched.city && errors.city ? true : false}
                  helperText={touched.city && errors.city ? errors.city : ""}
                  name="city"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Zip Code <span>*</span>
                </label>
                <TextField
                  placeholder="Zip Code"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zip_code}
                  error={touched.zip_code && errors.zip_code ? true : false}
                  helperText={
                    touched.zip_code && errors.zip_code ? errors.zip_code : ""
                  }
                  name="zip_code"
                />
              </div>

              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  Country <span>*</span>
                </label>
                <Autocomplete
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    height: "50px",
                    borderRadius: "0px",
                  }}
                  value={
                    country_option.filter((e: any) => {
                      return e.value === values.country;
                    }).length != 0
                      ? country_option.filter((e: any) => {
                          return e.value === values.country;
                        })[0].label
                      : values.country
                  }
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: Partial<any>
                  ) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  options={country_option}
                  onChange={(e, value: any) => {
                    const a = value ? value.value : "";
                    // vari(a);
                    setStateKey(a);
                    setFieldValue("country", a);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onBlur={handleBlur}
                      error={touched.country && errors.country ? true : false}
                      helperText={
                        touched.country && errors.country ? errors.country : ""
                      }
                      name="country"
                      placeholder="Select Type"
                      {...params}
                    />
                  )}
                />
              </div>

              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  State <span>*</span>
                </label>
                <Autocomplete
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    height: "50px",
                    borderRadius: "0px",
                  }}
                  value={
                    state_option.filter((e: any) => {
                      return e.value === values.state;
                    }).length != 0
                      ? state_option.filter((e: any) => {
                          return e.value === values.state;
                        })[0].label
                      : values.state
                  }
                  options={state_option}
                  onChange={(e, value: any) => {
                    const a = value ? value.value : "";
                    // vari(a);

                    setFieldValue("state", a);
                  }}
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: Partial<any>
                  ) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onBlur={handleBlur}
                      error={touched.state && errors.state ? true : false}
                      helperText={
                        touched.state && errors.state ? errors.state : ""
                      }
                      name="state"
                      placeholder="Select Type"
                      {...params}
                    />
                  )}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  GST Type {employee_read_only ? <span>*</span> : ""}
                </label>
                <Autocomplete
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    height: "50px",
                    borderRadius: "0px",
                  }}
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: Partial<any>
                  ) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  readOnly={employee_read_only ? false : true}
                  value={
                    GstTypeOption.filter((e: any) => {
                      return e.value === values.gst_type;
                    }).length != 0
                      ? GstTypeOption.filter((e: any) => {
                          return e.value === values.gst_type;
                        })[0].label
                      : values.gst_type
                  }
                  options={GstTypeOption}
                  onChange={(e, value: any) => {
                    const a = value ? value.value : "";
                    // vari(a);

                    setFieldValue("gst_type", a);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onBlur={handleBlur}
                      error={touched.gst_type && errors.gst_type ? true : false}
                      helperText={
                        touched.gst_type && errors.gst_type
                          ? errors.gst_type
                          : ""
                      }
                      name="gst_type"
                      placeholder="Select Type"
                      {...params}
                    />
                  )}
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>GSTIN {employee_read_only ? <span>*</span> : ""}</label>
                <TextField
                  placeholder="GSTIN"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      textTransform: "uppercase",
                    },
                    readOnly: employee_read_only ? false : true,
                  }}
                  onChange={(e: any) => {
                    setFieldValue("gstin", e.target.value.toUpperCase());
                  }}
                  onBlur={handleBlur}
                  value={values.gstin}
                  error={touched.gstin && errors.gstin ? true : false}
                  helperText={touched.gstin && errors.gstin ? errors.gstin : ""}
                  name="gstin"
                />
              </div>
              <div className="input-field px-3 mt-2 col-4 d-flex flex-column">
                <label>
                  GST Certificate {employee_read_only ? <span>*</span> : ""}
                </label>
                <div
                  className={
                    errors.gst_certificate && touched.gst_certificate
                      ? "msme-certificate-require"
                      : "msme-certificate"
                  }
                  onClick={() => {
                    if (employee_read_only) {
                      fileRep.current.click();
                    }
                  }}
                >
                  <FontAwesomeIcon
                    className="ms-50"
                    icon={["fas", "paperclip"]}
                    style={{
                      fontSize: "1vw",
                      marginLeft: "10px",
                      marginRight: "10px",
                      color: "#908f8f",
                    }}
                  />
                  <label>
                    {values.gst_certificate
                      ? values.gst_certificate.name === ""
                        ? "Upload GST Certificate"
                        : values.gst_certificate.name
                      : "Upload GST Certificate"}
                  </label>
                </div>
                <span
                  className={
                    errors.gst_certificate && touched.gst_certificate
                      ? "text-danger"
                      : ""
                  }
                >
                  {errors.gst_certificate && touched.gst_certificate ? (
                    gst_certificate
                  ) : values.gst_certificate ? (
                    values.gst_certificate.name != "" ? (
                      <span
                        style={{
                          color: "#09b909",
                          fontSize: "10px",
                        }}
                      >
                        File attached
                      </span>
                    ) : (
                      "Supported file(.pdf,.jpg,.png) && maximum file size 1MB"
                    )
                  ) : (
                    "Supported file(.pdf,.jpg,.png) && maximum file size 1MB"
                  )}
                </span>
                <TextField
                  inputRef={fileRep}
                  type="file"
                  // value={values.msme_certificate}
                  name="gst_certificate"
                  onChange={(e: any) => {
                    setFieldValue("gst_certificate", e.target.files[0]);
                    setFieldTouched("gst_certificate", true, false);
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": {
                      top: 0,
                      border: "2px solid #c4c4c4",
                      borderRadius: "0px",
                    },
                    // borderRadius: "0px",
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    display: "none",
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="col-12 mt-4 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    onClick={() => {
                      handleClose();
                      setIsEdit(false);
                    }}
                    className="cancel"
                  >
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        <22/DialogActions> */}
      </Dialog>
    </>
  );
};

const BankDetails = (props: any) => {
  const validationSchema = Yup.object().shape({
    bank_name: Yup.string().required("Bank Name Is Required"),
    IFSC_code: Yup.string().required("IFSC Code Is Required"),
    account_number: Yup.string().required("Account Number  Is Required"),
    bank_account_name: Yup.string().required("Bank Account  Is Required"),
    branch: Yup.string().required("Branch  Is Required"),
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),

    cancelled_cheque: Yup.mixed()
      .test(
        "Cancelled Cheque Required",
        "Unsupported Format - We only allow jpg,pdf,png,jpeg",
        (value) => {
          if (value.type) {
            if (SUPPORTED_FORMATS.includes(value.type)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      )
      .test(
        "Cancelled Cheque Required",
        "Cancelled Cheque Required",
        (value: any) => {
          if (bankDetailData.isCompleted) {
            return true;
          }
          if (value.name) {
            return true;
          } else {
            return false;
          }
        }
      )
      .test("file size", "maximum file size 1MB", (value) => {
        const fileSize = value.size / 1024 / 1024;
        if (value.type) {
          if (fileSize <= 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
  });

  type basicData = {
    id: any;
    bank_name: string;
    IFSC_code: string;
    bank_code: string;
    account_number: string;
    bank_account_name: string;
    branch: string;
    cancelled_cheque: any;
  };
  const bankDetailData: { data: basicData; isCompleted: boolean } =
    props.bank_Detail_Data;
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: bankDetailData.isCompleted ? bankDetailData.data.id : "",
      bank_name: bankDetailData.isCompleted
        ? bankDetailData.data.bank_name
        : "",
      bank_code: bankDetailData.isCompleted
        ? bankDetailData.data.bank_code
        : "",
      IFSC_code: bankDetailData.isCompleted
        ? bankDetailData.data.IFSC_code
        : "",
      account_number: bankDetailData.isCompleted
        ? bankDetailData.data.account_number
        : "",
      bank_account_name: bankDetailData.isCompleted
        ? bankDetailData.data.bank_account_name
        : "",
      branch: bankDetailData.isCompleted ? bankDetailData.data.branch : "",
      cancelled_cheque: bankDetailData.isCompleted
        ? bankDetailData.data.cancelled_cheque
        : {
            name: "",
          },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("customer_ Bank_Details_Submit", values);
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;

  useEffect(() => {
    props.SetCheckSaveErrors(Object.entries(errors).length > 0 ? true : false);
  }, [errors, props]);

  // submit
  const submit = async (e: any) => {
    await dispatch(submit_bank_detail_state(e));
    props.bank_detail_error(e);
  };

  const ifsc_code_option = useSelector(
    (state: any) => state.BankIFSCCodeListApi
  );
  const cancelled_cheque_error: any =
    errors.cancelled_cheque != undefined ? errors.cancelled_cheque : "";
  //  type of business options
  const bank_names =
    props.bank_master_data.data.length != 0
      ? props.bank_master_data.data.status
        ? props.bank_master_data.data.result.map((e: any) => {
            const data = {
              code: e.BankCode,
              label: e.BankName,
              value: e.BankName,
            };
            return data;
          })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  //  type of business options
  const bank_ifsc_codes =
    ifsc_code_option.data.length != 0
      ? ifsc_code_option.data.status
        ? ifsc_code_option.data.result.map((e: any) => {
            const data = { label: e.IFC, value: e.IFC, branch: e.BRANCH };
            return data;
          })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];
  // ref for file upload
  const cancelledChequeRef = createRef<any>();
  return (
    <>
      <div className="d-flex flex-column">
        <div className="">
          <div className="row p-0 mt-3">
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>
                Bank Name <span>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  height: "50px",
                  borderRadius: "0px",
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                value={values.bank_name}
                options={bank_names}
                onChange={(e, value: any) => {
                  setFieldValue("IFSC_code", "");
                  setFieldValue("branch", "");
                  const a = value ? value.value : "";
                  // console.log("Bank_COde", value.code);
                  dispatch(clear_data_bank_Ifsc_code());
                  dispatch(
                    list_bank_Ifsc_code({
                      bank: a,
                    })
                  );

                  // vari(a);
                  setFieldValue("IFSC_code", "");
                  setFieldValue("branch", "");
                  setFieldValue("bank_code", value ? value.code : "");
                  setFieldValue("bank_name", a);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.bank_name && errors.bank_name ? true : false}
                    helperText={
                      touched.bank_name && errors.bank_name
                        ? errors.bank_name
                        : ""
                    }
                    name="bank_name"
                    placeholder="Select Type"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>
                BIC/IFSC/SWIFT Code <span>*</span>
              </label>
              <Autocomplete
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  height: "50px",
                  borderRadius: "0px",
                }}
                value={values.IFSC_code}
                options={bank_ifsc_codes}
                onChange={(e, value: any) => {
                  const a = value ? value.value : "";
                  // dispatch(
                  //   list_bank_Ifsc_code({
                  //     bank: a,
                  //   })
                  // );
                  // vari(a);

                  setFieldValue("branch", value ? value.branch : "");

                  setFieldValue("IFSC_code", a);
                }}
                renderOption={(
                  props: React.HTMLAttributes<HTMLLIElement>,
                  option: any
                ) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      textTransform: "capitalize",
                    }}
                    onBlur={handleBlur}
                    error={touched.IFSC_code && errors.IFSC_code ? true : false}
                    helperText={
                      touched.IFSC_code && errors.IFSC_code
                        ? errors.IFSC_code
                        : ""
                    }
                    name="IFSC_code"
                    placeholder="Select Type"
                    {...params}
                  />
                )}
              />
            </div>

            <div className="input-field px-3 col-4 d-flex flex-column">
              <label>
                Account No. <span>*</span>
              </label>
              <TextField
                placeholder="Account No."
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_number}
                error={
                  touched.account_number && errors.account_number ? true : false
                }
                helperText={
                  touched.account_number && errors.account_number
                    ? errors.account_number
                    : ""
                }
                name="account_number"
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }}
              />
            </div>
            <div className="input-field px-3 mt-3 col-4 d-flex flex-column">
              <label>
                Bank Account Name <span>*</span>
              </label>
              <TextField
                placeholder=" Bank Account Name"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bank_account_name}
                error={
                  touched.bank_account_name && errors.bank_account_name
                    ? true
                    : false
                }
                helperText={
                  touched.bank_account_name && errors.bank_account_name
                    ? errors.bank_account_name
                    : ""
                }
                name="bank_account_name"
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }}
              />
            </div>
            <div className="input-field px-3 mt-3 col-4 d-flex flex-column">
              <label>
                Branch <span>*</span>
              </label>
              <TextField
                placeholder="Branch"
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.branch}
                error={touched.branch && errors.branch ? true : false}
                helperText={
                  touched.branch && errors.branch ? errors.branch : ""
                }
                name="branch"
                InputProps={{
                  readOnly: true,
                }}
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }}
              />
            </div>
            <div className="input-field px-3 mt-3 col-4 d-flex flex-column">
              <label>
                Cancelled Cheque <span>*</span>
              </label>
              <div
                className={
                  errors.cancelled_cheque && touched.cancelled_cheque
                    ? "msme-certificate-require"
                    : "msme-certificate"
                }
                onClick={() => {
                  cancelledChequeRef.current.click();
                }}
              >
                <FontAwesomeIcon
                  className="ms-50"
                  icon={["fas", "paperclip"]}
                  style={{
                    fontSize: "1vw",
                    marginLeft: "10px",
                    marginRight: "10px",
                    color: "#908f8f",
                  }}
                />
                <label>
                  {values.cancelled_cheque.name === ""
                    ? "Cancelled Cheque"
                    : values.cancelled_cheque.name}
                </label>
              </div>
              <span
                className={
                  errors.cancelled_cheque && touched.cancelled_cheque
                    ? "text-danger"
                    : ""
                }
              >
                {errors.cancelled_cheque && touched.cancelled_cheque ? (
                  cancelled_cheque_error
                ) : values.cancelled_cheque.type ? (
                  <span
                    style={{
                      color: "#09b909",
                      fontSize: "10px",
                    }}
                  >
                    File attached
                  </span>
                ) : (
                  "Supported file(.pdf,.jpg,.png) && maximum file size 1MB"
                )}
              </span>
              <TextField
                inputRef={cancelledChequeRef}
                type="file"
                name="cancelled_cheque"
                onChange={(e: any) => {
                  setFieldValue("cancelled_cheque", e.target.files[0]);
                  setFieldTouched("cancelled_cheque", true, false);
                }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": {
                    top: 0,
                    border: "2px solid #c4c4c4",
                    borderRadius: "0px",
                  },
                  // borderRadius: "0px",
                  fontWeight: "700",
                  fontFamily: "Roboto",
                  display: "none",
                }}
                inputProps={{
                  style: {
                    height: "20px",
                  },
                }}
              />
            </div>
          </div>
          <div
            ref={props.bank_validate}
            onClick={() => {
              handleSubmit();
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

const Aggrement = (props: any) => {
  const {
    scrolledPage,
    setScrolledPage,
    firstNumber,
    secondNumber,
    thirdNumber,
    forthNumber,
    fifthNumber,
    setFirstNumber,
    setSecondNumber,
    setThirdNumber,
    setForthNumber,
    setFifthNumber,
    verifyed_otp,
    basicInfoCompleted,
    contactInfoCompleted,
    addressInfoCompleted,
    bankDetailCompleted,
    addressdata,
    contactdata,
    bankdata,
  }: any = props;

  const agreement_required =
    props.PartnerMasterList.data.length != 0
      ? props.PartnerMasterList.data.result.length != 0
        ? true
        : false
      : false;

  let dataval: any;
  const listInnerRef: any = useRef();
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [acceptTerms, setTerms] = useState<boolean>(false);
  const [completeScrollbar, setComplete] = useState<boolean>(false);
  const dispatch = useDispatch();

  const url =
    props.agreementData.data.length != 0
      ? props.agreementData.data.result.documentfile_url
      : "";

  const api = configs.api;
  // const api = "http://192.168.1.114:3035";
  const get_otp_from_backend = useSelector(
    (state: any) => state.getOnboardingAggrementOtp
  );

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  const pageLenth: number[] = [];
  for (let i = 0; i < numPages; i++) {
    pageLenth.push(i + 1);
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 300) {
        setScrolledPage(true);
        setComplete(true);
      } else {
        if (completeScrollbar) {
          setComplete(false);
        }
      }
    }
  };
  const submitOTP = () => {
    if (
      firstNumber &&
      secondNumber &&
      thirdNumber &&
      forthNumber &&
      fifthNumber
    ) {
      const final_otp =
        firstNumber + secondNumber + thirdNumber + forthNumber + fifthNumber;

      dispatch(
        raise_api_call_verify_otp_onboarding({
          aggrementid:
            props.agreementData.data.length != 0
              ? props.agreementData.data.result.id
              : "",
          otp: final_otp,
        })
      );
    }
  };

  return (
    <>
      <div className=" w-100 d-flex ">
        {url == null ? (
          ""
        ) : (
          <div className="w-70  my-auto">
            <div className="d-flex justify-content-end">
              <div
                className="d-flex justify-content-end me-2"
                style={{
                  cursor: "pointer",
                  width: "200px",
                }}
                onClick={async () => {
                  const res = await axios.get(api + url, {
                    responseType: "blob",
                  });
                  FileSaver.saveAs(res.data, "agreement.pdf");
                }}
              >
                <span>Download Agreement</span>
                <DownloadIcon />
              </div>
            </div>
            <div
              className="aggrement  border"
              id="agreements"
              onScroll={() => onScroll()}
              ref={listInnerRef}
            >
              {!completeScrollbar ? (
                <>
                  <ArrowCircleDownIcon
                    className="scroll"
                    onClick={() => {
                      const { scrollTop, scrollHeight, clientHeight } =
                        listInnerRef.current;
                      listInnerRef.current.scrollTop =
                        // scrollTop + clientHeight + 200;
                        listInnerRef.current.scrollHeight;
                      setScrolledPage(true);
                    }}
                  />
                </>
              ) : (
                ""
              )}

              <div>
                <div className="container-fluid text-center">
                  {/* <Document
                  onLoadError={(e) => {
                  }}
                  file={"http://example.com/sample.pdf"}
                /> */}
                  <div className="row terms-and-conditions-section text-center">
                    <div className="px-5 text-center body-text">
                      <div className="content">
                        <Document
                          file={api + url}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          {pageLenth.map((page) => (
                            <Page pageNumber={page} />
                          ))}
                        </Document>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className=" d-flex align-item
        s-center"
          style={{
            width: "30%",
            margin: "auto",
          }}
        >
          <div className="m-auto">
            <div className="otp-verification">
              {props.agreementData.data.length === 0 ? (
                ""
              ) : !props.agreementData.data.result.Verify ? (
                <>
                  {url === null ? (
                    ""
                  ) : (
                    <h5>
                      <Checkbox
                        sx={{
                          padding: "0",
                        }}
                        onChange={(e: any) => {
                          setTerms(e.target.checked);
                        }}
                        name="gst_filling_status"
                        checked={
                          (props.basicInfoCompleted &&
                            props.contactInfoCompleted &&
                            props.addressInfoCompleted &&
                            props.bankDetailCompleted &&
                            scrolledPage &&
                            acceptTerms) ||
                          url == null
                            ? true
                            : false
                        }
                      />
                      I accept the terms & conditions constitutes the entire
                      agreement between the parties to the contract
                    </h5>
                  )}

                  <div
                    className={
                      url == null
                        ? props.basicInfoCompleted &&
                          props.contactInfoCompleted &&
                          props.addressInfoCompleted &&
                          props.bankDetailCompleted &&
                          addressdata.data.length != 0 &&
                          contactdata.data.length != 0 &&
                          Object.keys(bankdata.data).length != 0
                          ? "genarate-otp"
                          : "genarate-otp-grey"
                        : props.basicInfoCompleted &&
                          props.contactInfoCompleted &&
                          props.addressInfoCompleted &&
                          props.bankDetailCompleted &&
                          addressdata.data.length != 0 &&
                          contactdata.data.length != 0 &&
                          Object.keys(bankdata.data).length != 0 &&
                          scrolledPage &&
                          acceptTerms
                        ? "genarate-otp"
                        : "genarate-otp-grey"
                    }
                    // &&
                    //         scrolledPage &&
                    //         acceptTerms) ||
                    //       url == null
                    onClick={() => {
                      if (
                        // (props.basicInfoCompleted &&
                        //   props.contactInfoCompleted &&
                        //   props.addressInfoCompleted &&
                        //   props.bankDetailCompleted &&
                        //   scrolledPage &&
                        //   acceptTerms) ||
                        // url == null
                        url == null
                          ? props.basicInfoCompleted &&
                            props.contactInfoCompleted &&
                            props.addressInfoCompleted &&
                            props.bankDetailCompleted &&
                            addressdata.data.length != 0 &&
                            contactdata.data.length != 0 &&
                            Object.keys(bankdata.data).length != 0
                          : // &&
                            // AddressInfoData.data.length !== 0

                            props.basicInfoCompleted &&
                            props.contactInfoCompleted &&
                            props.addressInfoCompleted &&
                            props.bankDetailCompleted &&
                            addressdata.data.length != 0 &&
                            contactdata.data.length != 0 &&
                            Object.keys(bankdata.data).length != 0 &&
                            scrolledPage &&
                            acceptTerms
                      ) {
                        dispatch(
                          get_otp_from_api({
                            aggrementid:
                              props.agreementData.data.length != 0
                                ? props.agreementData.data.result.id
                                : "",
                          })
                        );
                      }
                      // if (agreement_required) {
                      //   dispatch(
                      //     get_otp_from_api({
                      //       aggrementid:
                      //         props.agreementData.data.length != 0
                      //           ? props.agreementData.data.result.id
                      //           : "",
                      //     })
                      //   );
                      // }
                    }}
                  >
                    {get_otp_from_backend.data.length === 0
                      ? "Generate OTP"
                      : !get_otp_from_backend.data.status
                      ? "Generate OTP"
                      : "Resend OTP"}
                  </div>
                  {get_otp_from_backend.data.length === 0 ? (
                    ""
                  ) : !get_otp_from_backend.data.status ? (
                    ""
                  ) : (
                    <div className="verify-otp  mt-2">
                      <div className="d-flex align-items-center">
                        <CheckCircleIcon className="tic-icon" />
                        <p className="otp-sent m-0">
                          OTP has been send to your registered mail
                        </p>
                      </div>
                      <p className="ms-2 otp-enter-text mt-4">
                        Enter the 5 digit OTP
                      </p>
                      <div className="otp-field d-flex justify-content-center">
                        <form className="d-flex">
                          <input
                            className=" input-otp "
                            maxLength={1}
                            defaultValue={firstNumber}
                            onChange={(e) => {
                              if (e.target.value.length === 1) {
                                const form: any = e.target.form;
                                const index: number = [...form].indexOf(
                                  e.target
                                );
                                form.elements[index + 1].focus();
                                setFirstNumber(e.target.value);
                                e.preventDefault();
                              }
                            }}
                            name="search"
                          />
                          <input
                            // value={secondNumber}
                            defaultValue={secondNumber}
                            onChange={(e) => {
                              if (e.target.value.length === 1) {
                                const form: any = e.target.form;
                                const index: number = [...form].indexOf(
                                  e.target
                                );
                                form.elements[index + 1].focus();
                                setSecondNumber(e.target.value);
                                e.preventDefault();
                              }
                            }}
                            className=" input-otp "
                            maxLength={1}
                            name="search"
                          />
                          <input
                            onChange={(e) => {
                              if (e.target.value.length === 1) {
                                const form: any = e.target.form;
                                const index: number = [...form].indexOf(
                                  e.target
                                );
                                form.elements[index + 1].focus();
                                setThirdNumber(e.target.value);
                                e.preventDefault();
                              }
                            }}
                            defaultValue={thirdNumber}
                            // value={thirdNumber}
                            className=" input-otp "
                            maxLength={1}
                            name="search"
                          />
                          <input
                            onChange={(e) => {
                              if (e.target.value.length === 1) {
                                const form: any = e.target.form;
                                const index: number = [...form].indexOf(
                                  e.target
                                );
                                form.elements[index + 1].focus();
                                setForthNumber(e.target.value);
                                e.preventDefault();
                              }
                            }}
                            defaultValue={forthNumber}
                            // value={forthNumber}
                            className=" input-otp "
                            maxLength={1}
                            name="search"
                          />
                          <input
                            onChange={(e) => {
                              setFifthNumber(e.target.value);
                            }}
                            value={fifthNumber}
                            className=" input-otp last"
                            maxLength={1}
                            name="search"
                          />
                        </form>
                      </div>
                      <div>
                        {verifyed_otp.data.length != 0
                          ? !verifyed_otp.data.status
                            ? verifyed_otp.data.message
                            : ""
                          : ""}
                      </div>
                      <div
                        className={
                          verifyed_otp.data.length != 0
                            ? verifyed_otp.data.status
                              ? "genarate-otp-green"
                              : "genarate-otp"
                            : "genarate-otp"
                        }
                        onClick={() => {
                          submitOTP();
                        }}
                      >
                        {verifyed_otp.data.length != 0
                          ? verifyed_otp.data.status
                            ? "Verified"
                            : "Verify"
                          : "Verify"}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className={"genarate-otp-green "}
                  style={{
                    padding: "12px 20px",
                    width: "261px",
                  }}
                >
                  Verified
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
