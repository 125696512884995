import { createSlice } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const CreatePassword = createSlice({
  name: "CreatePassword",
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    raise_api_call_create_password: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      SUCCESE(action.payload.message);
    },
    failure_api_call: (state, action) => {
      state.isLoading = false;
      FAILED(action.payload);
    },
  },
});
export default CreatePassword.reducer;
export const {
  raise_api_call_create_password,
  success_api_call,
  failure_api_call,
} = CreatePassword.actions;
