import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

export const Menus = createSlice({
  name: "Menus",
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    raise_api_call_menu: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call_menu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      //   success(action.payload);
    },
    failure_api_call_menu: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      //   FAILED(action.payload);
    },
    data_cleare_menu: (state, action) => {
      state.data = [];
    },
  },
});
export default Menus.reducer;
export const {
  raise_api_call_menu,
  success_api_call_menu,
  failure_api_call_menu,
  data_cleare_menu,
} = Menus.actions;
