import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const DownPayAccountActiveData = createSlice({
  name: "DownPayAccountActiveData",
  initialState: {
    data: {},
  },
  reducers: {
    addDownPayData: (state, action?: any) => {
      state.data = action.payload;
    },
  },
});
export default DownPayAccountActiveData.reducer;
export const { addDownPayData } = DownPayAccountActiveData.actions;
