import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const WTAXMasterListApi = createSlice({
  name: "WTAXMasterListApi",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_wtax_master: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call_wtax_master: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_wtax_master: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    wtax_master_isFirst: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default WTAXMasterListApi.reducer;
export const {
  list_wtax_master,
  success_api_call_wtax_master,
  failure_api_call_wtax_master,
  wtax_master_isFirst,
} = WTAXMasterListApi.actions;
