import React, { useEffect, useState } from "react";
import "../../assets/css/sap-master/sap-master.css";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { InputAdornment } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import { addGroupDataData } from "../../redux/sap-master/group-master-active-data-reducer";
import {
  business_channel_is_first,
  list_business_channel,
} from "../../redux/prefered-master/business-channel-list";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AutoComplete from "../components/autocomplete-input";

import CompletePopup from "../components/response-pop-up";
import { list_role } from "../../redux/add-user/list-role";
import {
  add_user,
  add_user_is_success,
  create_user_complete_api,
} from "../../redux/add-user/add-user";
import { list_user, user_is_first } from "../../redux/add-user/list-user";
import {
  edit_user,
  edit_user_complete_api,
  edit_user_is_success,
} from "../../redux/add-user/edit-user";
const AddUser = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const roleList = useSelector((state: any) => state.ListRole);
  const addUserApi = useSelector((state: any) => state.AddUser);
  const editUserApi = useSelector((state: any) => state.editUser);
  const [activePartner, setActivePartner] = React.useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const activeDatas = useSelector((state: any) => state.GroupMasterActiveData);
  const activeData = activeDatas.data;

  const list_users = useSelector((state: any) => state.ListUser);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    department: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    employee_id: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    reporting_manager: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
    email: Yup.string().email("email is invalid").required("Required"),
    password: Yup.string().required("Required"),
    telephone: Yup.number().required("Required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      department: edit ? activeData.department : "",
      name: edit ? activeData.name : "",
      employee_id: edit ? activeData.employee_id : "",
      role: edit ? activeData.roleId : "",
      reporting_manager: edit ? activeData.reporting_manager : "",
      email: edit ? activeData.email : "",
      location: edit ? activeData.location : "",
      password: "",
      telephone: edit ? activeData.telephone : "",
      active: edit ? activeData.active : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  // useEffect
  useEffect(() => {
    dispatch(list_user(""));
    dispatch(list_role(""));
    if (addUserApi.isSuccess) {
      resetForm();
      handleClose();
      dispatch(add_user_is_success(""));
    }
    if (editUserApi.isSuccess) {
      resetForm();
      handleClose();
      dispatch(edit_user_is_success(""));
    }
    if (addUserApi.api_completed) {
      setResponsePopup(true);
      dispatch(create_user_complete_api(false));
    }
    if (editUserApi.api_completed) {
      setResponseEdit(true);
      dispatch(edit_user_complete_api(false));
      setResponsePopup(true);
    }
  }, [addUserApi, editUserApi]);

  const submit = (a: any) => {
    const role = roleList.data.result.filter((e: any) => {
      if (a.role === e.role_name) {
        return e;
      }
    });
    const report_manager = list_users.data.result.filter((e: any) => {
      if (a.reporting_manager === e.name) {
        return e;
      }
    });
    if (!edit) {
      const data = {
        department: a.department,
        name: a.name,
        employee_id: a.employee_id,
        active: a.active,
        role: a.role,
        reporting_manager: a.reporting_manager,
        email: a.email,
        location: a.location,
        password: a.password,
        telephone: a.telephone,
      };
      dispatch(add_user(data));
    } else {
      const data = {
        id: activeData.id,
        department: a.department,
        name: a.name,
        employee_id: a.employee_id,
        role: a.role,
        active: a.active,
        reporting_manager: a.reporting_manager,
        email: a.email,
        location: a.location,
        password: a.password,
        telephone: a.telephone,
      };

      dispatch(edit_user(data));
    }
  };
  // mainTable data
  const mainTable = () => {
    const filterarr = [];
    if (list_users.data.length != 0) {
      for (let i = 0; i < list_users.data.result.length; i++) {
        let data = { ...list_users.data.result[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // create on
        const created_on = new Date(data.created_on);
        // delete data.EffecDate;
        const created_date =
          created_on.getDate() +
          "-" +
          monthNames[created_on.getMonth()] +
          "-" +
          created_on.getFullYear();
        data.created_on
          ? (data.created_on = created_date)
          : console.log("sdfdsf");
        filterarr.push(data);
      }
    }
    // filterarr.filter((e) => delete e.id);
    // filterarr.filter((e) => delete e.active);
    filterarr.filter((e) => delete e.created_by);
    filterarr.filter((e) => delete e.updated_by);

    return (
      <>
        {/* {list_users.data.length != 0 ? ( */}
        <>
          <div className="list col-5">
            {responsePopup ? (
              <CompletePopup
                data={responseEdit ? editUserApi : addUserApi}
                responsePopup={responsePopup}
                setCompletePopup={(e: any) => {
                  setResponsePopup(false);
                  setResponseEdit(false);
                }}
              />
            ) : (
              ""
            )}
            <div className=" px-3 my-4  d-flex align-items-center">
              <div className="title d-flex ">
                <h5>Add User</h5>
              </div>

              <div className="filter ms-auto me-3 d-flex align-items-center">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="w-100 me-2"
                  name="search"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "45px",
                    },
                  }}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    dispatch(list_business_channel(""));
                  }}
                  className="mx-2"
                  style={{
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                  icon={["fas", "arrows-rotate"]}
                />
              </div>
            </div>
            <div className="list">
              {filterarr
                .filter((item: any) => {
                  return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                .map((e: any, key: number) => {
                  if (list_users.isFirst && key === 0) {
                    dispatch(user_is_first(""));
                    dispatch(addGroupDataData(e));
                  }
                  return (
                    <>
                      <div
                        key={key + 1}
                        id={activePartner === key ? "active-lists" : ""}
                        onClick={(a) => {
                          setActivePartner(key);
                          dispatch(addGroupDataData(e));
                        }}
                        className={"d-flex lists flex-column mt-2  p-3"}
                        style={{
                          height: "100px",
                          background: "#FFFFFF",
                        }}
                      >
                        <div className="mx-2 partner-name">{e.name}</div>
                        <div className="d-flex mt-auto partner-locations align-items-center">
                          <p className="ms-2 me-auto mb-0">{e.email}</p>
                          <p
                            className={
                              e.status === "Request Sent for Approval"
                                ? "ms-auto me-2 mb-0 text-yellow"
                                : "ms-auto text-light-green me-2 mb-0"
                            }
                          >
                            {/* {e.status} */}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div
            className="col-7 tracking pt-4 px-4"
            style={{
              height: "100vh",
            }}
          >
            <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
              <div
                className="mx-4 add-branch"
                onClick={() => {
                  setEdit(false);
                  handleClickOpen();
                }}
              >
                Add user
              </div>
              <div
                className="mx-4 add-branch"
                onClick={() => {
                  setEdit(true);
                  handleClickOpen();
                }}
              >
                Edit user
              </div>
            </div>
            <div className="row">
              <div className="col-4 partner-detail-data mt-4">
                <label> Name</label>
                <p>{activeData.name}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Email</label>
                <p>{activeData.email}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label> Employee Id</label>
                <p>{activeData.employee_id}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Reporting Manager</label>
                <p>
                  {activeData.reporting_manager
                    ? activeData.reporting_manager
                    : "-"}
                </p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Location</label>
                <p>{activeData.location}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Role Id</label>
                <p>{activeData.roleId}</p>
              </div>
              <div className="col-4 partner-detail-data mt-4">
                <label>Sequence Code</label>
                <p>{activeData.active ? "Active" : "inActive"}</p>
              </div>

              <div className="col-4 partner-detail-data mt-4">
                <label>Create On</label>
                <p>{activeData.created_on}</p>
              </div>
            </div>
            {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
          </div>
        </>
        {/* ) : (
          <div>No Data Found</div>
        )} */}
      </>
    );
  };
  const pageOption = roleList.data.status
    ? list_users.data.length != 0
      ? roleList.data.result.map((e: any) => {
          const data = { id: e.id, label: e.role_name, value: e.id };
          return data;
        })
      : [{ label: "No Page", value: "no Page" }]
    : [{ label: "No Page", value: "no Page" }];
  const reportManager = roleList.data.status
    ? list_users.data.length != 0
      ? list_users.data.result.map((e: any) => {
          const data = { id: e.id, label: e.name, value: e.id };
          return data;
        })
      : [{ label: "No Page", value: "no Page" }]
    : [{ label: "No Page", value: "no Page" }];
  return (
    <>
      <div className="general-setting sap-master">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={roleList.isLoading || list_users.isLoading ? true : false}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "100px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className="forms d-flex">
          {
            //   list_users.isSuccess ? (
            mainTable()
            //   ) : (
            //     <div>No Data Found</div>
            //   )
          }
        </div>
      </div>
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700">
            {"Add User"}{" "}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="d-flex flex-column">
            <div className="row">
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="name"
                  label="Name"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="department"
                  label="Department"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col-6 input-field px-3 mt-3 col-6 d-flex flex-column">
                <AutoComplete
                  className="w-100"
                  pageOption={pageOption}
                  name="role"
                  handleBlur={handleBlur}
                  values={values}
                  vari={(e: any) => {
                    setFieldValue("role", e ? e.value : "");
                  }}
                  label="Role"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <AutoComplete
                  className="w-100"
                  pageOption={reportManager}
                  name="reporting_manager"
                  handleBlur={handleBlur}
                  values={values}
                  vari={(e: any) => {
                    setFieldValue("reporting_manager", e ? e.value : "");
                  }}
                  label="Reporting Manger"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 col-6 mt-2 d-flex flex-column">
                <TextFiealdInput
                  name="employee_id"
                  label="Employee Id"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="email"
                  label="Email"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>

              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="location"
                  label="Location"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-3 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="password"
                  label="Password"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3  mt-3 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="telephone"
                  label="Telephone"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col-12 mt-4">
                <div className="col-6 d-flex ms-3 align-items-center">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox checked={values.active} />}
                    label="Is Active"
                    onChange={handleChange}
                    name="active"
                    labelPlacement="end"
                    sx={{
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    onClick={() => {
                      handleClose();
                      //   setIsEdit(false);
                    }}
                    className="cancel"
                  >
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {addUserApi.isLoading ? "Please Wait" : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUser;
