import { useCallback } from "react";
import { Route, Navigate, useNavigate, Outlet } from "react-router-dom";

function PrivateRoute({ children }: any) {
  const navigate = useNavigate();
  const token = localStorage.getItem("SpToken");
  if (token != null && token != "undefined") {
    return children;
  } else {
    console.log(token);

    window.location.pathname = "/login";
  }
}
export default PrivateRoute;
