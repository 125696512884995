import { PublicClientApplication } from "@azure/msal-browser";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import "../../assets/css/login.css";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  data_cleare,
  raise_api_call,
} from "../../redux/login-and-create-password/login-reducer";
import { useNavigate } from "react-router";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
const Login = () => {
  const validationSchema = Yup.object().shape({
    email_id: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    Password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // ),
  });
  const formik = useFormik({
    initialValues: {
      email_id: "",
      Password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  // const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  //   props,
  //   ref
  // ) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(data_cleare(""));
    setOpen(false);
  };
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
    errors,
  } = formik;
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [Dialogopen, setDialogOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showConfirmPassword, setShowConfirmPassword] =
    useState<Boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LoginApi = useSelector((state: any) => state.Login);

  // useEffect
  useEffect(() => {
    document.addEventListener("keypress", keyPress, true);
    if (LoginApi.data.status) {
      localStorage.setItem("SpToken", LoginApi.data.token);
      console.log(LoginApi);
      // window.location.pathname = "/";
      const role = LoginApi.data.result?.role_name;
      if (role) {
        if (role == "super_admin" || role == "employee" || role == "admin") {
          navigate("/business-partner/onboarding");
        } else if (
          role == "management" ||
          role == "account_head" ||
          role == "account_team"
        ) {
          navigate("/business-partner/partner-details");
        } else if (role == "partner") {
          navigate("/business-partner/onboarding/add");
        } else {
          navigate("/");
        }
      }
    }
    if (LoginApi.data.length != 0 && !LoginApi.data.status) {
      setOpen(true);
    }
  }, [LoginApi]);

  useEffect(() => {
    if (LoginApi.data.status_code == 503) {
      setDialogOpen(true);
      setErrorMessage(LoginApi.data.message);
    }
  }, [LoginApi.data]);

  // normal login
  const submit = (data: any) => {
    console.log(data);
    const login_data = {
      email: data.email_id,
      password: data.Password,
    };
    dispatch(raise_api_call(login_data));
  };

  // enter button submit
  const keyPress = (e: any) => {
    // console.log("value", e);
    if (e.keyCode == 13) {
      // put the login here
      handleSubmit();
    }
  };

  // microsoft login function

  const getDate = new PublicClientApplication({
    auth: {
      clientId: "9c8eff0a-f553-4a2f-a700-f551282acc3f",
      redirectUri: "http://localhost:3000",
      authority:
        "https://login.microsoftonline.com/2fdf28ac-a687-46d3-a0e0-cad399982737",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  });
  const login = async () => {
    try {
      const logindata = await getDate.loginPopup({
        scopes: ["user.read"],
      });
      console.log(logindata);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClosed = () => {
    setDialogOpen(false);
    // setErrorMessage("");
  };
  return (
    <>
      <div className="create_password">
        <div
          className="page col-12"
          style={{
            minHeight: "100% !important",
            maxHeight: "100% !important",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <div className="col-12 ">
            <img
              className="logo_image"
              src={require("../../assets/images/banner_Logo_2.jpg")}
            />
          </div>
          <div
            className="login_form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className=" d-flex justify-content-center align-items-center w-100">
              <div className="create-password-form m-0">
                <div className="d-flex">
                  <div>
                    <h2>Login</h2>
                    <p>Enter a valid info</p>
                  </div>
                </div>

                <div className="forms">
                  <div
                    className="text-fieald w-100"
                    style={{
                      height: "65px",
                    }}
                  >
                    <TextField
                      type="email"
                      id="outlined-basic"
                      value={values.email_id}
                      onBlur={handleBlur}
                      name="email_id"
                      label="Email ID"
                      onChange={(e: any) => {
                        setFieldValue("email_id", e.target.value.toLowerCase());
                      }}
                      helperText={touched.email_id && errors.email_id}
                      error={touched.email_id && errors.email_id ? true : false}
                      variant="outlined"
                      className="w-100"
                      InputProps={{
                        style: {
                          borderRadius: "0px",
                        },
                      }}
                    />
                  </div>
                  <div
                    className="text-fieald w-100"
                    style={{
                      height: "65px",
                    }}
                  >
                    <TextField
                      type={showPassword ? "text" : "Password"}
                      id="outlined-basic"
                      label={"Password"}
                      value={values.Password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Password"
                      error={touched.Password && errors.Password ? true : false}
                      helperText={touched.Password && errors.Password}
                      variant="outlined"
                      className="w-100"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityOff
                                type="button"
                                onClick={() => {
                                  setShowPassword(false);
                                }}
                              />
                            ) : (
                              <Visibility
                                type="button"
                                onClick={() => {
                                  setShowPassword(true);
                                }}
                              />
                            )}
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "0px",
                        },
                      }}
                    />
                  </div>
                  <div className="text-fieald-button login-button w-100">
                    <LoadingButton
                      className="w-100 text-white "
                      sx={{
                        borderRadius: "0px",
                        width: "100px",
                        height: "55px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 700,
                        backgroundColor: "#46A4E3",
                        textTransform: "capitalize",
                      }}
                      disabled={LoginApi.isLoading ? true : false}
                      variant="outlined"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {LoginApi.isLoading ? "Please Wait..." : "Login"}
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        // PaperProps={{
        //   style: { borderRadius: 0, width: "700px" },
        // }}
        open={Dialogopen}
        onClose={handleClosed}
        // maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle
          id="alert-dialog-title"
          style={{ color: "red", marginBottom: "15px" }}
          className="font-weight-700 pb-0"
        >
          {"Login Error"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "larger" }}
          >
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosed}
            style={{
              background: "#e4e4e4",
              color: "black",
              border: "1px solid black",
              margin: "10px",
              borderRadius: 0,
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Login;
