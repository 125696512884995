import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import {
  success_api_call_basic_info,
  failure_api_call_basic_info,
  create_basic_complete,
} from "./basic-info-add-reducer";
import {
  success_api_call_contact,
  failure_api_call_contact,
} from "./contact-details-add-reducer";
import {
  success_api_call_address,
  failure_api_call_address,
} from "./address-add-reducer";
import {
  success_api_call_bank_detail,
  failure_api_call_bank_detail,
} from "./bank-detail-add-reducer";
import { isVariableStatement } from "typescript";
import { configs } from "../../../common";
import { useDispatch } from "react-redux";

// const api = "http://185.209.228.65:3035/api4/list";
//192.168.1.107:3035

// local ipp
// const basicinfo_api = "http://192.168.1.114:3035/api6/create";
// const contact_api = "http://192.168.1.114:3035/api/person/create";
// const address_api = "http://192.168.1.114:3035/api/address/create";
// const banck__api = "http://192.168.1.114:3035/api/bankdetail/create";
// server ip

const basicinfo_api = configs.api + "/api6/create";
const contact_api = configs.api + "/api/person/create";
const address_api = configs.api + "/api/address/create";
const banck__api = configs.api + "/api/bankdetail/create";

const getCallBasicInfo = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(basicinfo_api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallContact = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(contact_api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallAddress = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(address_api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallBankDetail = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(banck__api, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddOnboardingApi(e: any) {
  try {
    // const dispatch = useDispatch();
    const response_basic_info: AxiosResponse = yield call(
      getCallBasicInfo,
      e.payload.basicInfo
    );
    if (response_basic_info.data.status) {
      yield put(success_api_call_basic_info(response_basic_info.data));
    } else {
      // dispatch(create_basic_complete(""));
      yield put(failure_api_call_basic_info(response_basic_info.data));
    }

    const response_contact: AxiosResponse = yield call(
      getCallContact,
      e.payload.contact_info
    );
    if (response_contact.data.status) {
      yield put(success_api_call_contact(response_contact.data));
    } else {
      // dispatch(create_basic_complete(""));
      yield put(failure_api_call_contact(response_contact.data));
    }

    const response_address: AxiosResponse = yield call(
      getCallAddress,
      e.payload.address_info
    );
    if (response_address.data.status) {
      yield put(success_api_call_address(response_address.data));
    } else {
      // dispatch(create_basic_complete(""));
      yield put(failure_api_call_address(response_address.data));
    }

    const response_bank_detail: AxiosResponse = yield call(
      getCallBankDetail,
      e.payload.bank_detail
    );
    if (response_bank_detail.data.status) {
      // dispatch(create_basic_complete(""));
      yield put(success_api_call_bank_detail(response_bank_detail.data));
    } else {
      // dispatch(create_basic_complete(""));
      yield put(failure_api_call_bank_detail(response_bank_detail.data));
    }
  } catch (err) {
    yield put(failure_api_call_bank_detail(err));
  }
}
