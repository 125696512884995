import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import {
  failure_api_call,
  success_api_call,
} from "./list-general-setting-reducer";
import { isVariableStatement } from "typescript";
import { configs } from "../../common";

const api = configs.api + "/api4/list";
// const api = "http://192.168.1.112:3035/api4/list";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");

  return Axios.get(api, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listGeneralSettingApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);
    console.log(typeof response.data);
    if (response.data.message === "Token is invalid") {
      window.location.pathname = "/login";
      localStorage.removeItem("SpApperalsToken");
    }
    if (response.data.name == "TokenExpiredError") {
      window.location.pathname = "/login";
      localStorage.removeItem("SpApperalsToken");
    }
    yield put(success_api_call(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call(e));
  }
}
