import React, { useEffect, useState } from "react";
import "../../assets/css/sap-master/sap-master.css";
import MainTable from "../components/main-tables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import {
  bank_master_isFirst,
  list_bank_master,
} from "../../redux/sap-master/bank-master";
import { useSelector } from "react-redux";
import { addGroupDataData } from "../../redux/sap-master/group-master-active-data-reducer";
import {
  sink_bank_isSuccess,
  sink_bank_master,
} from "../../redux/sap-master/brank-master-sink-api";
const BankMaster = () => {
  const dispatch = useDispatch();
  const bank_master_data = useSelector((state: any) => state.BankMasterListApi);
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const activeDatas = useSelector((state: any) => state.GroupMasterActiveData);
  const [search, setSearch] = useState<string>("");
  const activeData = activeDatas.data;
  const SinkBranchMasterApi = useSelector((State: any) => State.SinkbankMaster);
  // useEffect
  useEffect(() => {
    dispatch(list_bank_master(""));
    if (SinkBranchMasterApi.isSuccess) {
      dispatch(sink_bank_isSuccess(""));
    }
  }, [SinkBranchMasterApi]);

  // mainTable data
  const mainTable = () => {
    const filterarr = [];
    for (let i = 0; i < bank_master_data.data.result.length; i++) {
      let data = { ...bank_master_data.data.result[i] };
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // create on
      const created_on = new Date(data.created_on);
      // delete data.EffecDate;
      const created_date =
        created_on.getDate() +
        "-" +
        monthNames[created_on.getMonth()] +
        "-" +
        created_on.getFullYear();
      data.created_on
        ? (data.created_on = created_date)
        : console.log("sdfdsf");

      // update on
      const updated_on = new Date(data.updated_on);
      // delete data.EffecDate;
      const update_date =
        updated_on.getDate() +
        "-" +
        monthNames[updated_on.getMonth()] +
        "-" +
        updated_on.getFullYear();
      data.updated_on ? (data.updated_on = update_date) : console.log("sdfdsf");

      filterarr.push(data);
    }
    filterarr.filter((e) => delete e.id);
    // filterarr.filter((e) => delete e.active);
    filterarr.filter((e) => delete e.created_by);
    filterarr.filter((e) => delete e.updated_by);

    return (
      <>
        {bank_master_data.data.length != 0 ? (
          <>
            <div className="list col-5">
              <div className=" px-3 my-4  d-flex align-items-center">
                <div className="title d-flex ">
                  <h5>Bank Master</h5>
                </div>

                <div className="filter ms-auto me-3 d-flex align-items-center">
                  <TextField
                    placeholder="Search"
                    variant="outlined"
                    className="w-100 me-2"
                    name="search"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "0px",
                        height: "45px",
                      },
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      dispatch(sink_bank_master(""));
                    }}
                    className="mx-2"
                    style={{
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                    icon={["fas", "arrows-rotate"]}
                  />
                </div>
              </div>
              <div className="list">
                {filterarr
                  .filter((item: any) => {
                    return Object.values(item)
                      .join("")
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  .map((e: any, key: number) => {
                    if (bank_master_data.isFirst && key === 0) {
                      dispatch(bank_master_isFirst(""));
                      dispatch(addGroupDataData(e));
                    }
                    return (
                      <>
                        <div
                          key={key + 1}
                          id={activePartner === key ? "active-lists" : ""}
                          onClick={(a) => {
                            setActivePartner(key);
                            dispatch(addGroupDataData(e));
                          }}
                          className={"d-flex lists flex-column mt-2  p-3"}
                          style={{
                            height: "100px",
                            background: "#FFFFFF",
                          }}
                        >
                          <div className="mx-2 partner-name">{e.BankName}</div>
                          <div className="d-flex mt-auto partner-locations align-items-center">
                            <p className="ms-2 me-auto mb-0">{e.BankCode}</p>
                            <p
                              className={
                                e.status === "Request Sent for Approval"
                                  ? "ms-auto me-2 mb-0 text-yellow"
                                  : "ms-auto text-light-green me-2 mb-0"
                              }
                            >
                              {/* {e.status} */}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div
              className="col-7 tracking pt-4 px-4"
              style={{
                height: "100vh",
              }}
            >
              <div className="row">
                <div className="col-12 partner-detail-data mt-4">
                  <label>Bank Name</label>
                  <p>{activeData.BankName}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Bank Code</label>
                  <p>{activeData.BankCode}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Abs Entry</label>
                  <p>{activeData.AbsEntry}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Country Code</label>
                  <p>{activeData.CountryCod}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Active</label>
                  <p>{activeData.active ? "active" : "inActive"}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Create On</label>
                  <p>{activeData.created_on}</p>
                </div>
                <div className="col-4 partner-detail-data mt-4">
                  <label>Update On</label>
                  <p>{activeData.updated_on}</p>
                </div>
              </div>
              {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
            </div>
          </>
        ) : (
          <div>No Data Found</div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="general-setting sap-master">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            bank_master_data.isLoading || SinkBranchMasterApi.isLoading
              ? true
              : false
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        {/* <div className="title d-flex">
          <h5>Bank Master</h5>
          <div className="ms-auto me-2">
            <FontAwesomeIcon
              onClick={() => {
                dispatch(list_bank_master(""));
              }}
              style={{
                fontSize: "22px",
                cursor: "pointer",
              }}
              icon={["fas", "arrows-rotate"]}
            />
          </div>
        </div> */}
        <div className="forms d-flex">
          {bank_master_data.isSuccess ? mainTable() : <div>No Data Found</div>}
        </div>
      </div>
    </>
  );
};

export default BankMaster;
