import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  //   toast.success(data, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  if (data.status) {
    window.location.pathname = "/login";
  }
  //
};
// const FAILED = async (data: any) => {
//   console.log(data);
//   toast.error(data.message, {
//     position: toast.POSITION.TOP_RIGHT,
//   });
// };

export const CheckUserExist = createSlice({
  name: "CheckUserExist",
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    raise_api_call_check_user: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_check_user: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;

      SUCCESE(action.payload);
    },
    failure_api_call_check_user: (state, action) => {
      state.isLoading = false;
      //   FAILED(action.payload);
    },
  },
});
export default CheckUserExist.reducer;
export const {
  raise_api_call_check_user,
  success_api_call_check_user,
  failure_api_call_check_user,
} = CheckUserExist.actions;
