import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Avatar, Badge } from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { list_notification } from "../redux/notification/list-notification-reducer";
import { useSelector } from "react-redux";
import { read_one_notification } from "../redux/notification/single-notification-read";
import { Navigate, useNavigate } from "react-router";
import { addPartnerData } from "../redux/business-partner/partner-master/partner-master-first-data";
import { raise_api_call_list_partner_master } from "../redux/business-partner/partner-master/list-partner-master";
const Header = () => {
  const [notification, setNotification] = useState<boolean>(false);

  const notificationIconRep = useRef<any>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ListNotificationApi = useSelector(
    (state: any) => state.ListNotification
  );
  const ReadOneNotification = useSelector(
    (state: any) => state.ReadOneNotification
  );

  useEffect(() => {
    dispatch(list_notification(""));
    dispatch(raise_api_call_list_partner_master(""));
  }, [ReadOneNotification]);

  const notificationNumber =
    ListNotificationApi.data.length != 0
      ? ListNotificationApi.data.result.filter((e: any) => {
          if (!e.seen) {
            return e;
          }
        }).length
      : 0;

  // let refNode = useClickOutside(() => {
  //   setNotification(true);
  // });

  return (
    <>
      <div
        className=" m-0 d-flex align-items-center shadow"
        style={{
          height: "72px",
        }}
      >
        <div className="heder-log d-flex">
          <div className="ps-4">
            <img src={require("../assets/images/SPRVL-Black 1.png")}></img>
          </div>
          <div>
            <img src={require("../assets/images/SPRVL-Black 2.png")} />
          </div>
        </div>
        <div className="global-search"></div>
        {/* <h1 className="">S P Appearals Retail</h1> */}
        <div className="ms-auto justify-content-end d-flex me-2 align-items-center">
          <div className="position-relative">
            <FontAwesomeIcon
              ref={notificationIconRep}
              onClick={(e: any) => {
                setNotification(!notification);
              }}
              name="notification"
              style={{
                cursor: "pointer",
              }}
              icon={["fas", "bell"]}
              className="heder-setting"
            />
            <div
              style={{
                position: "absolute",
                top: "-6px",
                left: "24px",
                color: "red",
                fontSize: "14px",
              }}
            >
              {notificationNumber}
            </div>
          </div>
          {/* <FontAwesomeIcon icon={["fas", "gear"]} className="heder-setting" /> */}
          {/* <FontAwesomeIcon
            icon={["fas", "circle-question"]}
            className="heder-setting"
          /> */}
          <Avatar className="heder-avatar" />
          <div className="user-name d-flex align-items-center mx-3">
            <h4>{localStorage.getItem("userName")}</h4>
          </div>
          <div
            className="user-name d-flex align-items-center me-3"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.removeItem("SpApperalsToken");
              localStorage.removeItem("SpApperalsRole");
              localStorage.removeItem("SpToken");
              window.location.pathname = "/login";
              // navigate("/login");
            }}
          >
            <LogoutIcon />
            {/* <FontAwesomeIcon
              onClick={() => {
                setNotification(!notification);
              }}
              id="notification"
              name="notification"
              style={{
                cursor: "pointer",
              }}
              icon={["fal", "arrow-up-left-from-circle"]}
              className="heder-setting"
            /> */}
            {/* <h4>Log Out</h4> */}
          </div>
        </div>
        {notification ? (
          <Notifications
            notification={notification}
            ListNotificationApi={ListNotificationApi}
            setNotification={setNotification}
            notificationIconRep={notificationIconRep}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Header;

const Notifications = (props: any) => {
  const { ListNotificationApi } = props;
  const notificationRep = useRef<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PartnerMasterList = useSelector(
    (state: any) => state.listPartnerMaster
  );

  useEffect(() => {
    const closeNotificatiom = (e: any) => {
      if (
        !notificationRep.current.contains(e.target) &&
        !props.notificationIconRep.current.contains(e.target)
      ) {
        props.setNotification(false);
      }
    };
    document.body.addEventListener("mousedown", closeNotificatiom);
    return () =>
      document.body.removeEventListener("mousedown", closeNotificatiom);
  }, []);
  return (
    <>
      <div
        ref={notificationRep}
        className="notifications p-4"
        style={{
          overflow: "hidden",
        }}
      >
        <div className="position-relative">{/* <KeyboardArrowUpIcon /> */}</div>
        <div className="title d-flex border-bottom">
          <p
            className="me-auto mb-3"
            style={{
              fontWeight: "700",
            }}
          >
            Notification
          </p>
          <p
            className="ms-auto mb-3"
            style={{
              textDecorationLine: "underline",
              color: "#46A4E3",
              fontWeight: "700",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(read_one_notification({ url: "all" }));
            }}
          >
            Read all
          </p>
        </div>
        <div
          style={{
            overflow: "scroll",
            height: "100%",
            paddingBottom: "50px",
          }}
        >
          {ListNotificationApi.data.length != 0 ? (
            ListNotificationApi.data.message ? (
              ListNotificationApi.data.result.map((e: any) => {
                const monthNames = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];
                const avatar_name: any = Array.from(e.title)[0];
                const created_on = new Date(e.created_on);
                // delete data.EffecDate;
                const created_date =
                  created_on.getDate() +
                  "-" +
                  monthNames[created_on.getMonth()] +
                  "-" +
                  created_on.getFullYear();
                const date = created_date;

                return (
                  <>
                    <div className="content d-flex mt-4 align-items-center">
                      <Avatar className="heder-avatar avatar">
                        {avatar_name}
                      </Avatar>
                      <div
                        className="values px-2 ps-3 me-auto"
                        id={e.seen ? "readed" : ""}
                        onClick={() => {
                          dispatch(
                            read_one_notification({ id: e.id, url: "" })
                          );
                          const data =
                            PartnerMasterList.data.length != 0
                              ? PartnerMasterList.data.result.filter(
                                  (a: any) => {
                                    if (
                                      Number(a.id) === Number(e.partnerMasterId)
                                    ) {
                                      return a;
                                    }
                                  }
                                )
                              : [];
                          dispatch(
                            addPartnerData(data.length != 0 ? data[0] : {})
                          );
                          navigate("/business-partner/partner-details");

                          props.setNotification(false);
                        }}
                      >
                        <h5>{e.title}</h5>
                        <p className="mb-0">{e.description}</p>
                      </div>
                      <p
                        className="mb-0 "
                        style={{
                          width: "100px",
                        }}
                      >
                        {date}
                      </p>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                No Notification Found
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              No Notification Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const useClickOutside = () => {};
