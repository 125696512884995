import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";

import { isVariableStatement } from "typescript";
import { failure_api_call_role, success_api_call_role } from "./list-role";
import {
  failure_api_call_add_user,
  success_api_call_add_user,
} from "./add-user";
import { success_api_call_user, failure_api_call_user } from "./list-user";
import {
  failure_api_call_edit_user,
  success_api_call_edit_user,
} from "./edit-user";
import { configs } from "../../common";

// const api = "http://185.209.228.65:3035";
// const api = configs.api;
// const api = "http://192.168.1.110:3035";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");

  return Axios.get(configs.api + "/api/role/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listRoleApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);
    console.log(typeof response.data);
    if (response.data.message === "Token is invalid") {
      window.location.pathname = "/login";
      localStorage.removeItem("SpApperalsToken");
    }
    if (response.data.name == "TokenExpiredError") {
      window.location.pathname = "/login";
      localStorage.removeItem("SpApperalsToken");
    }

    yield put(success_api_call_role(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_role(e));
  }
}

// add user
const addUserCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");

  return Axios.post(configs.api + "/api1/create", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddUserApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addUserCall, e);

    yield put(success_api_call_add_user(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_user(e));
  }
}

// list user

const listUserCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");

  return Axios.get(configs.api + "/api1/list", {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listUserApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listUserCall, e);

    yield put(success_api_call_user(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_user(e));
  }
}

// edit user
const editUserCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");

  return Axios.put(configs.api + "/api1/update", a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* editUserApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editUserCall, e);

    yield put(success_api_call_edit_user(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_user(e));
  }
}
