import React, { useEffect, useState } from "react";
import "../../assets/css/sap-master/sap-master.css";
import SearchIcon from "@mui/icons-material/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { InputAdornment } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import TextFiealdInput from "../components/textFiald";
import { addGroupDataData } from "../../redux/sap-master/group-master-active-data-reducer";
import {
  business_channel_is_first,
  list_business_channel,
} from "../../redux/prefered-master/business-channel-list";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AutoComplete from "../components/autocomplete-input";

import { raise_api_call_list_general_setting } from "../../redux/general-setting/list-general-setting-reducer";
import {
  add_business_channel,
  add_business_channel_api_complete,
  add_business_channel_isSuccess,
} from "../../redux/prefered-master/add-business-channel";
import {
  edit_business_channel,
  edit_business_channel_api_complete,
  edit_business_channel_isSuccess,
} from "../../redux/prefered-master/edit-business-channel";
import { list_branch_master } from "../../redux/prefered-master/list-branch-master";
import CompletePopup from "../components/response-pop-up";

const GroupMaster = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const generalSettingList = useSelector((state: any) => state.GeneralSetting);
  const [responsePopup, setResponsePopup] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const editBusinessChannel = useSelector(
    (state: any) => state.EditBusinessBhannel
  );
  const addBusinessChannel = useSelector(
    (state: any) => state.AddBusinessBhannel
  );
  const [isEdit, setEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<{
    costcenter: string;
    name: string;
    dimension: string;
    active: boolean;
    branch: string;
    type: string;
    validfrom: string;
    sequenceno: string;
  }>({
    costcenter: "",
    name: "",
    dimension: "",
    type: "",
    active: true,
    branch: "",
    validfrom: "",
    sequenceno: "",
  });
  const [activePartner, setActivePartner] = React.useState<null | number>(0);
  const [search, setSearch] = useState<string>("");
  const activeDatas = useSelector((state: any) => state.GroupMasterActiveData);
  const activeData = activeDatas.data;

  const business_channel_data = useSelector(
    (state: any) => state.BusinessChannelList
  );
  const branch_master_data = useSelector(
    (state: any) => state.ListBranchMaster
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  // useEffect

  const validationSchema = Yup.object().shape({
    costcenter: Yup.string()

      .max(4, "maximum  4 character")
      .required(" Required"),
    name: Yup.string().required("Required"),
    dimension: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    branch: Yup.string().required("Required"),
    validfrom: Yup.string().required("Required"),
    sequenceno: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")

      .max(4, "maximum  4 character")
      .required("Required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      costcenter: isEdit ? editData.costcenter : "",
      name: isEdit ? editData.name : "",
      active: isEdit ? editData.active : true,
      dimension: isEdit ? editData.dimension : "",
      type: isEdit ? editData.type : "",
      branch: isEdit ? editData.branch : "",
      validfrom: isEdit ? editData.validfrom.substring(0, 10) : "",
      sequenceno: isEdit ? editData.sequenceno : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
  } = formik;
  const submit = (a: any) => {
    if (!isEdit) {
      const data = {
        costcenter: a.costcenter,
        name: a.name,
        active: a.active,
        dimension: a.dimension,
        type: a.type,
        branch: a.branch,
        validfrom: a.validfrom,
        sequenceno: a.sequenceno,
      };
      console.log(data);
      dispatch(add_business_channel(data));
    } else {
      const data = {
        id: activeData.id,
        active: a.active,
        costcenter: a.costcenter,
        name: a.name,
        dimension: a.dimension,
        type: a.type,
        branch: a.branch,
        validfrom: a.validfrom,
        sequenceno: a.sequenceno,
      };
      dispatch(edit_business_channel(data));
    }
  };
  useEffect(() => {
    dispatch(list_business_channel(""));
    dispatch(list_branch_master(""));
    dispatch(raise_api_call_list_general_setting(""));
    if (addBusinessChannel.isSuccess) {
      dispatch(add_business_channel_isSuccess(""));
      setOpen(false);
      resetForm();
    }
    if (editBusinessChannel.isSuccess) {
      dispatch(edit_business_channel_isSuccess(""));
      setOpen(false);
      resetForm();
      setEdit(false);
    }
    if (addBusinessChannel.api_completed) {
      setResponsePopup(true);
      dispatch(add_business_channel_api_complete(false));
    }
    if (editBusinessChannel.api_completed) {
      setResponseEdit(true);
      dispatch(edit_business_channel_api_complete(false));
      setResponsePopup(true);
    }
  }, [addBusinessChannel, editBusinessChannel]);
  // mainTable data
  const mainTable = () => {
    const filterarr = [];
    if (business_channel_data.data.length != 0) {
      for (let i = 0; i < business_channel_data.data.result.length; i++) {
        let data = { ...business_channel_data.data.result[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const valid_from = new Date(data.validfrom);
        // delete data.EffecDate;
        data.valid_from_date =
          valid_from.getDate() +
          "-" +
          monthNames[valid_from.getMonth()] +
          "-" +
          valid_from.getFullYear();
        // create on
        const created_on = new Date(data.created_on);
        // delete data.EffecDate;
        const created_date =
          created_on.getDate() +
          "-" +
          monthNames[created_on.getMonth()] +
          "-" +
          created_on.getFullYear();
        data.created_on
          ? (data.created_on = created_date)
          : console.log("sdfdsf");

        // update on
        const updated_on = new Date(data.updated_On);
        // delete data.EffecDate;
        const update_date =
          updated_on.getDate() +
          "-" +
          monthNames[updated_on.getMonth()] +
          "-" +
          updated_on.getFullYear();
        data.updated_On
          ? (data.updated_On = update_date)
          : console.log("sdfdsf");

        filterarr.push(data);
      }
    }
    // filterarr.filter((e) => delete e.id);
    // filterarr.filter((e) => delete e.active);
    // filterarr.filter((e) => delete e.created_by);
    // filterarr.filter((e) => delete e.updated_by);

    return (
      <>
        {/* {business_channel_data.data.length != 0 ? ( */}
        <>
          <div className="list col-5">
            <div className=" px-3 my-4  d-flex align-items-center">
              <div className="title d-flex ">
                <h5>Business Channel</h5>
              </div>

              <div className="filter ms-auto me-3 d-flex align-items-center">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="w-100 me-2"
                  name="search"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "0px",
                      height: "45px",
                    },
                  }}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    dispatch(list_business_channel(""));
                  }}
                  className="mx-2"
                  style={{
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                  icon={["fas", "arrows-rotate"]}
                />
              </div>
            </div>
            <div className="list">
              {filterarr
                .filter((item: any) => {
                  return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
                .map((e: any, key: number) => {
                  if (business_channel_data.isFirst && key === 0) {
                    dispatch(business_channel_is_first(""));
                    dispatch(addGroupDataData(e));
                  }
                  return (
                    <>
                      <div
                        key={key + 1}
                        id={activePartner === key ? "active-lists" : ""}
                        onClick={(a) => {
                          setActivePartner(key);
                          dispatch(addGroupDataData(e));
                        }}
                        className={"d-flex lists flex-column mt-2  p-3"}
                        style={{
                          height: "100px",
                          background: "#FFFFFF",
                        }}
                      >
                        <div className="mx-2 partner-name">{e.name}</div>
                        <div className="d-flex mt-auto partner-locations align-items-center">
                          <p className="ms-2 me-auto mb-0">{e.type}</p>
                          <p
                            className={
                              e.status === "Request Sent for Approval"
                                ? "ms-auto me-2 mb-0 text-yellow"
                                : "ms-auto text-light-green me-2 mb-0"
                            }
                          >
                            {/* {e.status} */}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div
            className="col-7 tracking pt-4 px-4"
            style={{
              height: "100vh",
            }}
          >
            <div className="ms-auto me-2 d-flex align-items-center justify-content-end">
              <div
                className="mx-4 add-branch"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                Add Business Channel
              </div>
              {Object.keys(activeData).length != 0 && activeData.name ? (
                <div>
                  <FontAwesomeIcon
                    onClick={() => {
                      setEdit(true);
                      setEditData(activeData);
                      handleClickOpen();
                    }}
                    title="edit"
                    className="mx-2"
                    style={{
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                    icon={["fas", "pen-to-square"]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {Object.keys(activeData).length != 0 && activeData.name ? (
              <>
                <div className="row">
                  <div className="col-8 partner-detail-data mt-4">
                    <label> Name</label>
                    <p>{activeData.name}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Cost Center</label>
                    <p>{activeData.costcenter}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label> Type</label>
                    <p>{activeData.type}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Dimension</label>
                    <p>{activeData.dimension}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Branch</label>
                    <p>{activeData.branch}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Sequence No</label>
                    <p>{activeData.sequenceno}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Sequence Code</label>
                    <p>{activeData.sequencecode}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Active</label>
                    <p>{activeData.active ? "Active" : "InActive"}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Valid From</label>
                    <p>{activeData.valid_from_date}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Create On</label>
                    <p>{activeData.created_on}</p>
                  </div>
                  <div className="col-4 partner-detail-data mt-4">
                    <label>Update On</label>
                    <p>{activeData.updated_On}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>Please Select Anyone</div>
              </>
            )}

            {/* <MainTable mainTableData={filterarr} nonObject={true} /> */}
          </div>
        </>
        {/* ) : (
          <div>No Data Found</div>
        )} */}
      </>
    );
  };
  const branch_option =
    branch_master_data.data.length != 0
      ? branch_master_data.data.status
        ? branch_master_data.data.result.map((e: any) => {
            const data = { label: e.branch_name, value: e.branch_code };
            return data;
          })
        : [{ label: "No data", value: null }]
      : [{ label: "No data", value: null }];

  return (
    <>
      <div className="general-setting sap-master">
        {responsePopup ? (
          <CompletePopup
            data={responseEdit ? editBusinessChannel : addBusinessChannel}
            responsePopup={responsePopup}
            setCompletePopup={(e: any) => {
              setResponsePopup(false);
              setResponseEdit(false);
            }}
          />
        ) : (
          ""
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            generalSettingList.isLoading || business_channel_data.isLoading
              ? true
              : false
          }
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            style={{
              height: "150px",
            }}
            src={require("../../assets/images/3.gif")}
          ></img>
        </Backdrop>
        <div className="forms d-flex">
          {
            //   business_channel_data.isSuccess ? (
            mainTable()
            //   ) : (
            //     <div>No Data Found</div>
            //   )
          }
        </div>
      </div>
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="d-flex">
          <DialogTitle id="alert-dialog-title" className="font-weight-700">
            {"Add Business Channel"}{" "}
          </DialogTitle>
          <div className="ms-auto p-3">
            <CancelIcon
              onClick={() => {
                handleClose();
              }}
              sx={{
                color: "#D1D1D1",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="d-flex flex-column">
            <div className="row">
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="costcenter"
                  label="Cost Center"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="name"
                  label="Name"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="dimension"
                  label="Dimension"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="type"
                  label="Type"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>

              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <AutoComplete
                  pageOption={branch_option}
                  name="branch"
                  label="Branch"
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  vari={(e: any) => {
                    setFieldValue("branch", e ? e.value : "");
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 mt-2 d-flex flex-column">
                <label className="px-3">
                  Valid from<span>*</span>
                </label>

                <TextField
                  type="date"
                  className="px-3"
                  placeholder="Address Id"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    borderRadius: "0px",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.validfrom}
                  error={touched.validfrom && errors.validfrom ? true : false}
                  helperText={
                    touched.validfrom && errors.validfrom
                      ? errors.validfrom
                      : ""
                  }
                  name="validfrom"
                  inputProps={{
                    style: {
                      height: "20px",
                    },
                  }}
                />
              </div>
              <div className="input-field px-3 mt-2 col-6 d-flex flex-column">
                <TextFiealdInput
                  name="sequenceno"
                  label="Sequence No"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  readOnly={isEdit}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="col-12 mt-4">
                <div className="col-6 d-flex ms-3 align-items-center">
                  <FormControlLabel
                    value={values.active}
                    control={<Checkbox checked={values.active} />}
                    label="Is Active"
                    onChange={handleChange}
                    name="active"
                    labelPlacement="end"
                    sx={{
                      fontWeight: "700",
                    }}
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    onClick={() => {
                      handleClose();
                      setEdit(false);
                    }}
                    className="cancel"
                  >
                    Cancel
                  </div>
                </div>
                <div className="add-more px-3 col-3 d-flex flex-column">
                  <div
                    className="add"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {addBusinessChannel.isLoading ||
                    editBusinessChannel.isLoading
                      ? "Please Wait"
                      : "Save"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GroupMaster;
