import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { configs } from "../../../common";

export const ListConsalidationBusinessPartner = createSlice({
  name: "ListConsalidationBusinessPartner",
  initialState: {
    data: [],
    isSuccess: false,
    isLoading: false,
  },
  reducers: {
    get_consalidation_business_partner: (state, action?) => {
      state.isLoading = true;
    },
    success_api_consalidation_business_partner: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_consalidation_business_partner: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default ListConsalidationBusinessPartner.reducer;
export const {
  get_consalidation_business_partner,
  success_api_consalidation_business_partner,
  failure_api_consalidation_business_partner,
} = ListConsalidationBusinessPartner.actions;

//

const api = configs.api + "/api/partner/listconpartner";
// const api = "http://185.209.228.65:3035/api5/listconpartner";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api, a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* getConsalidationBusinessPartner(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);

    yield put(success_api_consalidation_business_partner(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_consalidation_business_partner(e));
  }
}
