import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_LEFT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const ApprovePartner = createSlice({
  name: "ApprovePartner",
  initialState: {
    data: [],
    api_completed: false,
    isLoading: false,
    isSuccess: false,
  },
  reducers: {
    management_approve_partner: (state, action?: any) => {
      state.data = action.payload;
      state.isLoading = true;
    },
    list_success_management_approve_partner: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      // SUCCESE(action.payload.message);
    },
    list_failure_management_approve_partner: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    approve_partner_is_succese: (state, action) => {
      state.isSuccess = false;
    },
    approve_partner_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default ApprovePartner.reducer;
export const {
  management_approve_partner,
  approve_partner_is_succese,
  list_success_management_approve_partner,
  list_failure_management_approve_partner,
  approve_partner_complete_api,
} = ApprovePartner.actions;
