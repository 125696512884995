import { TextField, Autocomplete } from "@mui/material";
import { useState } from "react";
// import { matchSorter } from "match-sorter";

const AutoComplete = (props: any) => {
  const [close, setClose] = useState<any>(false);
  const {
    style,
    onBlur,
    pageOption,
    touched,
    onchange,
    setFieldValue,
    disable,
    onchangeValue,
    notRequire,
    label,
    readOnly,
    handchange,
    handleBlur,
    errors,
    values,
    vari,
    name,
  } = props;

  return (
    <>
      <div className="input-field px-3  d-flex flex-column">
        <label>
          {label} {!notRequire ? <span>*</span> : ""}
        </label>
        <Autocomplete
          sx={{
            "& legend": { display: "none" },
            "& fieldset": {
              top: 0,
              border: "2px solid #c4c4c4",
              borderRadius: "0px",
            },
            // borderRadius: "0px",
            fontWeight: "700",
            fontFamily: "Roboto",
          }}
          readOnly={readOnly}
          value={
            pageOption.length != 0
              ? pageOption.filter((e: any) => {
                  return e.value === values[name];
                }).length != 0
                ? pageOption.filter((e: any) => {
                    return e.value === values[name];
                  })[0].label
                : values[name]
              : values[name]
          }
          onChange={(e, value: any) => {
            vari(value);
          }}
          renderOption={(
            props: React.HTMLAttributes<HTMLLIElement>,
            option: Partial<any>
          ) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          options={pageOption}
          renderInput={(params) => (
            <TextField
              sx={{
                textTransform: "capitalize",
                height: "54px",
              }}
              onBlur={handleBlur}
              error={touched[name] && errors[name] ? true : false}
              helperText={touched[name] && errors[name] ? errors[name] : ""}
              name={name}
              placeholder="Select Type"
              {...params}
            />
          )}
        />
      </div>
    </>
  );
};

export default AutoComplete;

// import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// export default function Filter() {
//   return (
//     <Autocomplete
//       id="filter-demo"
//       options={top100Films}
//       getOptionLabel={(option) => option.title}
//       filterOptions={filterOptions}
//       sx={{ width: 300 }}
//       renderInput={(params) => <TextField {...params} label="Custom filter" />}
//     />
//   );
// }
