import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { configs } from "../../../common";

export const GenerateCostCentre = createSlice({
  name: "GenerateCostCentre",
  initialState: {
    data: [],
    isSuccess: false,
    isLoading: false,
  },
  reducers: {
    generate_cost_center_from_api: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_generate_cost_center_call: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_generate_cost_center_call: (state, action) => {
      state.isLoading = false;
    },
  },
});
export default GenerateCostCentre.reducer;
export const {
  generate_cost_center_from_api,
  success_api_generate_cost_center_call,
  failure_api_generate_cost_center_call,
} = GenerateCostCentre.actions;

//

const api = configs.api + "/api/partner/generatecostcentre";
// const api = "http://192.168.1.114:3035/api5/generatecostcentre";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.post(api, a.payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* getCostCenter(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);

    yield put(success_api_generate_cost_center_call(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_generate_cost_center_call(e));
  }
}
