import Router from "./route";
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
library.add(fas, faTwitter, faFontAwesome, faCheckSquare, faCoffee, fab);

const ErrorBoundary = React.lazy(() => import("./error-boundry"));
function App() {
  return (
    <>
      <ErrorBoundary>
        <Router></Router>
        <ToastContainer />
      </ErrorBoundary>
    </>
  );
}

export default App;
