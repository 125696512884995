import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const onboardingContactInfo = createSlice({
  name: "onboardingContactInfo",
  initialState: {
    data: [],
    isCompleted: false,
  },
  reducers: {
    submit_contact_info_state: (state, action?: any) => {
      state.data = action.payload;
      state.isCompleted = true;
    },
    list_success_api_contact: (state, action) => {
      const responce_data: any = [];
      action.payload.result.forEach((a: any) => {
        const data: any = {
          id: a.id,
          default: a.default,
          phone: a.phone,
          title: a.title,
          email: a.email,
          country_code: Number(a.country_code),
          contact_person: a.contact_person,
        };
        responce_data.push(data);
      });

      state.data = responce_data;
      state.isCompleted = true;
    },
    list_failure_api_contact: (state, action) => {
      state.data = action.payload;
      state.isCompleted = false;
    },
  },
});
export default onboardingContactInfo.reducer;
export const {
  submit_contact_info_state,
  list_success_api_contact,
  list_failure_api_contact,
} = onboardingContactInfo.actions;
