import React from "react";
import { createSlice } from "@reduxjs/toolkit";
import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { configs } from "../../../common";

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data: any) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
export const UpdateAcountsOnboarding: any = createSlice({
  name: "UpdateAcountsOnboarding",
  initialState: {
    data: [],
    api_completed: false,
    isCompleted: false,
    isLoading: false,
    isSuccess: false,
  },
  reducers: {
    raise_api_call_update_acounts_onboarding: (state, action?: any) => {
      state.isLoading = true;
    },
    success_api_call_update_acounts_onboarding: (state, action) => {
      state.data = action.payload;
      state.api_completed = true;
      state.isLoading = false;
      state.isSuccess = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_update_acounts_onboarding: (state, action) => {
      state.api_completed = true;
      state.isLoading = false;
      state.data = action.payload;
      // FAILED(action.payload);
    },
    update_acounts_onboarding_is_success: (state, action) => {
      state.isSuccess = false;
    },
    account_onboarding_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default UpdateAcountsOnboarding.reducer;
export const {
  raise_api_call_update_acounts_onboarding,
  success_api_call_update_acounts_onboarding,
  failure_api_call_update_acounts_onboarding,
  account_onboarding_complete_api,
  update_acounts_onboarding_is_success,
} = UpdateAcountsOnboarding.actions;

const api = configs.api;
// const api = "http://192.168.1.114:3035";

const getCall = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.put(api + "/api/accounts_onboarding/update", a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export function* updateAccountsOnboardingApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e.payload);
    if (response.data.status) {
      yield put(success_api_call_update_acounts_onboarding(response.data));
    } else {
      yield put(failure_api_call_update_acounts_onboarding(response.data));
    }
  } catch (err) {
    yield put(failure_api_call_update_acounts_onboarding(err));
  }
}
