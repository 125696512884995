import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import {
  list_success_api_basic_info,
  list_failure_api_basic_nfo,
  raise_api_completed,
} from "./basic-info-reducer";
import {
  list_success_api_contact,
  list_failure_api_contact,
} from "./contact-details-reducer";
import {
  list_success_api_address_info,
  list_failure_api_address_info,
} from "./address-reducer";
import {
  list_success_api_bank_detail,
  list_failure_api_bank_detail,
} from "./bank-detail-reducer";
import { isVariableStatement } from "typescript";
import { configs } from "../../../common";

// const api = "http://185.209.228.65:3035/api4/list";
//192.168.1.107:3035

// local ipp
// const basicinfo_api = "http://192.168.1.114:3035/api6/list_basic_info";
// const contact_api = "http://192.168.1.114:3035/api/person/list";
// const address_api = "http://192.168.1.114:3035/api/address/list";
// const banck__api = "http://192.168.1.114:3035/api/bankdetail/list";
// server ip

const basicinfo_api = configs.api + "/api6/list_basic_info";
const contact_api = configs.api + "/api/person/list";
const address_api = configs.api + "/api/address/list";
const banck__api = configs.api + "/api/bankdetail/list";

const getCallBasicInfo = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(basicinfo_api, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallContact = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(contact_api, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallAddress = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(address_api, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
const getCallBankDetail = async (a: any) => {
  let token = await localStorage.getItem("SpApperalsToken");
  return Axios.get(banck__api, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export function* listOnboardingApi(e: any) {
  try {
    const response_basic_info: AxiosResponse = yield call(
      getCallBasicInfo,
      e.payload.basicInfo
    );
    // console.log(response_basic_info);
    if (response_basic_info.data.status) {
      yield put(list_success_api_basic_info(response_basic_info.data));
    } else {
      yield put(list_failure_api_basic_nfo(response_basic_info.data));
    }

    const response_contact: AxiosResponse = yield call(
      getCallContact,
      e.payload.contact_info
    );
    if (response_contact.data.status) {
      yield put(list_success_api_contact(response_contact.data));
    } else {
      yield put(list_failure_api_contact(response_contact.data));
    }

    const response_address: AxiosResponse = yield call(
      getCallAddress,
      e.payload.address_info
    );
    if (response_address.data.status) {
      yield put(list_success_api_address_info(response_address.data));
    } else {
      yield put(list_failure_api_address_info(response_address.data));
    }

    const response_bank_detail: AxiosResponse = yield call(
      getCallBankDetail,
      e.payload.bank_detail
    );
    if (response_bank_detail.data.status) {
      yield put(list_success_api_bank_detail(response_bank_detail.data));
    } else {
      yield put(list_failure_api_bank_detail(response_bank_detail.data));
    }
    yield put(raise_api_completed(""));
  } catch (err) {
    console.log(err);
  }
}
