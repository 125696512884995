import { call, takeEvery, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { ServerResponse } from "http";
import { failure_api_call, success_api_call } from "./create-password-reducer";
import { isVariableStatement } from "typescript";
import { configs } from "../../common";

const api = configs.api + "/api1/changepassword";

const getCall = (a: any) => {
  return Axios.put(api, a.payload);
};
export function* CreatePasswordApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getCall, e);
    if (response.data.status) {
      yield put(success_api_call(response.data));
    } else {
      yield put(failure_api_call(response.data));
    }
  } catch (e) {
    // console.log(e);
    yield put(failure_api_call(e));
  }
}
