import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const SinkGroupMaster = createSlice({
  name: "SinkGroupMaster",
  initialState: {
    isLoading: false,
    isSuccess: false,
    data: [],
  },
  reducers: {
    sink_group_account_master: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_sink_group_account_master: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_sink_group_account_master: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    sink_group_account_isSuccess: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default SinkGroupMaster.reducer;
export const {
  sink_group_account_master,
  success_api_call_sink_group_account_master,
  sink_group_account_isSuccess,
  failure_api_call_sink_group_account_master,
} = SinkGroupMaster.actions;
